<template>
<v-card>
      <v-card-title>{{ $t('tournament.teamPage.forfeitDialogTitle') }}</v-card-title>
      <v-card-text>
        <p>{{ $t('tournament.teamPage.forfeitDescription') }}</p>
        <p class="mb-0">
          {{ $t('tournament.teamPage.enterTeamName') }}:
        </p>
        <v-text-field dense v-model="teamNameInput" persistent-hint :hint="teamName"></v-text-field>
      </v-card-text>
      <v-card-actions class="actions">
          <v-btn color="error" text @click="forfeit" :disabled="teamNameInput !== teamName"> {{ $t('tournament.teamPage.forfeit') }}</v-btn>
          <v-btn text @click="cancel">{{ $t('common.cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
const FORFEIT_EVENT = 'forfeit';
const CANCEL_EVENT = 'cancel';

export default {
  name: 'forfeit-dialog',
  data: function() {
    return {
      teamNameInput: ''
    }
  },
  props: {
    teamName: {
      type: String,
      required: true
    }
  },
  methods: {
    forfeit: function() {
      this.$emit(FORFEIT_EVENT);
    },
    cancel: function() {
      this.$emit(CANCEL_EVENT)
    }
  }
}
</script>

<style scoped>
.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
</style>
