<template>
  <section class="score">
    <span class="ma-2" :class="{ 'red--text': highlight === 'home' }">{{ home }}</span>
    <span class="ma-2">:</span>
    <span class="ma-2" :class="{ 'red--text': highlight === 'away' }">{{ away }}</span>
  </section>
</template>

<script>
export default {
  name: 'scoreDisplay',
  props: {
    home: {
      type: Number,
      default: () => 0
    },
    away: {
      type: Number,
      default: () => 0
    },
    highlight: {
      type: String,
      required: false,
      default: () => ''
    }
  }
}
</script>

<style scoped>
.score {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: 'Digital-7';
  font-size: 75px;
  font-weight: 100;
}

@font-face {
    font-family: 'Digital-7';
    src:url('../assets/fonts/Digital-7.ttf.woff') format('woff'),
        url('../assets/fonts/Digital-7.ttf.svg#Digital-7') format('svg'),
        url('../assets/fonts/Digital-7.ttf.eot'),
        url('../assets/fonts/Digital-7.ttf.eot?#iefix') format('embedded-opentype'); 
    font-weight: normal;
    font-style: normal;
}
</style>
