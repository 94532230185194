<template>
  <v-app-bar app dark class="primary">
    <v-btn dark text icon @click="navigateBack">
      <v-icon>arrow_back</v-icon>
    </v-btn>
    
    <v-toolbar-title>
      {{ $t('shell.appTitle') }}
    </v-toolbar-title>

    <v-spacer />

    <v-toolbar-items>
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text>
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        
        <v-list>
          <v-list-item @click="openQrDialog">
            <v-list-item-action>
              <svg-icon color="grey-darken4" name="qr-code" size="icon"></svg-icon>
            </v-list-item-action>
            <v-list-item-title>{{ $t("qr.showQr") }}</v-list-item-title>
          </v-list-item>

          <v-list-item @click.stop="selectPosition">
            <v-list-item-action>
              <v-icon>autorenew</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ $t("match.changePosition") }}</v-list-item-title>
          </v-list-item>

          <v-list-item @click="resetMatch" v-if="!isInSpectationMode">
            <v-list-item-action>
              <v-icon>restore</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ $t("match.resetMatch") }}</v-list-item-title>
          </v-list-item>
        </v-list>

        
      </v-menu>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { ACTIONS as MatchSessionActions } from '../vuex/matchSession';
import { mapActions } from 'vuex';

const SELECT_POSITION_EVENT = 'selectPosition'

export default {
  name: 'match-toolbar',
  data: () => ({
    showQrDialog: false
  }),
  computed: {
    matchId: function() {
      return this.$route.params.id
    },
    isInSpectationMode: function() {
      return this.$route.query.pos == null;
    }
  },
  methods: {
    openQrDialog: function() {
      this.$emit('openQrDialog');
    },
    resetMatch: function() {
      this.resetSession();
    },
    ...mapActions({
      resetSession: MatchSessionActions.ResetSession,      
    }),
    navigateBack: function() {
      this.$router.replace('/');
    },
    selectPosition: function() {
      this.$emit(SELECT_POSITION_EVENT);
    }
  }
}
</script>
