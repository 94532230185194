<template>
    <div>
        <v-icon size="60" color="green" class="mx-auto">check_circle</v-icon>
        <p class="headline text mb-0">{{ title }}</p>
        <p class="text mb-0">{{ subtitle }}</p>
    </div>
</template>

<script>
export default {
    name: 'success',
    props: {
        title: String,
        subtitle: String
    },
}
</script>

<style scoped>
.text {
    text-align: center;
}

.back-button {
    width: 100%;
}
</style>