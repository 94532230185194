<template>
  <div>
    <div class="header-image">
      <slot name="image"></slot>
      <div class="blue-overlay"></div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'page-header',
}
</script>

<style scoped>
.header-image {
  position: relative;
  height: 200px;
}
.header-image>img {
  object-fit: cover;
  object-position: 50% 80%;
  width: 100%;
  height: 100%;
  position: absolute;
}
.blue-overlay {
  position: relative;
  width: 100%;
  height: 100%;
  
  background-color: var(--v-primary-base);
  opacity: 0.75;
}
</style>