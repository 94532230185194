/**
 * Vuex module for handling match session events
 */
import matchSessionApi from '../services/vuezlerApi/matchSession';
import { ACTIONS as MatchStateActions } from './matchState'
import matchHubMethods from '../logic/matchSession/matchHubMethods';
import { PositionUpdate } from '../services/vuezlerApi/models';
import * as SignalR from '@microsoft/signalr';
import positionUpdate from '../services/vuezlerApi/models/positionUpdate';
import { createMatch } from '../services/vuezlerApi/match.service';
import { prepareErrorMessages } from '../logic/matchSessionFunctions';
import { ACTIONS as ShellActions } from './shell.module';
import router from '../routes'
import { ACTIONS as MatchSummaryModule } from './matchSummary.module';
import firebase from 'firebase/app';

/** Actions on the MatchSession */
export const ACTIONS = {
  InitializeSession: '[🎸 MatchSession] Initialize Session',
  DestroySession: '[🎸 MatchSession] Destroy Session',
  InvokeSessionAction: '[🎸 MatchSession] Invoke Action',
  ResetSession: '[🎸 MatchSession] Reset session',
  UpdatePlayerPosition: '[🎸 MatchSession] Update player position',
  EndMatch: '[🎸 MatchSession] End match',
  TryingToEnd: '[🎸 MatchSession] End match requested',
}

/** Getters of the MatchSession */
export const GETTERS = {
  State: '[🎸️ MatchSession] State'
}

/** Buffered match session actions while the connection is not active. */
let sessionBuffer = [];

const MUTATIONS = {
  SetConnection: '[🎸 MatchSession] Set Connection',
  DestroySession : '[🎸 MatchSession] Destroy Session',
  SetHubConnectionState: '[🎸 MatchSession] Set hub connection state',
  SetPlayerPosition: '[🎸 MatchSession] Set player position',
  TryingToEnd: '[🎸 MatchSession] End match requested',
  Finish: '[🎸 MatchSession] Finish Match Session',
}

export const MatchState = {
  Uninitialized: 'uninitialized',
  Running: 'running',
  Disconnected: 'disconnected',
  Finishing: 'finishing',
  Finished: 'finished'
}

const state = {
  hubConnection: undefined,
  isHubConnectionEstablished: false,
  isSessionClosed: false,
  state: MatchState.Uninitialized
}

const getters = {
  [GETTERS.State]: state => state.state
}

const actions = {
  [ACTIONS.InitializeSession]: ({dispatch, commit}, options) => {
    const connection = createNewConnection(options, commit, dispatch);
    commit(MUTATIONS.SetConnection, connection);

    const eventsDispatcher = ({ data: events }) => dispatch(MatchStateActions.ADD_EVENT, { events });
    const hubConnectionEstablished = hubCollectionEstablishedFactory(commit);
    startSession(connection, options, eventsDispatcher, hubConnectionEstablished);

    connection.onclose(() => {
      const isMatchActive = state.state !== MatchState.Finished || state.state !== MatchState.Uninitialized;
      const isCurrentConnectionClosed = state.hubConnection && state.hubConnection.id === connection.id;
      if (isMatchActive && isCurrentConnectionClosed) {
        startSession(connection, options, eventsDispatcher, hubConnectionEstablished)
      }
    });
  },
  [ACTIONS.DestroySession]: ({ commit, state, dispatch }, previouslyUsedConnection) => {
    // The used hub connection is an optional parameter.
    // If a previous action used a specific connection it can be passed to this method to destroy it afterwards.
    const connection = previouslyUsedConnection || state.hubConnection;
    if (connection.id === state.hubConnection.id) {
      dispatch(MatchStateActions.RESET_EVENTS);
      commit(MUTATIONS.DestroySession);
      sessionBuffer = [];
    }
    connection.stop();
  },
  [ACTIONS.InvokeSessionAction]: ({state}, { name, params = [] }) => {
    const hubConnection = state.hubConnection;
    return invokeAction(hubConnection, { name, params }).then(() => hubConnection);
  },
  [ACTIONS.UpdatePlayerPosition]: ({commit, dispatch}, position) => {
    commit(MUTATIONS.SetPlayerPosition, position);
    dispatch(ACTIONS.InvokeSessionAction, {
      name: matchHubMethods.POSITION_UPDATE,
      params: [ position, positionUpdate.Enter ]
    })
  },
  [ACTIONS.ResetSession]: ({state}) => {
    state.hubConnection.invoke(matchHubMethods.RESET_MATCH);
  },
  [ACTIONS.EndMatch]: ({dispatch}, match) => {
    state.hubConnection.invoke(matchHubMethods.MATCH_END_REQUESTED, true);

    createMatch(match)
      .then((response) => {
        state.hubConnection
          .invoke(matchHubMethods.MATCH_ENDED, response.data)
          .catch(() => {
            // Nothing to do here. The websocket response gets processed earlier then the response from the request.
            // This means that the connection was already closed by the redirect/destroy mechanism as soon as the response arrives.
          });
      }).catch(({response}) => {
        const errorMessages = prepareErrorMessages(response.data)
        dispatch(ShellActions.SHOW_ERROR, errorMessages.join('\n'))
        state.hubConnection.invoke(matchHubMethods.MATCH_END_REQUESTED, false);
      })
  },
  [ACTIONS.TryingToEnd]: ({ commit }, isEnding) => {
    commit(MUTATIONS.TryingToEnd, isEnding);
  }
}

const mutations = {
  [MUTATIONS.SetConnection]: (state, connection)  => {
    state.hubConnection = connection;
  },
  [MUTATIONS.DestroySession]: (state) => {
      state.hubConnection = undefined;
      state.state = MatchState.Uninitialized;
  },
  [MUTATIONS.SetPlayerPosition]: (state, position) => {
    state.position = position;
  },
  [MUTATIONS.SetHubConnectionState]: (state, isConnectionEstablished) => {
    state.isHubConnectionEstablished = isConnectionEstablished;
    if (isConnectionEstablished) {
      state.state = MatchState.Running;
    }
  },
  [MUTATIONS.TryingToEnd]: (state, isEnding) => {
    state.state = isEnding ? MatchState.Finishing : MatchState.Running;
  },
  [MUTATIONS.Finish]: (state) => {
    state.state = MatchState.Finished;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

/**
 * Invokes an action on the match session connection. If the connection doesn't exist yet it will be put into a buffer.
 * @param {HubConnection} connection represents the connection object on which the action will be invoked.
 * @param {object} action describes the action using a name and parameters.
 */
function invokeAction(connection, { name, params }) {
  return new Promise(resolve => {
    if (connection == null) {
        sessionBuffer.push({ name, params});
      resolve();
    }
    else {
      connection
        .invoke(name, ...params)
        .then(resolve)
        .catch(() => {
            sessionBuffer.push({name, params});
          resolve();
        });
    }
  });
  
}

/**
 * Creates a function for setting the initialization state of match sessions.
 * @param {CommitFn} commit vuex function for altering the state
 */
const hubCollectionEstablishedFactory = commit => isConnectionEstablished => commit(MUTATIONS.SetHubConnectionState, isConnectionEstablished);

/**
 * Starts a connection to the match hub.
 * When the connection is established all existing match events will be requested from the api. Only after this happened, the match session is fully initialized
 * @param {*} connection represents the connection to the hub
 * @param {*} options contains information about the match
 * @param {*} eventsDispatcherFn function for dispatching the existing match events from the api.
 * @param {*} hubConnectionEstablished function for setting the 
 */
function startSession(connection, options, eventsDispatcherFn, hubConnectionEstablished) {
  hubConnectionEstablished(false);

  sessionBuffer = [];
  connection
    .start()
    .then(() => {
      matchSessionApi.getMatchSessionEvents(options.matchId)
        .then(eventsDispatcherFn)
        .then(() => hubConnectionEstablished(true));

      sessionBuffer.forEach(act => invokeAction(connection, act));
      sessionBuffer = [];
      
      if (options.getCurrentPosition() >= 0 && options.getCurrentPosition() < 4) {
        connection.invoke(matchHubMethods.POSITION_UPDATE, options.getCurrentPosition(), PositionUpdate.Enter);
      }
    })
    .catch(() => {
      setTimeout(() => startSession(connection, options, eventsDispatcherFn, hubConnectionEstablished), 5000);
    });
}

/**
 * Create a connection for a match.
 * @param {MatchOptions} options contains information about the match
 * @param {DispatchFn} dispatch vuex function for dispatching events
 */
function createNewConnection(options, commit, dispatch) {
  const connection = new SignalR.HubConnectionBuilder()
    .withUrl(`${window.$config.vuzzlerApi}/matchhub?matchId=${options.matchId}`, {
      accessTokenFactory: () => {
        return new Promise((resolve, reject) => {
          const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            unsubscribe();
            user.getIdToken().then(token => {
              resolve(token);
            }).catch(reject)
          }, reject);
        })
      }
    })
    .build();

  connection.on(matchHubMethods.POSITION_UPDATE,  event => dispatch(MatchStateActions.ADD_EVENT, { events: [ event ] }));
  connection.on(matchHubMethods.CONNECTION_CHANGE, event => dispatch(MatchStateActions.ADD_EVENT, { events: [ event ] }));
  connection.on(matchHubMethods.COUNT_SCORE, event => dispatch(MatchStateActions.ADD_EVENT, { events: [ event ] }));

  connection.on(matchHubMethods.RESET_MATCH, () => {
    dispatch(MatchStateActions.RESET_EVENTS, { keepConnections: true });
    connection.invoke(matchHubMethods.POSITION_UPDATE, options.getCurrentPosition(), PositionUpdate.Enter); 
  });

  connection.on(matchHubMethods.PLAY_SOUND, options.playSoundCallback);

  connection.on(matchHubMethods.MATCH_END_REQUESTED, isEnding => {
    dispatch(ACTIONS.TryingToEnd, isEnding);
  });

  connection.on(matchHubMethods.MATCH_ENDED, match => {
    if (match) {
      commit(MUTATIONS.Finish);
      dispatch(MatchSummaryModule.SET_SUMMARY, match)
      router.replace(`/overview`);
    }
  });

  return connection;
}