<template>
  <div class="upload-area">
    <div v-if="uploadingImage" class="upload-progress">
      <avatar-preview ref="image" :src="uploadingImage" class="image"/>
      <v-spacer />
      <v-progress-circular size="24" indeterminate></v-progress-circular>
      <v-spacer />
    </div>
    <div v-else>
      <input class="file-upload-input" type="file" accept="image/*" ref="fileInput" @change="handleFileChange"/>
      <v-btn text class="upload-button" block @click="openFileDialog">
        <v-icon x-large color="background darken-3">cloud_upload</v-icon>
        <span class="upload-text">{{ $t('settings.uploadImage') }}</span>
      </v-btn>
    </div>
  </div>
</template>


<script>
import AvatarPreview from './AvatarPreview';
import { uploadAvatar } from '../services/playerService';
import { GETTERS } from '../../../vuex/auth.module';
import { mapGetters } from 'vuex';

export default {
  name: 'avatar-upload',
  data: () => ({
    uploadingImage: null
  }),
  computed: {
    ...mapGetters({
      playerId: GETTERS.USER_ID
    })
  },
  methods: {
    openFileDialog: function() {
      this.$refs.fileInput.click();
    },
    previewImage: function(imageFile) {
      var reader = new FileReader();
      reader.onload = e => {
        this.uploadingImage = e.target.result;
      }
      reader.readAsDataURL(imageFile);
      return reader;
    },
    handleFileChange: function(data) {
      var uploadedFile = data.target.files[0];
      var file = new FormData();

      const previewProcess = this.previewImage(uploadedFile);
      
      file.append("file", uploadedFile, uploadedFile.name)
      uploadAvatar(this.playerId, file).then(({ data }) => {
        previewProcess.abort();
        this.uploadingImage = null;
        this.$emit('uploaded', data);
      }).catch(error => {
        previewProcess.abort();
        this.uploadingImage = null;
        const errorData = error.response.data;
        if (errorData.key === 'fileSizeTooLarge') {
          this.showError(this.$t('settings.errors.fileSizeTooLarge'));
        } else if (errorData.key === 'unsupportedFileType') {
          this.showError(this.$t('settings.errors.unsupportedFileType'));
        }
      })
    },
  },
  components: {
    AvatarPreview
  }
}
</script>

<style scoped>
  .upload-area {
    border: 4px dashed var(--v-grey-darken4);
    border-radius: 4px;
    height: 100px;
  }

  .upload-area * {
    height: 100%;
    padding: 8px;
  }

  .upload-progress {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .upload-button {
    width: 100%;
    height: 100% !important;
    margin: 0px;
  }

  .upload-text {
    color: var(--v-grey);
    padding-left: 8px;
  }

  .file-upload-input {
    display: none;
  }
</style>
