import axios from "axios";
import { isRunningInCordova } from '../modules/core/cordova/cordovaApi';
import firebase from 'firebase/app';

export default function setupAuthorizationInterceptor(store, router) {
  axios.interceptors.request.use(function(config) {
    return new Promise((resolve) => {
      if (config.url.startsWith(window.$config.vuzzlerApi)) {
        tryAddBearerToken(config, store).then(config => resolve(config));
      } else {
        resolve(config);
      }
    });
  });

  axios.interceptors.response.use(_ => _, error => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('user-token');
      firebase.auth().signOut();
      if (isRunningInCordova()) {
        window.location.reload();
      } else {
        router.go();
      }
    } else {
      return Promise.reject(error);
    }
  });
}

/**
 * Try to add the bearer token to the provided config object.
 * The token will be taken from the user specified in the store. If the token can't be retrieved from the user object it will be skipped.
 * @param {*} config the http configuration object.
 * @param {*} store contains the user object
 * @returns promise with the new config object.
 */
function tryAddBearerToken(config) {
  return new Promise((resolve) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      unsubscribe();
      if (user != null) {
        user.getIdToken().then(token => {
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
          resolve(config);
        })
      } else {
        resolve(config);
      }
    })
  });
}