import axios from "axios";

export function getGroupMemberships() {
  return axios
      .get(`${window.$config.vuzzlerApi}/api/players/groups`)
}

export function getPlayer(playerId) {
  return axios
    .get(`${window.$config.vuzzlerApi}/api/players/${playerId}`);
}

export function queryPlayer(query) {
  return axios
    .get(`${window.$config.vuzzlerApi}/api/players?q=${query}`);
}

export function ensurePlayerExists(player) {
  return axios
    .put(`${window.$config.vuzzlerApi}/api/players/${player.id}`, player);
}