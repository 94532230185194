export const en = {
  pageTitles: {
    default: 'Vuzzler - Your digital foosball leaderboard',
  },
  player: {
    winAbbr: "W",
    drawAbbr: "D",
    loseAbbr: "L",
    rating: "Rating",
    errors: {
      cantFetchInfo: "We could not gather your player information. If this error persists, please contact the support."
    }
  },
  match: {
    joinMatch: "Join a match",
    join: "Join match",
    orHostMatch: "Or host a match",
    hostMatch: "Host a match",
    qrCodeOption: "with QR code",
    nfcOption: "with NFC",
    choosePositionTitle: "Choose your position",
    onlySpectating: "Only spectating",
    kickedTitle: "Kicked",
    kickedDescription: "Somebody kicked you from your current playing position. You can either reconnect to reclaim your position, choose another one or return to your home screen.",
    reconnect: "Reconnect",
    changePosition: "Change Position",
    endingMatch: "The referee blows the whistle.",
    leave: "Leave",
    resetMatch: "Reset Match",
    endGame: "End Game",
    endGameInfo: "Please press and hold to end the match.",
    winningTitle: "Congratulations!",
    winningSubtitle: "You won.",
    losingTitle: "Ouch!",
    losingSubtitle: "Good luck next time.",
    errorOpeningMatch: "Something happened, while opening the match. Please try again later.",
    vs: "vs.",
    isFinished:"Match already finished",
    isFinishedDescription: "Unfortunately somebody already finished this match. If you want to play right now, please create another one.",
    newMatch: "Match is ready",
    newMatchDescription: "Show this QR code to your fellow players so they can join your game.",
  },
  matchSummary: {
    goToTournament: "Go to tournament",
    hornSound: "Horn",
    result: "Result"
  },
  tournament: {
    create: "Create a tournament",
    untitledTournament: "(untitled tournament)",
    name: "Tournament Name",
    team: "Team",
    myTeam: "My Team",
    teams: "Teams",
    teamName: "Team name",
    teamLeader: "Team Leader",
    notParticipating: "You're currently not participating",
    register: "Register",
    createdOn: "Created on {date}",
    runningSince: "Running since {date}",
    ranFrom: "Ran from {fromDate} to {toDate}",
    registerTitle: "Register for tournament",
    registeredHeadline: "You've registered with team",
    registrationClosed: "Registration closed",
    registrationOpen: "Registration open",
    participatingAsTeam: "Participating as team",
    unknownTeam: "Unknown",
    editTeam: "Edit Team",
    editTournament: "Edit Tournament",
    cancelRegistration: "Cancel Registration",
    joinTeam: "Join Team",
    joinTeamText: "Do you want to join:",
    joinTeamDescription: "You can invite other players using this QR code. Once they scan the code they can directly register with your team.",
    tournamentUrl: "Tournament URL",
    teamUrl: "Team URL",
    shareTournamentDescription: "Show the QR code to other players or share the url below so they can visit this tournament.",
    shareTournament: "Share tournament",
    tabs: {
      active: 'Active',
      inactive: 'Inactive',
      finished: 'Past'
    },
    leaveTeam: "Leave Team",
    teamDoesntExist: "We're sorry, but the requested team doesn't exist anymore.",
    teamMembers: "Members",
    waitForGroupToFinish: "Please wait for your group to finish. At the moment we are not able to determine the advancing teams.",
    eliminatedMessage: "Unfortunatelly you were eliminated from this tournament.",
    waitForResolvedIssue: "Some teams seem to be ranked equally. Please wait for your tournament administrator to resolve this issue.",
    nextMatch: "Next match",
    nextPersonalMatch: "Your next match",
    gamePlanTitle: "Game Plan",
    groupPrefix: "Group",
    joinMatch: "Join match",
    tournament: "Tournament",
    startTournament: "Start Tournament",
    nrOfRegisteredTeams: "Registered teams",
    deleteTournament: "Delete Tournament",
    winnerAnnouncement: "The winner is",
    isOver: "Tournament is over",
    description: "Description",
    rules: "Rules",
    forfeitMessage: "You forfeited in this tournament.",
    participatingTeams: "Participating teams",
    groupStage: "Group Stage",
    knockoutStage: "Knockout Stage",
    basicInformation: "Basic Information",
    administrative: "Administrative",
    participationNotice: "You can register with a new team or join an existing one by scanning the QR code.",
    state: {
      0: "In Progress",
      1: "Not yet started",
      2: "Finished"
    },
    editor: {
      rulesLabel: "Rules",
      descriptionLabel: "Description"
    },
    group: {
      table: "Table",
      fixtures: "Fixtures",
      team: "Team",
      won: "Won",
      lost: "Lost",
      goalDifference: "GD",
      fixturesNotEligible: "The following fixtures are not eligible, because at least one team forfeited",
    },
    error: {
      fetchTeams: "An error occured while loading the tournament teams.",
      updateTeam: "Couldn't update the team name. Please try again later.",
      joinTeam: "Couldn't join the team.",
      registrationError: "Something unexpected happened while trying to register you to the tournament. Please try again later.",
      tournamentCreation: "Couldn't create the tournament. Please try again later.",
      notExisting: "Tournament doesn't exist",
      cantEditTournament: "The tournament couldn't be updated.",
      participantCountError: "The number of starting teams must be larger than the number of advancing teams.",
      singleAdvancingTeam: "The last stage needs to end with a single advancing team.",
      tooFewTeams: "Not enough teams registered.",
      tooManyTeams: "Too many teams registered.",
      issueFetchError: "Couldn't fetch the issues for the tournament. Please try again later.",
      fetchYourTeam: "Couldn't gather your team information.",
      couldntForfeit: "Unfortunatelly you couldn't forfeit in this tournament."
    },
    teamsPage: {
      kickTeams: "Kick teams",
      kickTeamsNote: "Select at least one team to kick"
    },
    gamePlan: {
      registered: "registered",
      winningTheTournament: "is winning the tournament",
      starting: "starting",
      advancing: "advancing",
      addGroupStage: "Add Group",
      addKnockoutStage: "Add K.O.",
      noGroupsAdded: "No groups added.",
      fields: {
        starting: "Starting",
        advancing: "Advancing",
        rematchRequired: "Rematch Required",
        rounds: "Rounds",
        groups: "Groups",
        name: "Name"
      }
    },
    teamPage: {
      title: "Team",
      members: "Members",
      leaveTournament: "Leave Tournament",
      forfeit: "Forfeit",
      forfeitDialogTitle: "Do you want to forfeit?",
      forfeitDescription: "This will eliminate you and your team mates from the tournament. All matches played in your current stage will not be considered in the tournament anymore. This action can't be undone.",
      enterTeamName: "Please enter your team name to forfeit",
    },
    issues: {
      knockoutRankingTitle: "Choose advancing team",
      knockoutRankingDescription: "Please select the team that will advance to the next stage.",
      groupRankingTitle: "Choose advancing teams",
      noIssues: "No issues present.",
      grouRankingDescription: "Please rearrange the teams below to select the ones that will advance to the next stage.",
      issuesPresent: "There is at least one unresolved issue present in this tournament.",
      viewIssues: "View issues",
      pageTitle: "Issues"
    },
    stages: {
      1: "Final",
      2: "Semifinals",
      4: "Quarterfinals",
      8: "Round of 16",
      16: "Round of 32",
      32: "Round of 64",
      others: "Round of {matchCount}"
    }
  },
  matchHistory: {
    matchHistory: "Match History"
  },
  group: {
    group: "Group",
    addGroup: "Add Group",
    createGroup: "Create Group",
    addPlayer: "Add player",
    deleteGroup: "Delete Group",
    deleteGroupInfo: "You're about to delete this group. Are you sure?",
    currentRanking: "Current Ranking",
    name: "Name",
    description: "Description",
    information: "Information",
    nrOfMembers: "Number of members"
  },
  qr: {
    openingCamera: "Opening camera ...",
    cantOpenCamera: "There seems to be an issue opening your camera. Please contact the support if the problem still occurs.",
    qrCode: "QR Code",
    showQr: "Show QR",
    incorrectCode: "Incorrect QR code",
    matchCodeInformation: "Let the other players scan this QR code so they can enter the match session.",
    incorrectCodeInfo: "The scanned QR code seems to be invalid. Please try again.",
    notAvailable: "Camera not available",
    qrActivationDescription: "This app requires the camera permission to scan QR codes. Please open the device settings and allow camera access for this application."
  },
  shell: {
    appTitle: "Vuzzler",
    version: "Version",
    loggingIn: "Logging in",
    loginFailed: "Login failed. Please try again later.",
    actionBar: "Actions",
    drawer: {
      home: "Home",
      history: "History",
      groups: "Groups",
      signOut: "Sign Out",
      statistics: "Statistics",
      tournaments: "Tournaments",
      support: "Help & feedback",
      signIn: "Log in",
      privacy: "Privacy",
      about: "About Vuzzler",
      settings: "Settings"
    },
    error: {
      description: "Oops something broke. Please try to refresh this page. If the error still appears please contact the support.",
      refresh: "Refresh"
    }
  },
  sounds: {
    horn: "Horn",
    clap: "Clap"
  },
  common: {
    ok: "Ok",
    add: "Add",
    close: "Close",
    cancel: "Cancel",
    create: "Create",
    error: "Error",
    delete: "Delete",
    save: "Save",
    submit: "Submit",
    send: "Send",
    thankYou: "Thank You!",
    returnHome: "Return Home",
    back: "Back",
    refresh: "Refresh",
    optional: "(Optional)",
    returnToPreviousPage: "Return to previous page",
    unknownError: "A unexpected error occured. Please contact the support.",
    validation: {
      required: "Required",
      passwordLength: "Min 8 characters",
      email: "Please provide a valid email",
      passwordsMustMatch: "The passwords entered must match",
      tooShort: "The text you entered is too short.",
      tooLong: "The text you entered is too long.",
      equalOrLarger: "This value can't be smaller than {value}.",
      equalOrSmaller: "This value can't be larger than {value}."
    },
    no: "No",
  },
  info: {
    nfc: {
      title: "Join Match via NFC",
      conditions: "If your table is equipped with a Vuzzler NFC tag you can easily join a match using it.",
      instructions: "Simply place you mobile on top of the tag. Please make sure to activate the NFC feature on your device."
    },
    update: {
      recommendedTitle: "An update is available",
      recommendedText: "If you want to install it please visit your app store.",
      requiredTitle: "A required update is available",
      requiredText: "To provide you with the best experience possible, we need you to install it. Please visit your app store for this matter. Until then we can't let you continue using this version."
    }
  },
  statistics: {
    overview: {
      newSelection: "New Statistic"
    },
    selection: {
      none: "None",
      any: "Any",
    },
    error: {
      noStatistics: "No statistics available",
      noStatisticsDescription: "You haven't played any matches in this constellation yet.",
      notPresentInConstellation: "You can't query statistics for constellations you didn't participate in. Please add yourself back to the constellation in order to recieve statistical information.",
      couldntFetchFavourites: "There was a problem fetching your favorites. Please try again later or contact the support."
    },
    summary: {
      playerSide: {
        0:  {
          0: "alone",
          1: "with anyone"
        },
        1: {
          0: "with {0}",
        }
      },
      opponentSide: {
        0: {
          0: "no one",
          1: "against somebody",
          2: "against some players"
        },
        1: {
          0: "against {0}",
          1: "against {0} and somebody"
        },
        2: {
          0: "against {0} and {1}"
        }
      }
    }
  },
  auth: {
    needAccount: "Need an account?",
    createAccount: "Create your Vuzzler account.",
    creationError: "Something unexpected happened during the account creation process. If something seems off please refresh this page and see if it's ok. Otherwise please contact the support.",
    email: "Email",
    password: "Password",
    repeatPassword: "Repeat password",
    passwordHint: "At least 8 characters",
    error: {
      weakPassword: "The password you chose is too weak. Please provide a stronger one.",
      general: "There was a problem creating you're account. If this issue still persists, please contact the support."
    },
    loginError: "Unfortunately we were not able to sign you in. Please contact the support."
  },
  support: {
    title: "Help & Feedback",
    introduction: "If you detect an issue with the page or want to leave some feedback, please feel free to use this form.",
    description: "Please describe your matter...",
    email: "Your mail address",
    unavailable: "Unfortunatelly it seems like the Support is currently unavailable. Please try again later.",
    successfullySubmitted: "Your feedback was successfully sent.",
    tooMany: "You created more than 10 support tickets in the last hour. We really appreciate your input but unfortunatelly we can't process so many requests. Please collect a few issues the next time and submit them within a single request. You can submit your next support ticket at %TIME%."
  },
  settings: {
    name: "Username",
    fullName: "Full name",
    changeImage: "Change image",
    uploadImage: "Upload image",
    setAvatar: "Set as avatar",
    avatarTitle: "Change profile image",
    errors: {
      fileSizeTooLarge: "The file you tried to upload is too large.",
      unsupportedFileType: "Please provide a valid image.",
      settingImage: "There was an error updating your profile image. Please contact the support.",
      cantSaveProfile: "We could not save your settings. If this error persists, please contact the support."
    },
    validation: {
      noWhitespace: "No whitespace characters allowed."
    }
  },
  landingPage: {
    carousel: {
      text: 'Compete with',
      options: {
        yourFriends: 'your friends',
        yourCoworkers: 'your coworkers',
        playersAroundTheWorld: 'players around the world'
      },
    },
    pageTitles: {
      about: 'About Vuzzler',
      policy: 'Privacy Policy'
    },
    socialLinks: {
      iconsCreatedBy: 'Icons created by',
      from: 'from'
    }
  },
  error: {
    generalError: "Ups ... something broke",
    generalErrorDescription: "Unfortunatelly something unexpected happened. But don't worry, we're on it."
  },
  errorCodes: {
    "0001": "This field is required",
    "0002": "Positive number required",
    "3001": "The number of starting teams must be larger than the number of advancing teams.",
    "3002": "The last stage doesn't end with a single advancing team.",
    "3003": "The number of starting teams in a knockout stage must be a exponential of 2.",
    "3004": "The number of advancing teams in a knockout stage must be 1 or an exponential of 2.",
    "3005": "The number of starting teams does not match the number of advancing teams in the previous stage.",
    "3006": "Groups Stages need at least a single round.",
    "3007": "The registration is already closed. Please contact the organizer.",
    "3008": "Some teams are ranked equally. Please ensure a unique rank for each team.",
    "3009": "More than one team required"
  },
}
