import firebase from 'firebase/app';
import * as firebaseui from 'firebaseui';

export const VueFirebaseAuth = {
    install: function(Vue) {
      this.initializeFirebaseAuthentication();
      const ui = new firebaseui.auth.AuthUI(firebase.auth());
      Vue.prototype.$firebaseui = ui;
    },
    
    /**
     * Initializes Firebase for Authentication purpose
     */
    initializeFirebaseAuthentication() {
    let firebaseConfig = {
        apiKey: "AIzaSyDylbn0AKSSKGvUnxIVULHiJOZ5NBZ1glE",
        authDomain: "vuezler-ddbe2.firebaseapp.com",
        databaseURL: "https://vuezler-ddbe2.firebaseio.com",
        projectId: "vuezler-ddbe2",
        storageBucket: "vuezler-ddbe2.appspot.com",
        messagingSenderId: "773559727792",
        appId: "1:773559727792:web:22336f987a476e6f"
      };

      firebase.initializeApp(firebaseConfig);
    }
}