<template>
  <div class="position-selection">
    <img class="soccer-field" src="@/assets/field.svg" />
    <avatar class="position position-0" @click="setValue(0)" :src="imageAtPosition(0)" accesskey="1" />
    <avatar class="position position-1" @click="setValue(1)" :src="imageAtPosition(1)" accesskey="2" />
    <avatar class="position position-2" @click="setValue(2)" :src="imageAtPosition(2)" accesskey="3" />
    <avatar class="position position-3" @click="setValue(3)" :src="imageAtPosition(3)" accesskey="4" />
  </div>
</template>

<script>
import Avatar from '../modules/core/components/Avatar';

export default {
  name: 'position-selection',
  data: () => ({
    unknownImg: require('@/assets/unknown.svg'),
    defaultAvatar: require('@/assets/images/default-avatar.png')
  }),
  props: {
    value: Number,
    occupiedPositions: {
      type: Array,
      default: () => [undefined, undefined, undefined, undefined]
    },
    player: Object
  },
  methods: {
    imageAtPosition: function(pos) {
      if (pos === this.value) {
        return this.player.image;
      }

      const occupiedPosition = this.occupiedPositions[pos];
      if (occupiedPosition != null) {
        if (occupiedPosition.id === this.player.id) return this.unknownImg;
        else return this.occupiedPositions[pos].profileImage || this.defaultAvatar;
      }
      return this.unknownImg;
    },
    setValue: function(pos) {
      this.$emit('input', pos);
    }
  },
  components: {
    Avatar
  }
}
</script>

<style scoped>
  .position-selection {
    display: inline;
    position: absolute;
  }

  .soccer-field {
    margin: 10% 0 8% 0;
    width: 100%;
  }

  .position {
    color: lightgray;
    position: absolute;
    width: 20%;
    border-radius: 50%;
  }

  .position-0 {
    left: 15%;
    bottom: 0;
  }

  .position-1 {
    right: 15%;
    bottom: 0;
  }

  .position-2 {
    right: 15%;
    top: 0;
  }

  .position-3 {
    left: 15%;
    top: 0;
  }
</style>
