<template>
  <section class="next-fixture">
    <h1 class="title">{{ $t("tournament.nextPersonalMatch") }}</h1>
    <v-row class="fixture-display my-4">
      <v-col class="team away">
        <multi-avatar :avatars="homeTeamAvatars" />
        <span class="teamName">{{ value.homeTeam && value.homeTeam.info.name }}</span>
      </v-col>
      <span>vs</span>
      <v-col class="team home">
        <multi-avatar :avatars="awayTeamAvatars" />
        <span class="teamName">{{ value.awayTeam && value.awayTeam.info.name }}</span>
      </v-col>
    </v-row>
    <div class="action-bar my-4">
      <v-spacer />
      <v-btn block outlined color="primary" @click="navigateToMatch">{{ $t("tournament.joinMatch") }}</v-btn>
    </div>
  </section>
</template>

<script>
import MultiAvatar from '@/components/MultiAvatar';
import { getMatchForMatchUp } from '@/services/vuezlerApi/tournamentService';

export default {
  name: 'next-fixture',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    MultiAvatar
  },
  computed: {
    homeTeamAvatars() {
      if (this.value.homeTeam == null) return [];
      return this.value.homeTeam.participants.map(p => p.profileImage);
    },
    awayTeamAvatars() {
      if (this.value.awayTeam == null) return [];
      return this.value.awayTeam.participants.map(p => p.profileImage);
    },
  },
  methods: {
    navigateToMatch() {
      getMatchForMatchUp(this.value.id).then(({ data: matchRegistration }) => {
        const matchId = matchRegistration.matchId;
        this.$router.push({name: 'match', params: { id: matchId }});
      });
    }
  }
}
</script>

<style scoped>
.fixture-display {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.team {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
}

.teamName {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: inherit;
}

.action-bar {
  display: flex;
  flex-direction: row;
}
</style>