<template>
  <div class="page" :class="{ 'fill-height': fillHeight }">
    <slot></slot>
  </div>
</template>

<script>
// Page wireframe that takes up the entire height.
export default {
  name: 'page',
  props: {
    fillHeight: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.page {
  padding: 1rem;
}

.fill-height {
  height: 100%;
}
</style>