
















import Vue from 'vue';
import VueContentLoader from 'vue-content-loading'

const TITLE_LINE_HEIGHT = 14
const SUBTITLE_LINE_HEIGHT = 10
const LINE_PADDING = 8
const ITEM_PADDING = 16

export default Vue.extend({
    components: { VueContentLoader },
    props: {
        amount: {
            type: Number,
            default: () => 3
        }
    },
    computed: {
        items: function() {
            const result: {id: number, y1: number, y2: number}[] = [];
            let previousItemY = 0;
            for (var i = 0; i < this.amount; i++) {
                result.push({
                    id: i,
                    y1: previousItemY + ITEM_PADDING,
                    y2: previousItemY + ITEM_PADDING + TITLE_LINE_HEIGHT + LINE_PADDING
                });
                previousItemY = previousItemY + ITEM_PADDING + TITLE_LINE_HEIGHT + LINE_PADDING + SUBTITLE_LINE_HEIGHT + ITEM_PADDING
            }
            return result;
        },
        totalHeight: function() {
            return (ITEM_PADDING + TITLE_LINE_HEIGHT + LINE_PADDING + SUBTITLE_LINE_HEIGHT + ITEM_PADDING) * this.amount;
        },
        titleLineHeight: function() { return TITLE_LINE_HEIGHT },
        subtitleLineHeight: function() { return SUBTITLE_LINE_HEIGHT },
    }
})
