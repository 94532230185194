<template>
  <div v-editable="blok">
    <template v-for="item in blok.body">
        <component :key="item._uid"
          :blok="item"
          :is="item.component"
          class="area"
        >
        </component>
    </template>
  </div>
</template>

<script>
export default {
  name: 'crm-page',
  props: ['blok'],
}
</script>

<style scoped>
  .area {
    width: 75%;
    margin: 0 auto;
  }
</style>
