<template>
  <v-card>
    <v-card-title class="title">{{ $t('info.update.recommendedTitle') }}</v-card-title>
    <v-card-text>{{ $t('info.update.recommendedText') }}</v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text @click="close">{{ $t('common.ok') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'update-recommended',
  methods: {
    close: function() {
      this.$emit('close');
    }
  }
}
</script>