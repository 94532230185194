<template>
  <v-footer fixed app class="actions" :style="{ height }">
    <slot></slot>
  </v-footer>
</template>

<script>
export default {
  name: 'action-footer',
  props: {
    height: {
      type: String,
      default: 'initial !important'
    }
  }
}
</script>


<style scoped>
.actions {
  padding: 8px;
  width: 100%;
  background-color: transparent !important;
  background-image: linear-gradient(rgba(255,255,255,0), var(--v-background-base) 24px) !important;
}
</style>
