<template>
<p2r-page @refresh="fetchMyTeam">
  <stacked-page-toolbar slot="toolbar"></stacked-page-toolbar>
  <div v-if="isLoading" class="loading my-2">
    <v-progress-circular indeterminate color="primary" />
  </div>
  <v-container v-if="team" style="height: 100%">
    <h1 class="headline text--lighten-2">{{ team.info.name }}</h1>

    <section class="participants mt-2">
      <h2 class="title mb-1">{{ $t('tournament.teamPage.members') }}</h2>
      <v-list light>
        <v-list-item
          v-for="participant in team.participants"
          :key="participant.id"
        >
          <v-list-item-avatar width="auto" tile>
            <avatar :size="40" :src="participant.profileImage" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              {{participant.name}}
              <v-chip small color="primary" class="ml-1" v-if="team.administratorId === participant.id">{{ $t('tournament.teamLeader') }}</v-chip>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </section>

    <section class="leave mt-2" v-if="isTeamAdministrator">
      <h2 class="title mb-1">{{ $t('tournament.teamPage.leaveTournament') }}</h2>
      <v-btn outlined block color="error" @click="openForfeitDialog">{{ $t('tournament.teamPage.forfeit') }}</v-btn>
    </section>
  </v-container>

  <v-dialog v-model="isForfeitDialogOpen" v-if="team">
    <forfeit-dialog :teamName="team.info.name" @forfeit="forfeitTournament" @cancel="closeForfeitDialog"></forfeit-dialog>
  </v-dialog>
</p2r-page>
</template>

<script>
import { shellInteractorMixin } from '@/modules/core/mixins/shellInteractorMixin';
import { getMyTeam, forfeit } from '@/services/vuezlerApi/tournamentService';
import StackedPageToolbar from '@/toolbar/StackedPageToolbar';
import Avatar from '@core/components/Avatar';
import ForfeitDialog from '@tournament/components/ForfeitDialog';
import { GETTERS as AuthGetters } from '@/vuex/auth.module';
import { mapGetters } from 'vuex';

export default {
  name: 'team',
  data: function() {
    return {
      team: undefined,
      isLoading: false,
      isForfeitDialogOpen: false,
      teamName: ''
    }
  },
  mixins: [ shellInteractorMixin ],
  props: {
    tournamentId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      currentUserId: AuthGetters.USER_ID
    }),
    isTeamAdministrator: function() {
      return this.team.administratorId === this.currentUserId;
    }
  },
  methods: {
    fetchMyTeam: function() {
      this.isLoading = true;
      getMyTeam(this.tournamentId).then(({data}) => {
        this.team = data;
        this.isLoading = false;
      }).catch(() => {
        this.showError(this.$t('tournament.errors.fetchYourTeam'));
        this.isLoading = false;
        this.$router.back();
      });
    },

    openForfeitDialog: function() {
      this.isForfeitDialogOpen = true;
    },
    closeForfeitDialog: function() {
      this.isForfeitDialogOpen = false;
    },
    forfeitTournament: function() {
      forfeit(this.tournamentId).then(() => {
        this.$router.back();
        this.isForfeitDialogOpen = false;
      }).catch(() => {
        this.showError(this.$t('tournament.error.couldntForfeit'));
      })
    }
  },
  mounted: function() {
    this.fetchMyTeam();
    this.setTitle({ title: this.$t('tournament.teamPage.title') })
  },
  components: {
    StackedPageToolbar,
    Avatar,
    ForfeitDialog,
  }
}
</script>

<style scoped>
.loading {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>
