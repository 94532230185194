<template>
  <svgicon ref="iconElement" :class="textColor" :icon="name" :height="height" :width="width"/>
</template>

<script>
export default {
  name: 'svg-icon',
  computed: {
    height: function() {
      return this.size === 'icon' ? '22px' : this.size;
    },
    width: function() {
      return this.size === 'icon' ? '22px' : this.size;
    },
    textColor: function() {
      return `${this.color}--text`
    },
  },
  props: {
    name: String,
    size: {
      type: String,
      default: 'custom'
    },
    color: {
      type: String,
      default: 'default'
    }
  },
}
</script>

<style lang="scss" scoped>
$colors: "yellow-base", "grey-darken4", "white-base";

@each $color in $colors {
  .svg-fill.#{$color}--text {
    fill: var(--v-#{$color});
  }
}

.svg-fill.default--text {
  fill: rgba(0,0,0,.54);
}

.v-list-item--active .svg-fill {
  fill: black;
}
</style>