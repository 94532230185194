/**
 * Responsible for updating the match session state with provided events
 * from the event source.
 */
import Vue from 'vue';
import { CONNECTION_STATE } from './connectionState';

export function updateScore(state, event) {
  const side = event.side === 0 ? 'home' : 'away';
    Vue.set(state.goals, side, Math.max(state.goals[side]+event.amount, 0));
}

export function updateConnections(state, event) {
  const playerId = event.playerId;
  const existingConnection = state.connections[playerId]
  if (!existingConnection) {
    if (event.connectionState === CONNECTION_STATE.Connected) {
      Vue.set(state.connections, playerId, event);
    }
  } else if (
    existingConnection.connectionId === event.connectionId ||
    existingConnection.connectionId !== event.connectionId && event.connectionState === CONNECTION_STATE.Connected)
  {
    Vue.set(state.connections, playerId, event);
  }
}

export function updatePlayers(state, event) {
  for(let playerIndex in state.players) {
    if (state.players[playerIndex] === event.userId)
    Vue.set(state.players, playerIndex, undefined);
  }

  // Skip setting position when spectating
  if (event.position === 100) return;

  Vue.set(state.players, event.position,event.positionUpdate === 0 ? event.userId : undefined);
}