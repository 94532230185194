<template>
  <page class="card-background">
    <stacked-page-toolbar :title="$t('tournament.issues.pageTitle')"></stacked-page-toolbar>
    <vue-pull-refresh :on-refresh="fetchIssues" :config="pullRefreshConfig">
      <v-container>
        <v-row v-if="isLoadingIssues">
          <v-col>
            <vue-content-loading v-if="isLoadingIssues" :width="300" :height="440">
              <rect x="0" y="0" rx="3" ry="3" width="300" height="200" />
              <rect x="0" y="220" rx="3" ry="3" width="300" height="200" />
            </vue-content-loading>
            
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col align="center" v-if="issues.length === 0">
            <div>{{ $t('tournament.issues.noIssues') }}</div>
            <a @click="returnToPreviousPage">{{ $t('common.returnToPreviousPage') }}</a>
          </v-col>
          <v-col v-else>
            <issue v-for="(issue, idx) of issues" :key="idx" 
              :tournamentId="tournamentId"
              :issue="issue"
              class="issue"
            />
          </v-col>
        </v-row>
      </v-container>
    </vue-pull-refresh>
  </page>
</template>

<script>
import StackedPageToolbar from '@/toolbar/StackedPageToolbar';
import Issue from '@tournament/components/Issue';
import VueContentLoading from 'vue-content-loading';
import VuePullRefresh from 'vue-pull-refresh';
import { shellInteractorMixin } from '../../core/mixins/shellInteractorMixin';
import { Actions } from '@tournament/states/issues';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'issues',
  data: function() {
    return {
      isLoadingIssues: false,
      pullRefreshConfig: {
        readyLabel: this.$i18n.t('common.refresh'),
        startLabel: this.$i18n.t('common.refresh'),
        loadingLabel: this.$i18n.t('common.refresh'),
        errorLabel: ''
      },
    }
  },
  props: {
    tournamentId: {
      type: String
    }
  },
  computed: {
    ...mapState({
      issues: state => state.issues.issues
    })
  },
  mixins: [ shellInteractorMixin ],
  methods: {
    ...mapActions({
      getIssues: Actions.GetIssues
    }),
    fetchIssues: function() {
      this.isLoadingIssues = true;
      this.getIssues(this.tournamentId).then(() => {
        this.isLoadingIssues = false;
      }).catch(() => {
        this.isLoadingIssues = false;
        this.showError(this.$t('tournament.error.issueFetchError'));
      });
    },
    returnToPreviousPage: function() {
      this.$router.back();
    }
  },
  mounted: function() {
    this.fetchIssues();
  },
  components: {
    StackedPageToolbar,
    VueContentLoading,
    Issue,
    VuePullRefresh,
  }
}
</script>

<style scoped>
.card-background {
  background-color: var(--v-grey-lighten2);
}

.actions {
  display: flex;
  flex-direction: row;
}

.issue {
  margin-bottom: 20px;
}
</style>