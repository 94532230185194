import { store } from '@/vuex/store';
import { Actions } from '@tournament/states/tournament';

export function ensureTournamentHubConnection(to, from, next) {
  if(to.meta.requiresHubConnection) {
    if (to.params.id != from.params.id || !store.state.tournament.hubInstance) {
      store.dispatch(Actions.Initialize, to.params.id);
    }
  } else if(from.meta.requiresHubConnection && !to.meta.requiresHubConnection) {
    store.dispatch(Actions.Destroy, to.params.id);
  }

  next();
}