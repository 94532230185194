<template>
  <v-container class="text-center" id="download" v-if="!isCordova">
    <h1 class="display-1 mb-4 blok-title">{{blok.title}}</h1>
    <p>{{blok.description}}</p>
    <section class="download-buttons flex-column flex-sm-row align-center">
      <a href="https://apps.apple.com/us/app/vuzzler/id1477529406?mt=8" style="overflow:hidden;background:url(https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2019-09-17&kind=iossoftware&bubble=ios_apps) no-repeat;width:135px;height:40px;" class="download-button"></a>
      <a href='https://play.google.com/store/apps/details?id=com.vuzzler&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' class="playstore download-button"></a>
    </section>
  </v-container>
</template>

<script>
import { isRunningInCordova } from '@core/cordova/cordovaApi';

export default {
  name: 'crm-download',
  props: ['blok'],
  computed: {
    isCordova: function() {
      return isRunningInCordova();
    }
  },
}
</script>

<style scoped>
.download-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 -16px;
}

.playstore {
  display:inline-block;
  overflow:hidden;
  background: url('~@/assets/images/playstore.png') no-repeat;
  background-size: cover;
  width:135px;
  height:40px;
}

.download {
  text-align: center;
}

.download-button {
  display: block;
  margin: 8px 8px 0px 8px;
}
</style>