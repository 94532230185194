import { getUserId, getUserMail } from "../modules/core/auth/vuzzlerJwtDecode";
import { getPlayer } from "../modules/core/services/playerService";

export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const SHOW_LOADING = 'SHOW_LOADING';
export const ENABLE_LOADING = 'ENABLE_LOADING';


export const ACTIONS = {
  SET_AUTH_TOKEN: "SetAuthToken",
  SIGN_OUT: "SignOut",
  SET_USER: 'SetUser',
  GATHER_USER_INFO: '[🔑 User] Gather User Info'
}

export const GETTERS = {
  USER_ID: '[🔑 Auth] User Id',
  TOKEN: '[🔑 Auth] Token',
  USER_MAIL: '[🔑 Auth] User Mail',
  IS_AUTHENTICATED: '[🔑 Auth] Is Authenticated',
  USER_NAME: '[🔑 Auth] User Name'
}

const MUTATIONS = {
  SET_AUTH_TOKEN: '[🔑 Auth] Set auth token',
  SET_USER: '[🔑 Auth] Set user'
}

const state = {
  token: localStorage.getItem('user-token') || '',
  isLoading: false,
  loadingState: 'loading...',
  googleAuth: undefined,
  user: undefined
}

const getters = {
  [GETTERS.IS_AUTHENTICATED]: state => !!state.token,
  authStatus: state => state.status,
  isLoading: state => state.isLoading,
  loadingState: state => state.loadingState,
  [GETTERS.TOKEN]: state => state.token,
  [GETTERS.USER_ID]: state => getUserId(state.token),
  [GETTERS.USER_MAIL]: state => getUserMail(state.token),
  [GETTERS.USER_NAME]: state => state.user && state.user.name
}

const actions = {
  [ACTIONS.SET_AUTH_TOKEN]: ({commit}, token) => {
    commit(MUTATIONS.SET_AUTH_TOKEN, token)
  },
  [ACTIONS.SIGN_OUT]: ({commit}) => {
    commit(MUTATIONS.SET_AUTH_TOKEN, '');
    commit(MUTATIONS.SET_USER, undefined);
  },
  [ENABLE_LOADING]: ({commit}, loadingData) => {
    return (
      commit(SHOW_LOADING, loadingData)
    )
  },
  [ACTIONS.SET_USER]: ({commit}, user) => {
    commit(MUTATIONS.SET_USER, user);
  },
  [ACTIONS.GATHER_USER_INFO]: ({state, commit, getters}) => {
    if (state.user != null) return Promise.resolve();

    const userId = getters[GETTERS.USER_ID];
    return getPlayer(userId).then(({data: user}) => {
      commit(MUTATIONS.SET_USER, user)
    });
  }
}

const mutations = {
  [MUTATIONS.SET_AUTH_TOKEN]: (state, token) => {
    state.token = token;
    if (token !== '') {
      localStorage['user-token'] = token;
    } else {
      localStorage.removeItem('user-token');
    }
  },
  [SHOW_LOADING]: (state, loadingData) => {
    state.loadingState = loadingData.loadingState;
    state.isLoading = loadingData.isLoading;
  },
  [MUTATIONS.SET_USER]: (state, user) => {
    state.user = user;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}