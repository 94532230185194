import firebase from 'firebase/app'

export const unauthorizedOnlyGuard = (to, from, next) => {
  const unsubscribe = firebase.auth().onAuthStateChanged(user => {
    unsubscribe();
    if (user) {
      next({
        path: '/'
      });
    } else {
      next();
    }
  })
}