<template>
    <v-card>
      <v-card-title class="headline">{{ $t("match.kickedTitle") }}</v-card-title>
      <v-card-text>{{ $t("match.kickedDescription") }}</v-card-text>
      <v-card-actions>
        <v-layout row wrap>
          <v-flex xs12 sm4>
            <v-btn text block @click="$emit('onQuit')">{{ $t("common.returnHome") }}</v-btn>
          </v-flex>
          <v-flex xs12 sm4>
            <v-btn text block @click="$emit('changePosition')">{{ $t("match.changePosition") }}</v-btn>
          </v-flex>
          <v-flex xs12 sm4>
            <v-btn text block @click="$emit('onReconnect')">{{ $t("match.reconnect") }}</v-btn>
          </v-flex>
        </v-layout>          
      </v-card-actions>
    </v-card>
</template>

<script>
export default {
  name: "kicked-dialog",
}
</script>
