<template>
    <v-card>
        <v-card-title>
            <h1 class="title">{{ $t('match.newMatch') }}</h1>
        </v-card-title>
        <v-card-text v-if="matchId">
            <v-layout column justify-center>
                <v-flex>
                    {{ $t('match.newMatchDescription') }}
                </v-flex>
                <v-flex>
                    <QrCode class="qr-code" :value="matchUrl"/>
                </v-flex>
            </v-layout>
        </v-card-text>
        <v-card-actions>
            <v-spacer/>
            <v-btn text @click="cancel">{{ $t('common.cancel') }}</v-btn>
            <v-btn text @click="joinMatch">{{ $t('match.join') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import QrCode from '../QrCode';

export default {
    name: 'new-match',
    props: {
        matchId: String
    },
    components: {
        QrCode
    },
    computed: {
        matchUrl: function() {
            return `${process.env.VUE_APP_VUZZLER_APP}/match/${this.matchId}`;
        }
    },
    methods: {
        cancel: function() {
            this.$emit('cancel');
        },
        joinMatch: function() {
            this.$emit('join');
        }
    }
}
</script>

<style scoped>
.qr-code {
    margin-left: auto;
    margin-right: auto;
}
</style>