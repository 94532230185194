<template>
  <div class="dots" :style="{ 'height': `${size}px`}">
    <div xs4 class="dot" :class="color" :style="dotStyle"></div>
    <div xs4 class="dot" :class="color" :style="dotStyle"></div>
    <div xs4 class="dot" :class="color" :style="dotStyle"></div>
  </div>
</template>

<script>
export default {
  name: 'bouncing-dots',
  props: {
    size: {
      type: Number,
      default: 14
    },
    color: {
      type: String,
      default: "grey lighten-2"
    }
  },
  computed: {
    dotSize: function() {
      return Math.round(this.size*0.4);
    },
    dotStyle: function(){
      return {
        'width': `${this.dotSize}px`,
        'height': `${this.dotSize}px`,
        'background': this.color,
      }
    }
  }
}
</script>


<style scoped>
html, body {
	margin:0;
	padding:0;
}

body {
	background:#F6F7F8;
}

.dots {
	position:relative;
	text-align:center;
}

.dots>.dot {
		display:inline-block;
		border-radius:50%;
		margin-right:3px;
		animation: wave 1.3s linear infinite;
}

.dots :nth-child(2) {
			animation-delay: -1.1s;
		}

.dots :nth-child(3) {
			animation-delay: -0.9s;
}

@keyframes wave {
	0%, 60%, 100% {
		transform: initial;
	}

	30% {
		transform: translateY(-7px);
	}
}
</style>
