<template>
  <v-navigation-drawer :mobile-breakpoint="3000" app @input="onDrawerInput" :value="isDrawerOpen" class="vuzzler-drawer">
    <v-app-bar flat dark color="primary">
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $t("shell.appTitle") }}</v-list-item-title>
            <v-list-item-subtitle>{{ $t("shell.version") }} {{version}}</v-list-item-subtitle>        
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-app-bar>

    <v-list class="vuzzler-nav">
        <v-list-item :to="{ name: 'home' }" exact>
          <v-list-item-action>
            <v-icon>home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("shell.drawer.home") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'matchHistory' }" exact v-if="isAuthenticated" aria-label="show match history">
          <v-list-item-action>
            <v-icon>history</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("shell.drawer.history") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'statisticSelection' }" exact v-if="isAuthenticated" aria-label="show statistics">
          <v-list-item-action>
            <v-icon>leaderboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("shell.drawer.statistics") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'tournaments' }" exact v-if="isAuthenticated" aria-label="show tournaments">
          <v-list-item-action>
            <v-icon>emoji_events</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("shell.drawer.tournaments") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      
        <groups-drawer v-if="isAuthenticated"></groups-drawer>

        <v-list-item :to="{ name: 'settings' }" exact v-if="isAuthenticated">
          <v-list-item-action>
            <v-icon>settings</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("shell.drawer.settings") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
    </v-list>

    <v-spacer />

    <v-list>
      <v-list-item v-if="isSupportVisible" :to="{ name: 'support' }">
        <v-list-item-action>
            <v-icon>feedback</v-icon>
            </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("shell.drawer.support") }}</v-list-item-title>
          </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="isSupportVisible" :to="{ name: 'privacy' }">
        <v-list-item-action>
            <v-icon>lock</v-icon>
            </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("shell.drawer.privacy") }}</v-list-item-title>
          </v-list-item-content>
      </v-list-item>

      <v-divider />
      <v-list-item @click='signOut()' v-if="isAuthenticated" role="button" aria-label="sign out">
        <v-list-item-action>
          <v-icon>exit_to_app</v-icon>
          </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("shell.drawer.signOut") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item :to="{ name: 'login', query: { redirect: $route.fullPath } }" v-else>
        <v-list-item-action>
          <v-icon>exit_to_app</v-icon>
          </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("shell.drawer.signIn") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {ACTIONS as AuthActions, GETTERS} from '../vuex/auth.module';
import GroupsDrawer from './GroupsDrawer';
import { mapActions, mapState, mapGetters } from 'vuex';
import { ACTIONS } from '../vuex/shell.module';
import firebase from 'firebase/app';
import { getVersion } from '../logic/common/shell';
import { isRunningInCordova } from '../modules/core/cordova/cordovaApi';

export default {
  name: 'vuezler-app-drawer',
  data: function () {
    return {
    open: false,
    version: getVersion()
    }
  },
  computed: {
    isSupportVisible: function() {
      return !(!this.isAuthenticated && isRunningInCordova())
    },
    ...mapState({
      isDrawerOpen: state => state.shell.isDrawerOpen
    }),
    ...mapGetters({
      isAuthenticated: GETTERS.IS_AUTHENTICATED
    })
  },
  components: {
    GroupsDrawer
  },
  methods: {
    signOut() {
      firebase.auth().signOut().then(() => {
        this.$store.dispatch(AuthActions.SIGN_OUT);
        window.location.reload();
      });
    },
    onDrawerInput(shouldOpen) {
      this.toggleDrawer(shouldOpen);
    },
    ...mapActions({
      toggleDrawer: ACTIONS.TOGGLE_DRAWER
    })
  },
}
</script>

<style scoped>
/deep/.v-navigation-drawer__content {
  display: flex;
  flex-direction: column;
}

.v-navigation-drawer__content>* {
  flex-shrink: 1;
  flex-grow: 0;
}
</style>