























import Vue, { PropType } from 'vue'
import { StackedBarChartData } from './models';

export default Vue.extend({
  name: 'stacked-bar-chart',
  props: {
    label: {
      type: String,
      required: true
    },
    data: {
      type: Array as PropType<StackedBarChartData>,
      required: true
    },
    spread: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    totalAmount(): number {
      return this.data.reduce((total, previous) => total + previous.value, 0);
    },
    filteredData(): StackedBarChartData {
      return this.data.filter(dataPoint => dataPoint.value !== 0);
    }
  }
})
