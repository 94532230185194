<template>
  <v-app-bar app dark class="primary">
    <v-btn dark text icon @click="navigateBack">
      <v-icon>arrow_back</v-icon>
    </v-btn>
    
    <v-toolbar-title>
      {{ this.$t('tournament.tournament') }}
    </v-toolbar-title>

    <v-spacer />

    <v-btn v-if="showAdministratorTools && isInactiveTournament" text icon dark @click="navigateToEditPage" class="ma-0">
      <v-icon>edit</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapActions } from 'vuex';
import { Actions as TournamentActions } from '@tournament/states/tournament';
import { TournamentState } from '@/logic/tournaments/tournamentState';

export default {
  name: 'tournament-toolbar',
  props: {
    showAdministratorTools: {
      type: Boolean,
      default: () => false
    },
    tournamentState: {
      type: Number,
      required: true,
    }
  },
  computed: {
    isInactiveTournament: function() {
      return this.tournamentState === TournamentState.Inactive;
    }
  },
  methods: {
    navigateBack: function() {
      this.resetTournament();
      this.$router.push({ name: 'tournaments' });
    },
    navigateToEditPage: function() {
      this.$router.push({ name: 'tournamentEdit', params: { id: this.$route.params.id}});
    },
    ...mapActions({
      setQrVisibility: TournamentActions.SetQrVisibility,
      resetTournament: TournamentActions.ResetTournament,
    }),
  }
}
</script>
