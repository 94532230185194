import { mapGetters, mapActions } from 'vuex';
import { GETTERS, ACTIONS } from '../../../vuex/playerModule';
import { ACTIONS as ShellActions } from '../../../vuex/shell.module';

export const playerDataMixin = {
  computed: {
    ...mapGetters({
      profileImage: GETTERS.PROFILE_IMAGE
    })
  },
  methods: {
    ...mapActions({
      fetchInformation: ACTIONS.FETCH_INFORMATION,
      showError: ShellActions.SHOW_ERROR
    })
  },
  mounted: function() {
    this.fetchInformation().catch(() => {
      this.showError(this.$t('player.errors.cantFetchInfo'));
    });
  }
}