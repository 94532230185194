import { en } from './locale.en.js';
import VueI18n from 'vue-i18n';
import Vue from 'vue';

Vue.use(VueI18n);

const messages = {
  en
};

export const i18n = new VueI18n({
  locale: 'en',
  messages
});