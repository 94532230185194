<template>
  <v-app>
    <v-snackbar :value="errorMessage" :timeout="-1">
      {{ errorMessage }}
      <v-btn color="pink" text @click="closeErrorMessage">
        Close
      </v-btn>
    </v-snackbar>
    
    <vuezler-app-drawer v-if="isLoggedIn" ref="appDrawer"/>
    <router-view name="toolbar" v-if="isLoggedIn" />

    <v-main>
      <router-view />
    </v-main>

    <v-dialog v-model="isUpdateDialogVisible" persistent light>
      <update-recommended v-if="isUpdateRecommended" @close="isUpdateDialogVisible=false"></update-recommended>
      <update-required v-if="isUpdateRequired"></update-required>
    </v-dialog>
  </v-app>
</template>

<script>
import VuezlerAppDrawer from './components/VuezlerAppDrawer.vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import { GETTERS } from './vuex/auth.module';
import { ACTIONS as ShellActions } from './vuex/shell.module';
import { checkVersion } from './services/vuezlerApi/info.service';
import { UpdateUrgency } from './services/vuezlerApi/models/updateUrgency';
import UpdateRecommended from './components/info/UpdateRecommended';
import UpdateRequired from './components/info/UpdateRequired';
import { isRunningInCordova } from './modules/core/cordova/cordovaApi';
import firebase from 'firebase/app';

export default {
  data: () => ({
    updateUrgency: UpdateUrgency.NotRequired,
    isUpdateDialogVisible: false,
    isLoggedIn: undefined,
    loggedInStateUnsubscriber: undefined
  }),
  computed: {
    ...mapGetters({
      isAuthenticated: GETTERS.IS_AUTHENTICATED
    }),
    ...mapState({
      errorMessage: state => state.shell.errorMessage
    }),
    isUpdateRecommended: function() {
      return this.updateUrgency === UpdateUrgency.Recommended;
    },
    isUpdateRequired: function() {
      return this.updateUrgency === UpdateUrgency.Required
    }
  },
  name: 'App',
  components: {
    VuezlerAppDrawer,
    UpdateRecommended,
    UpdateRequired
  },
  methods: {
    openDrawer: function() {
      this.$refs.appDrawer.openDrawer()
    },
    ...mapActions({
      closeErrorMessage: ShellActions.CLOSE_ERROR
    })
  },
  mounted: function() {
    if (isRunningInCordova()) {
      checkVersion(window.$config.version).then(({data: result}) => {
        this.isUpdateDialogVisible =
          result.urgency === UpdateUrgency.Recommended || 
          result.urgency === UpdateUrgency.Required;
        this.updateUrgency = result.urgency;
      });
    }

    this.loggedInStateUnsubscriber = firebase.auth().onAuthStateChanged(user => {
      this.isLoggedIn = !!user
    })
  },
  destroyed: function() {
    this.loggedInStateUnsubscriber();
  },
}
</script>

<style>
html {
  overscroll-behavior: contain;
}
</style>
