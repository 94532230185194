<template>
  <div>
    <div v-for="group in groups" :key="group.id" class="fixture">
      <div class="team-data" v-for="(team, idx) in group" :key="idx">
        <div
          class="team-name"
          :class="{ 'disabled' : team.hasForfeited }"
        >
          {{ team.team && team.team.name }}
        </div>
        <div class="team-score" v-for="(score, idx) in team.score" :key="idx">{{ score }}</div>
      </div>
    </div>
  </div>
</template>

<script>

/**
 * Fixtures in knockout stages always consist of 2 teams. There should always be n fixtures where n is the number of rounds that are played.
 */
export default {
  name: 'knockout-stage',
  props: {
    value: {
      type: Array,
      required: true
    },
  },
  methods: {
    updateGroupWithResult(group, teamId, teamScore) {
      if (group[teamId] === undefined) {
        group[teamId] = {
          team: this.participants[teamId],
          score: [ teamScore ],
          hasForfeited: this.participants[teamId] && this.participants[teamId].forfeitTimestamp != null
        }
      } else {
        group[teamId].score.push(teamScore);
      }
    },
  },
  computed: {
    groups() {
      return this.value.map(matchUp => {
        const group = {};
        for (const fixture of matchUp.fixtures) {
          this.updateGroupWithResult(group, fixture.firstTeam, fixture.firstScore);
          this.updateGroupWithResult(group, fixture.secondTeam, fixture.secondScore);
        }
        return Object.values(group);
      })
    },
    participants() {
      const result = {};
      for(const participant of this.value.flatMap(matchUp => matchUp.participants)) {
        if (participant.team != null) {
          result[participant.participantId] = {
            ...participant.team,
            forfeitTimestamp: participant.forfeitTimestamp
          };
        }
      }
      return result;
    }
  }
}
</script>

<style scoped>
.fixture {
  display: flex;
  flex-direction: column;

  border: 1px solid var(--v-grey-base);
  border-radius: 4px;
}

.fixture:not(:last-child) {
  margin-bottom: 16px;
}

.team-data {
  display: flex;
  flex-direction: row;
}

.team-data * {
  padding: 4px;
  min-height: 32px;
}

.team-data:nth-child(2) {
  border-top: 1px solid var(--v-grey-base);
}

.team-data:nth-child(odd) {
  background-color: var(--v-grey-lighten2);
}

.team-name {
  flex-grow: 1;
}

.team-name.disabled {
  text-decoration: line-through;
}

.team-score {
  border-left: 1px solid var(--v-grey-base);
  width: 40px;
}
</style>
