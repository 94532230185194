/**
 * Handles the event sourcing logic of match data.
 * A match state consists of various information like: goals, active players, ...
 * Each information can be changed using events. A user client publishes various events to all connected clients using SignalR.
 * This module is responsible for maintaining the state that is represented by those events.
 */
import { updateConnections, updateScore, updatePlayers } from '../logic/matchSession/matchStateReducer';
import { GETTERS as AuthGetters } from './auth.module';

export const ACTIONS = {
  ADD_EVENT: 'ADD_EVENT',
  RESET_EVENTS: 'RESET_EVENTS',
}

export const GETTERS = {
  IsPlaying: 'IS_PLAYING'
}

const MUTATIONS = {
  UPDATE_PLAYERS: '[⚽ MatchState] Update players',
  UPDATE_CONNECTIONS: '[⚽ MatchState] Update connections',
  UPDATE_SCORE: '[⚽ MatchState] Update score',
  RESET_STATE: '[⚽ MatchState] Reset state',
  RECALCULATE_STATE: '[⚽ MatchState] Recalculate state'
}

const state = {
  goals: {
    home: 0,
    away: 0,
  },
  connections: {},
  players: [undefined, undefined, undefined, undefined],
  consumedEvents: [],
  lastEventTimestamp: undefined
}

const getters = {
  [GETTERS.IsPlaying]: (state, rootGetters) => state.players.findIndex(playerId => playerId === rootGetters[AuthGetters.USER_ID]) !== -1
}

const actions = {
  [ACTIONS.ADD_EVENT]: ({commit, state}, { events }) => {
    const newEvents = removeConsumedEvents(events, state.consumedEvents);
    if (newEvents.length <= 0) {
      return;
    }

    if (newEvents.some(happenedBefore(state.lastEventTimestamp))) {
      commit(MUTATIONS.RECALCULATE_STATE, { events: newEvents });
    } else {
      newEvents.forEach(commitEvent(commit));
    }
  },
  [ACTIONS.RESET_EVENTS]: ({commit}, options) => {
    commit(MUTATIONS.RESET_STATE, options);
  },
}

const mutations = {
  [MUTATIONS.UPDATE_SCORE]: (state, { event }) => {
    updateScore(state, event);
    state.consumedEvents.unshift(event);
    state.lastEventTimestamp = event.timestamp
  },
  [MUTATIONS.UPDATE_CONNECTIONS]: (state, { event }) => {
    updateConnections(state, event);
    state.consumedEvents.unshift(event);
    state.lastEventTimestamp = event.timestamp
  },
  [MUTATIONS.UPDATE_PLAYERS]: (state, { event }) => {
    updatePlayers(state, event);
    state.consumedEvents.unshift(event);
    state.lastEventTimestamp = event.timestamp
  },
  [MUTATIONS.RECALCULATE_STATE]: (state, { events }) => {
    resetMatchState(state);
    state.consumedEvents = state.consumedEvents.concat(events);
    state.consumedEvents.sort((first, second) => first.timestamp-second.timestamp);
    state.lastEventTimestamp = undefined;

    for(let event of state.consumedEvents) {
      switch(event.type) {
        case ('GoalEvent'):
          updateScore(state, event);
          break;
        case ('ConnectionEvent'):
          updateConnections(state, event);
          break;
        case ('PositionUpdateEvent'):
          updatePlayers(state, event);
          break;
      }
    }

    state.lastEventTimestamp = state.consumedEvents[state.consumedEvents.length-1].timestamp
  },
  [MUTATIONS.RESET_STATE]: (state, options) => {
    const keepConnections = options && options.keepConnections || false;
    resetMatchState(state, keepConnections);

    state.consumedEvents = [];
    state.lastEventTimestamp = undefined;
  },
}

export default {
  state,
  actions,
  mutations,
  getters
}

/**
 * Get a function for committing an event to the match state based on its type.
 * @param {CommitFn} commit vuex function for mutating the store.
 */
const commitEvent = (commit) => (event) => {
  switch(event.type) {
    case ('GoalEvent'):
      commit(MUTATIONS.UPDATE_SCORE, { event });
      break;
    case ('ConnectionEvent'):
      commit(MUTATIONS.UPDATE_CONNECTIONS, { event });
      break;
    case ('PositionUpdateEvent'):
      commit(MUTATIONS.UPDATE_PLAYERS, { event });
      break;
  }
}

/**
 * Removes already consumed events from a list of new one.
 * @param {event[]} events list of new events
 * @param {event[]} consumedEvents list of already consumed events
 */
function removeConsumedEvents(events, consumedEvents) {
  const consumedEventIds = consumedEvents.map(event => event.id);
  return events.filter(event => !consumedEventIds.includes(event.id));
}

/**
 * Returns a function that checks whether a event happened before a provided timestamp.
 * @param {timestamp} timestamp the timestamp used for the check.
 */
const happenedBefore = (timestamp) => (event) => {
  return event.timestamp < timestamp;
}

/**
 * Reset the current state of the match
 * @param {MatchState} state contains the match state
 * @param {boolean} keepConnections should match connections be kept after reset
 */
function resetMatchState(state, keepConnections = false) {
  state.goals = {
    home: 0,
    away: 0
  };

  state.players = [undefined, undefined, undefined, undefined];
  
  if (!keepConnections) {
    state.connections = {};
  }
}