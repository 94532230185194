import * as SignalR from '@microsoft/signalr';
import accessTokenFactory from '@core/auth/accessTokenFactory';

export class TournamentHub {
  constructor(tournamentId) {
    this.connection =  new SignalR.HubConnectionBuilder()
      .withUrl(`${window.$config.vuzzlerApi}/tournamenthub?tournamentId=${tournamentId}`, { accessTokenFactory })
      .build();
  }
  
  addHandler(methodName, handler) {
    this.connection.on(methodName, handler);  
  }
  
  establishConnection() {
    return this.connection.start();
  }

  destroyConnection() {
    return this.connection.stop();
  }
}