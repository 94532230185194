<template>
  <v-card>
    <v-card-title class="title">{{ $t('tournament.shareTournament') }}</v-card-title>
    <v-card-text>
      <p class="body-1">{{ $t('tournament.shareTournamentDescription') }}</p>
      <qr-code class="mx-auto" :value="tournamentUrl" />
      <shareable-text :text="this.tournamentUrl" :label="$t('tournament.tournamentUrl')" type="url"></shareable-text>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text @click="close">{{ $t('common.close')}}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import QrCode from '@/components/QrCode';
import ShareableText from '@core/components/ShareableText';

const CLOSE_EVENT = 'close';

export default {
  name: 'join-tournament',
  props: {
    tournamentUrl: {
      type: String,
      required: true
    }
  },
  methods: {
    close() {
      this.$emit(CLOSE_EVENT);
    }
  },
  components: {
    QrCode,
    ShareableText
  }
}
</script>
