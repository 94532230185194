<template>
  <v-app-bar app dark color="primary">
    <drawer-toggler />
    
    <v-toolbar-title>
      {{ title }}
    </v-toolbar-title>

    <v-spacer />

    <v-btn outlined small @click="isCreatingTournament = true">
      <v-icon>add</v-icon>
      {{ $t("common.create") }}
    </v-btn>

    <template v-slot:extension>
        <v-tabs grow background-color="primary" @change="switchTab" :value="value">
          <v-tabs-slider color="secondary"></v-tabs-slider>
          <v-tab v-for="tab in tabs" :key="tab.state">{{ $t(`tournament.tabs.${tab.text}`) }}</v-tab>
        </v-tabs>
    </template>

    <v-dialog v-model="isCreatingTournament">
        <tournament-create @create="createTournament" @close="isCreatingTournament = false"></tournament-create>
      </v-dialog>
  </v-app-bar>
</template>

<script>
import DrawerToggler from '@/components/DrawerToggler';
import { mapState } from 'vuex';
import { TournamentState } from '@/logic/tournaments/tournamentState';
import { createTournament } from '@/services/vuezlerApi/tournamentService';
import TournamentCreate from '@tournament/components/TournamentCreate';

export default {
  name: 'tournament-list-toolbar',
  components: {
    DrawerToggler,
    TournamentCreate
  },
  data: () => ({
    isCreatingTournament: false,
    tabs: [
      {
        state: TournamentState.Active,
        text: 'active'
      },
      {
        state: TournamentState.Inactive,
        text: 'inactive'
      },
      {
        state: TournamentState.Finished,
        text: 'finished'
      },
    ]
  }),
  computed: {
    ...mapState({
      title: state => state.shell.title,
    })
  },
  props: {
    value: Number
  },
  methods: {
    switchTab: function(tabIndex) {
      this.$emit('input', tabIndex);
    },
    createTournament: function(tournamentData) {
      createTournament(tournamentData)
        .then(({data}) => {
          this.navigateToTournamentPage(data.id);
          this.initializeTournaments(true);
        })
        .catch(() => {
          const errorMessage = this.$t('tournament.error.tournamentCreation');
          this.showError(errorMessage);
        });
      this.isCreatingTournament = false;
    },
    navigateToTournamentPage: function(id) {
      this.$router.push({ name: 'tournament', params: { id }});
    }
  },
}
</script>
