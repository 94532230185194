import axios from 'axios';

export function getTournaments() {
  return axios.get(`${window.$config.vuzzlerApi}/api/tournament`);
}

export function getTournament(id) {
  return axios.get(`${window.$config.vuzzlerApi}/api/tournament/${id}`);
}

export function createTournament(tournamentData) {
  return axios.post(`${window.$config.vuzzlerApi}/api/tournament`, tournamentData);
}

export function removeTournament(id) {
  return axios.delete(`${window.$config.vuzzlerApi}/api/tournament/${id}`);
}

export function startTournament(id) {
  return axios.put(`${window.$config.vuzzlerApi}/api/tournament/${id}/start`);
}

export function updateTournament(id, tournamentInfo) {
  return axios.put(`${window.$config.vuzzlerApi}/api/tournament/${id}`, tournamentInfo);
}

export function getTournamentTeams(tournamentId) {
  return axios.get(`${window.$config.vuzzlerApi}/api/tournament/${tournamentId}/teams`);
}

export function updateTeam(tournamentId, teamId, team) {
  return axios.patch(`${window.$config.vuzzlerApi}/api/tournament/${tournamentId}/teams/${teamId}`, team);
}

export function getMyTeam(tournamentId) {
  return axios.get(`${window.$config.vuzzlerApi}/api/tournament/${tournamentId}/teams/my`);
}

export function getTeam(tournamentId, teamId) {
  return axios.get(`${window.$config.vuzzlerApi}/api/tournament/${tournamentId}/teams/${teamId}`);
}

export function forfeit(tournamentId) {
  return axios.post(`${window.$config.vuzzlerApi}/api/tournament/${tournamentId}/forfeit`);
}

export function createTeam(tournamentId, teamInfo) {
  return axios.post(`${window.$config.vuzzlerApi}/api/tournament/${tournamentId}/teams/`, teamInfo);
}

export function setRegistrationState(tournamentId, isRegistrationOpen) {
  return axios.patch(`${window.$config.vuzzlerApi}/api/tournament/${tournamentId}?registrationState=${isRegistrationOpen}`);
}

export function joinTeam(tournamentId, teamId) {
  return axios.put(`${window.$config.vuzzlerApi}/api/tournament/${tournamentId}/teams/${teamId}`);
}

export function leaveTeam(tournamentId, teamId) {
  return axios.delete(`${window.$config.vuzzlerApi}/api/tournament/${tournamentId}/teams/${teamId}`);  
}

export function getNextMatch(tournamentId) {
  let endpoint = `${window.$config.vuzzlerApi}/api/tournament/${tournamentId}/next`;
  return axios.get(endpoint);
}

export function getGamePlan(tournamentId) {
  return axios.get(`${window.$config.vuzzlerApi}/api/tournament/${tournamentId}/gamePlan`);
}

export function kickTeam(tournamentId, teamId) {
  return axios.post(`${window.$config.vuzzlerApi}/api/tournament/${tournamentId}/teams/${teamId}/eliminate`)
}

export function getMatchForMatchUp(matchUpId) {
  return axios.get(`${window.$config.vuzzlerApi}/api/tournamentGateway/${matchUpId}`);
}

export function getWinner(tournamentId) {
  return axios.get(`${window.$config.vuzzlerApi}/api/tournament/${tournamentId}/winner`);
}

export function updateGamePlan(tournamentId, gamePlanSpecification) {
  return axios.put(`${window.$config.vuzzlerApi}/api/tournament/${tournamentId}/mode`, gamePlanSpecification);
}

export function getGamePlanSpec(tournamentId) {
  return axios.get(`${window.$config.vuzzlerApi}/api/tournament/${tournamentId}/mode`);
}

export function getIssues(tournamentId) {
  return axios.get(`${window.$config.vuzzlerApi}/api/tournament/${tournamentId}/issues`);
}

export function resolveIssue(tournamentId, groupId, ranking) {
  return axios.put(`${window.$config.vuzzlerApi}/api/tournament/${tournamentId}/issues/ranking/${groupId}`, ranking)
}