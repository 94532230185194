import { authenticationGuard } from '../../routes/guards/authenticationGuard';
import { withToolbar } from '../../routes/routeHelpers';
import Settings from './pages/Settings';
import AvatarSelection from './pages/AvatarSelection';
import StackedPageToolbar from '../../toolbar/StackedPageToolbar';
import About from './pages/About';

export default [
  { name: 'settings', path: '/settings', components: withToolbar(Settings, StackedPageToolbar), beforeEnter: authenticationGuard },
  { name: 'avatarSelection', path: '/settings/avatar', components: withToolbar(AvatarSelection, StackedPageToolbar), beforeEnter: authenticationGuard },
  { name: 'about', path: '/about', component: About }
]