<template>
<v-container>
  <v-row justify="start">
    <v-col>
      <tournament-headline :name="tournament.name" :state="inactiveState" :tournamentId="tournament.id" shareable></tournament-headline>
      <tournament-description v-if="tournament.description" :value="tournament.description" />

      <v-btn v-if="isAdministrator" :disabled="!canStartTournament"
        class="mt-4" color="primary" block
        @click="startTournament"
        >
        Start Tournament
      </v-btn>
      <label v-if="isAdministrator && registeredTeamsErrorMessage" class="caption error--text">{{$t(registeredTeamsErrorMessage)}}</label>
    </v-col>
  </v-row>
  <v-row v-if="!!tournament.rules">
    <v-col class="py-0">
      <tournament-rules :rules="tournament.rules" />
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-divider />
      <v-list>
        <v-list-item :to="{ name: 'tournamentTeams' }">
          <div class="block-navigation">
            {{ $t('tournament.nrOfRegisteredTeams') }}: {{ teams.length }} / {{ tournament.nrOfRequiredTeams }}
            <v-icon right>keyboard_arrow_right</v-icon>
          </div>
        </v-list-item>
      </v-list>
      <v-divider />
    </v-col>
  </v-row>

  <v-row v-if="!!myTeam">
    <v-col>
      <registered-team :team="myTeam" :tournamentId="tournament.id"
        :onUpdateTeam="updateTeam" />
    </v-col>
  </v-row>

  <action-footer height="68px">
    <v-btn v-if="myTeam" color="error" fixed bottom rounded class="participation-fab"
      @click="leaveTeam"
    >
      {{$t('tournament.cancelRegistration') }}
    </v-btn>

    <v-btn v-else color="primary" fixed bottom rounded class="participation-fab"
      @click="isParticipationDialogOpen=true" :disabled="!tournament.isRegistrationOpen">
      {{ tournament.isRegistrationOpen ? $t('tournament.register') : $t('tournament.registrationClosed') }}
    </v-btn>
  </action-footer>
  
  <v-dialog v-model="isParticipationDialogOpen">
    <participation-dialog :tournamentId="tournament.id"
      @close="isParticipationDialogOpen=false"></participation-dialog>
  </v-dialog>

  <v-dialog v-model="isJoinTeamDialogVisible">
    <join-team :tournamentId="tournament.id" :teamId="joinTeamId" @joined="joinedTeam" @close="closeJoinTeamDialog" />
  </v-dialog>
</v-container>
</template>

<script>
import TournamentHeadline from '@tournament/components/TournamentHeadline';
import TournamentRules from '@tournament/components/TournamentRules';
import TournamentDescription from '@tournament/components/TournamentDescription';
import ParticipationDialog from '@tournament/components/ParticipationDialog';
import RegisteredTeam from '@tournament/components/RegisteredTeam';
import ActionFooter from '@core/components/ActionFooter';
import JoinTeam from '@tournament/components/JoinTeam';
import { startTournament, removeTournament, leaveTeam, updateTeam } from "@/services/vuezlerApi/tournamentService";
import { mapActions, mapGetters, mapState } from 'vuex';
import { ACTIONS as ShellActions } from '@/vuex/shell.module';
import { ACTIONS as TournamentListActions } from '@tournament/states/tournament-list';
import { Actions as TournamentActions, Getters as TournamentGetters } from '@tournament/states/tournament';
import { GETTERS as AuthGetters } from '@/vuex/auth.module';
import { TournamentState } from '@/logic/tournaments/tournamentState';
import { shellInteractorMixin } from '../../core/mixins/shellInteractorMixin';

export default {
  name: 'inactive-tournament',
  data: () => ({
    participants: 0,
    connection: undefined,
    isStartingTournament: false,
    isDeletingTournament: false,
    inactiveState: TournamentState.Inactive,
    isParticipationDialogOpen: false,
    isJoinTeamDialogVisible: false
  }),
  mixins: [ shellInteractorMixin ],
  props: {
    tournament: Object,
    joinTeamId: String
  },
  computed: {
    isAdministrator: function() {
      return this.tournament.administratorId === this.userId;
    },
    canStartTournament: function() {
      return this.teams.length === this.tournament.nrOfRequiredTeams;
    },
    ...mapGetters({
      userId: AuthGetters.USER_ID,
      token: AuthGetters.TOKEN,
      myTeam: TournamentGetters.MyTeam
    }),
    ...mapState({
      teams: state => state.tournament.teams,
    }),
    registeredTeamsErrorMessage() {
      return this.teams.length < this.tournament.nrOfRequiredTeams
        ? 'tournament.error.tooFewTeams'
        : this.teams.length > this.tournament.nrOfRequiredTeams
        ? 'tournament.error.tooManyTeams'
        : null
    }
  },
  methods: {
    startTournament: function() {
      this.isStartingTournament = true;
      startTournament(this.tournament.id).then(({ data }) => {
        this.isStartingTournament = false;
        this.$emit('tournamentChanged', data);
      }).catch(e => {
        this.isStartingTournament = false;
        this.showError(e);
      });
    },
    deleteTournament: function() {
      this.isDeletingTournament = true;
      removeTournament(this.tournament.id).then(() => {
        this.isDeletingTournament = false;
        this.refreshTournaments(true);
        this.$router.back();
      }).catch(() => {
        this.isDeletingTournament = false;
      })
    },
    updateTeam(team) {
      if (this.myTeam == null) return null;
      return updateTeam(this.tournament.id, this.myTeam.info.id, team);
    },
    updateParticipantCount: function(participants) {
      this.participants = participants;
    },
    ...mapActions({
      showError: ShellActions.SHOW_ERROR,
      refreshTournaments: TournamentListActions.INITIALIZE_TOURNAMENTS,
      fetchTeams: TournamentActions.FetchTeams,
    }),
    joinedTeam() {
      this.removeParam('join');
      this.isJoinTeamDialogVisible = false;
      this.fetchTeams(this.tournament.id);
    },
    leaveTeam() {
      leaveTeam(this.tournament.id, this.myTeam.info.id).then(() => {
        this.fetchTeams(this.tournament.id);
      });
    },
    removeParam(paramName) {
      this.$router.replace({
        ...this.$router.currentRoute,
        query: {
          [paramName]: undefined
        }
      });
    },
    closeJoinTeamDialog() {
      this.removeParam('join');
      this.isJoinTeamDialogVisible = false;
    }
  },
  mounted(){
    this.fetchTeams(this.tournament.id).catch(() => {
      this.showError('tournament.error.fetchTeams');
    }).then(() => {
      if (this.myTeam && this.myTeam.info.id === this.joinTeamId) {
        this.removeParam('join');
      } else if (this.joinTeamId) {
        this.isJoinTeamDialogVisible = true
      }
    })

  },
  components: {
    TournamentHeadline,
    TournamentRules,
    ParticipationDialog,
    RegisteredTeam,
    ActionFooter,
    JoinTeam,
    TournamentDescription
  },
}
</script>

<style scoped>
.participation-fab {
  left: 50%;
  transform: translate(-50%);
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -ms-transform: translate(-50%);
    -o-transform: translate(-50%);
}

.block-navigation {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
