import { Response } from "@/services/vuezlerApi/response";
import axios, { AxiosResponse } from "axios";
import { Statistic } from "../models/statistic";

/**
 * Get statistical data about all the matches played by the specified players.
 * 
 * @param home Ids of all the players on the home side
 * @param away Ids of all the players on the away side
 */
export function getStatistic(teamOne: (string | null)[], teamTwo: (string | null)[]): Promise<AxiosResponse<Statistic>> {
  return axios.get<Statistic>(`${window.$config.vuzzlerApi}/api/statistics`, {
    params: { teamOne, teamTwo}
  })
}
