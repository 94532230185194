<template>
  <v-card v-if="showDialog" color="background lighten-1" style="margin-bottom: 16px">
    <v-card-title>
      <v-icon style="margin-right: 16px">person_add</v-icon>
      <span class="title font-weight-light">{{ $t("group.addPlayer") }}</span>
    </v-card-title>
    <v-container>
      <v-autocomplete
        v-model="model"
        :search-input.sync="search"
        :loading="isSearching"
        :items="entries"
        :filter="() => true"
        return-object>
        <template v-slot:selection="{ item }">
          {{item.name}}
        </template>
        <template v-slot:item="{parent, item}">
          <template v-if="typeof item !== 'object'">
            <v-list-item-content v-text="item"></v-list-item-content>
          </template>
          <template v-else>
            <v-list-item-avatar>
              <avatar :src="item.profileImage || defaultImage" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="`${parent.genFilteredText(item.name)}`"></v-list-item-title>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="closeDialog">{{ $t("common.cancel") }}</v-btn>
        <v-btn color="primary" :disabled="!model" @click="addPlayer" :loading="isBusyAdding">{{ $t("common.add") }}</v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ACTIONS } from '../vuex/group.module';
import { ACTIONS as ShellActions } from '../vuex/shell.module';
import { addPlayerToGroup } from '../services/vuezlerApi/group.service';
import * as _ from 'lodash';
import { queryPlayer } from '../services/vuezlerApi/player.service';
import Avatar from '../modules/core/components/Avatar';

export default {
  name: 'group-player-search',
  data: () => ({
    model: null,
    entries: [],
    search: null,
    isSearching: false,
    isBusyAdding: false,
    defaultImage: require('@/assets/images/default-avatar.png'),
  }),
  props: ['value'],
  watch: {
    search: function(searchTerm) {
      if (searchTerm == null) return;
      
      this.isSearching = true;
      this.debouncedUserSearch(searchTerm);
    }
  },
  computed: {
    ...mapState({
      showDialog: state => state.group.isAddPlayerDialogOpen,
      groupId: state => state.group.currentGroup.id,
    })
  },
  methods: {
    ...mapActions({
      closeDialog: ACTIONS.CLOSE_ADDPLAYER_DIALOG,
      addPlayerToGroup: ACTIONS.ADD_PLAYER_TO_GROUP,
      showError: ShellActions.SHOW_ERROR
    }),
    addPlayer: function() {
      this.isBusyAdding = true;
      addPlayerToGroup(this.groupId, this.model.id).then(({data}) => {
        this.isBusyAdding = false;
        this.addPlayerToGroup(data);
        this.closeDialog();
      }).catch(({response}) => {
        this.isBusyAdding = false;
        this.showError(response.data);
      });
    },
    debouncedUserSearch: _.debounce(function(searchTerm) {
      this.searchUser(searchTerm)
    }, 500),
    searchUser: function(searchTerm) {
      if (!searchTerm || searchTerm.length <= 0) {
        this.entries = [];
        this.isSearching = false;
        return;
      }

      queryPlayer(searchTerm).then(({data}) => {
        this.entries = data;
      }).catch(() => {
        this.showError("Sorry, but we couldn't execute the search. Please try again later.")
      }).finally(() => {
        this.isSearching = false;
      });
    }
  },
  components: {
    Avatar
  }
}
</script>

