import { render, staticRenderFns } from "./SoundBoard.vue?vue&type=template&id=686291da&scoped=true&"
import script from "./SoundBoard.vue?vue&type=script&lang=js&"
export * from "./SoundBoard.vue?vue&type=script&lang=js&"
import style0 from "./SoundBoard.vue?vue&type=style&index=0&id=686291da&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "686291da",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VFlex,VLayout})
