<template>
  <v-app-bar app dark class="primary">
    <drawer-toggler />

    <v-toolbar-title>
      {{ $t('shell.appTitle') }}
    </v-toolbar-title>

    <v-spacer />

    <v-menu>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" text>
          <v-icon>more_vert</v-icon>
        </v-btn>
      </template>
      
      
      <v-list v-if="currentGroup != null">
        <v-list-item @click.stop="isInformationDialogVisible=true">
          <v-list-item-action>
            <v-icon>info</v-icon>
          </v-list-item-action>
          <v-list-item-title>{{ $t("group.information") }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="openAddPlayerDialog" v-if="isAdministrator">
          <v-list-item-action>
            <v-icon>person_add</v-icon>
          </v-list-item-action>
          <v-list-item-title>{{ $t("group.addPlayer" )}}</v-list-item-title>
        </v-list-item>
        <v-list-item @click.stop="openDeleteDialog=true" v-if="isAdministrator">
          <v-list-item-action>
            <v-icon>delete</v-icon>
          </v-list-item-action>
          <v-list-item-title>{{ $t("common.delete") }}</v-list-item-title>
        </v-list-item>
        <v-dialog v-model="openDeleteDialog">
          <v-card>
            <v-card-title class="headline">{{ $t("group.deleteGroup") }}</v-card-title>
            <v-card-text>{{ $t("group.deleteGroupInfo") }}</v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn text @click="openDeleteDialog=false" :disabled="isDeleting">{{ $t("common.cancel") }}</v-btn>
              <v-btn color="error" error @click="removeGroup" :loading="isDeleting">{{ $t("common.delete") }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-list>

      <group-information-dialog v-model="isInformationDialogVisible"/>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { ACTIONS } from '../vuex/group.module';
import { mapActions, mapState, mapGetters } from 'vuex';
import { deleteGroup } from '../services/vuezlerApi/group.service';
import { ACTIONS as ShellActions } from '../vuex/shell.module';
import { GETTERS } from '../vuex/group.module.js';
import GroupInformationDialog from '../components/dialogs/GroupInformationDialog';
import DrawerToggler from '../components/DrawerToggler';

export default {
  name: 'group-toolbar',
  components: {
    GroupInformationDialog,
    DrawerToggler
  },
  data: () => ({
    openDeleteDialog: false,
    isDeleting: false,
    isInformationDialogVisible: false,
  }),
  computed: {
    ...mapState({
      currentGroup: state => state.group.currentGroup,
      groupId: state => state.group.currentGroup.id,
    }),
    ...mapGetters({
      isAdministrator: GETTERS.IsAdministrator
    }),
  },
  methods: {
    ...mapActions({
      openAddPlayerDialog: ACTIONS.OPEN_ADDPLAYER_DIALOG,
      removeMembership: ACTIONS.REMOVE_GROUPMEMBERSHIP,
      showError: ShellActions.SHOW_ERROR
    }),
    removeGroup: function() {
      this.isDeleting = true;

      deleteGroup(this.groupId).then(() => {
        this.isDeleting = false;
        this.removeMembership(this.groupId);
        this.openDeleteDialog = false;
        this.$router.replace('/');
      }).catch(() => {
        this.isDeleting = false;
        this.openDeleteDialog = false;
        this.showError('Could not delete group');
      })
    }
  }
}
</script>
