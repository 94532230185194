import axios from "axios";

export function getGroup(groupId) {
  return axios
    .get(`${window.$config.vuzzlerApi}/api/group/${groupId}`);
}

export function createGroup(group) {
  return axios
    .post(`${window.$config.vuzzlerApi}/api/group/`, group);
}

export function deleteGroup(groupId) {
  return axios
    .delete(`${window.$config.vuzzlerApi}/api/group/${groupId}`);
}

export function getGroupMembers(groupId) {
  return axios
    .get(`${window.$config.vuzzlerApi}/api/group/${groupId}/members`)
}

export function addPlayerToGroup(groupId, playerId) {
  return axios
    .put(`${window.$config.vuzzlerApi}/api/group/${groupId}/player`, { playerId });
}