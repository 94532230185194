const homeProperty = "home";
const awayProperty = "away";

export function getSide(position) {
  switch (position) {
    case 0:
      return { side: homeProperty, player: 'playerOne' };
    case 1:
      return { side: homeProperty, player: 'playerTwo' };
    case 2:
      return { side: awayProperty, player: 'playerOne' };
    case 3:
      return { side: awayProperty, player: 'playerTwo' };
    default:
      return { side: null, player: null };
  }
}

export function extractPlayers(match) {
  const players = { home: [], away: [] };
  if (match.home.playerOne) {
    players.home.push(match.home.playerOne);
  }
  if (match.home.playerTwo) {
    players.home.push(match.home.playerTwo);
  }
  if (match.away.playerOne) {
    players.away.push(match.away.playerOne);
  }
  if (match.away.playerTwo) {
    players.away.push(match.away.playerTwo);
  }
  return players;
}

export function getSideById(players, playerId) {
  if (players.home.find(player => player.id === playerId)) {
    return homeProperty;
  } else if (players.away.find(player => player.id === playerId)) {
    return awayProperty;
  } else {
    return undefined;
  }
}

export function opposingSide(side) {
  switch(side) {
    case homeProperty: return awayProperty;
    case awayProperty: return homeProperty;
    default: return undefined;
  }
}

export function createMatchRequest(id, players, score) {
  return {
    home: {
      defensePlayerId: players[0],
      offensePlayerId: players[1],
      score: score.home
    },
    away: {
      defensePlayerId: players[2],
      offensePlayerId: players[3],
      score: score.away
    },
    id
  }
}

export function getPlayerFromMatch(match, playerId) {
  if (!match || !playerId) return undefined;
  
  const players = getPlayersFromMatch(match);
  return players.find(player => player && player.id === playerId);
}

export function getPlayersFromMatch(match) {
  if (!match) return [];
  else {
    const players = [];

    players[0] = getPlayer(match.home.playerOne);
    players[1] = getPlayer(match.home.playerTwo);
    players[2] = getPlayer(match.away.playerOne);
    players[3] = getPlayer(match.away.playerTwo);

    return players;
  }
}

export function hasPlayerWon(match, playerId) {
  var { playerSide, opponentSide } = getRelativeSides(match, playerId);
  if (playerSide === undefined || opponentSide === undefined) {
    return false;
  }

  return playerSide.score > opponentSide.score
}

function getPlayer(player) {
  return player && {
    id: player.id,
    profileImage: player.profileImage,
    eloRanking: {
      current: player.currentElo,
      previous: player.previousElo
    },
  }
}

function getRelativeSides(match, playerId) {
  const relativeSides = {
    playerSide: undefined,
    opponentSide: undefined
  };

  if (isPlayingOnSide(match.home, playerId)) {
    relativeSides.playerSide = match.home;
    relativeSides.opponentSide = match.away;
  } else if (isPlayingOnSide(match.away, playerId)) {
    relativeSides.playerSide = match.away;
    relativeSides.opponentSide = match.home;
  }
  
  return relativeSides;
}

const isPlayingOnSide = (side, playerId) =>
  (side.playerOne && side.playerOne.id === playerId) ||
  (side.playerTwo && side.playerTwo.id === playerId);