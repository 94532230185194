<template>
  <v-list-group
    v-model="active"
    prepend-icon="group"
    no-action>
    <template slot="activator">
      <v-list-item aria-label="expand groups">
        <v-list-item-content>
          <v-list-item-title>{{ $t("shell.drawer.groups") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-list-item
      v-for="membership in groupMemberships"
      :key="membership.id"
      :aria-label="'group named ' + membership.name"
      :to="{ name: 'group', params: { id: membership.id } }" >
      <v-list-item-content>
        <v-list-item-title>{{ membership.name }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item
      @click="isCreationDialogOpen = true"
    >
      <v-list-item-action>
        <v-icon>add</v-icon>
      </v-list-item-action>
        <v-list-item-title>
          Add
        </v-list-item-title>
    </v-list-item>
    <group-creation-dialog v-model="isCreationDialogOpen">
    </group-creation-dialog>
  </v-list-group>
</template>

<script>
import {ACTIONS as GroupActions} from '../vuex/group.module';
import GroupCreationDialog from './dialogs/GroupCreationDialog';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'groups-drawer',
  data: () => ({
    active: false,
    isCreationDialogOpen: false
  }),
  components: {
    GroupCreationDialog
  },
  computed: {
    ...mapState({
      groupMemberships: state => state.group.groupMemberships
    })
  },
  methods: {
    ...mapActions({
      initializeGroups: GroupActions.FETCH_MEMBERSHIPS,
    }),
  },
  mounted() {
    this.initializeGroups();
  }
}
</script>