<template>
  <div style="height: 100%">
  <stacked-page-toolbar />
  <v-container fill-height>
      <v-layout column>
        <v-flex grow class="loading-icon">
          <loading icon="photo_camera" :text="$t('qr.openingCamera')"
            :fallbackText="$t('qr.cantOpenCamera')"></loading>
        </v-flex>
        <v-flex shrink v-if="!isCordova">
          <qrcode-stream @decode="navigateToPage" class="qr"></qrcode-stream>
        </v-flex>
      </v-layout>
    <error-dialog :active="isErrorDialogActive" :title="$t('qr.incorrectCode')" :description="$t('qr.incorrectCodeInfo')" @ok="closeErrorDialog"></error-dialog>
    <error-dialog :active="isQrMisconfigured" :title="$t('qr.notAvailable')" :description="$t('qr.qrActivationDescription')" @ok="returnToPreviousPage"></error-dialog>
  </v-container>
  </div>
</template>

<script>
import { parseDeepLink } from '../modules/core/routing/deepLinkRouter';
import { QrcodeStream } from 'vue-qrcode-reader';
import ErrorDialog from '../components/dialogs/ErrorDialog';
import { isRunningInCordova, isRunningInIOS } from '../modules/core/cordova/cordovaApi';
import Loading from '../components/core/loading/Loading';
import StackedPageToolbar from '@/toolbar/StackedPageToolbar';

export default {
  name: "qrScanner",
  data: () => ({
    isErrorDialogActive: false,
    isQrMisconfigured: false
  }),
  computed: {
    pageTitle: function() {
      return this.$t('pageTitles.default');
    },
    isCordova: function() {
      return isRunningInCordova();
    }
  },
  components: {
    QrcodeStream,
    ErrorDialog,
    Loading,
    StackedPageToolbar
  },
  methods: {
    navigateToPage: function(url) {
      const routerLink = parseDeepLink(url);
      if (routerLink !== null) {
        this.$router.replace(routerLink);
      } else {
        this.isErrorDialogActive = true;
      }
    },
    closeErrorDialog: function() {
      this.isErrorDialogActive = false;
      this.openScanner();
    },
    returnToPreviousPage: function() {
      this.isQrMisconfigured = true;
      this.$router.back();
    },
    openScanner: function() {
      if (this.isCordova) {
        this.openCordovaScanner();
      } else {
        // web scanner is opened in the template
      }
    },
    openCordovaScanner: function() {
      window.cordova.plugins.barcodeScanner.scan(
        result => {
          if (result.cancelled && isRunningInIOS()) {
            this.$router.back();
          }

          if (!result.cancelled) {
            this.navigateToPage(result.text);
          }
        },
        () => {
          this.isQrMisconfigured = true;
        },
        {
            preferFrontCamera : false,
            showFlipCameraButton : true,
            showTorchButton : true,
            torchOn: false,
            resultDisplayDuration: 500,
            formats : "QR_CODE",
            orientation : "portrait",
            disableAnimations : true,
            disableSuccessBeep: true
        }
      );
    }
  },
  mounted: function() {
    this.openScanner();
  }
}
</script>

<style scoped>
.qr >>> video {
  object-fit: cover;
  height: 100%;

  position: fixed;
  bottom: 0px;
  top: 0px;
  left: 0px;
  right: 0px;
}

.loading-icon {
  flex-direction: column;
  display: flex;
}
</style>
