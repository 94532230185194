













import Vue, { PropType } from 'vue'
import PlayerSelection from '@statistic/components/PlayerSelection/PlayerSelection.vue'
import { Constellation } from '../../models/constellation'

const ConstellationSelection = Vue.extend({
  name: 'constellationSelection',
  computed: {
    selectedPlayers: function(): string[] {
      return [...this.value.home, ...this.value.away].filter(isDefinedPlayer)
    }
  },
  props: {
    value: Object as PropType<Constellation>
  },
  methods: {
    constellationChanged: function(playerId: string | null, playerIndex) {
      const result = {
        home: [ ...this.value.home],
        away: [ ...this.value.away]
      }
      switch(playerIndex) {
        case 0: result.home[0] = playerId; break;
        case 1: result.home[1] = playerId; break;
        case 2: result.away[0] = playerId; break;
        case 3: result.away[1] = playerId; break;
        default: throw new Error('Unknown index in the constellation')
      }
      this.$emit('input', result);
    }
  },
  components: {
    PlayerSelection
  },
})

function isDefinedPlayer(playerId: string | 'any' | null): playerId is string {
  return playerId !== 'any' && playerId !== null;
}

export default ConstellationSelection
