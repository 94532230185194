<template>
  <div>
    <page-header>
      <template v-slot:image>
        <img src="~@/assets/images/stock/privacy.jpg">
      </template>
      <nav-bar></nav-bar>
      <h1 class="text-center display-1 mt-4 mb-4">{{ $t("landingPage.pageTitles.policy") }}</h1>
    </page-header>
    <div class="content">
      <component :blok="story.content" :is="story.content.component"></component>
    </div>
  </div>
</template>

<script>
import { storyblokMixin } from "@crm/mixins/storyblokMixin";

export default {
  name: 'privacy-policy',
  mixins: [ storyblokMixin ],
  created() {
    this.initialize('privacy')
  }
}
</script>

<style scoped>
.content {
  margin: 0 auto;
  width: 80%;
}
</style>