<template>
  <v-container fill-height class="disconnected">
    <v-layout justify-center align-center wrap>
      <v-flex shrink class="box">
        <bouncing-dots color="black"></bouncing-dots>
        <div class="loading-description">Connecting</div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'connection-overlay',
}
</script>

<style scoped>
.disconnected {
  position: absolute;

  left: 0px;
  top: 0px;

  width: 100%;
  height: 100%;
}

.box {
  padding: 15px;
  background-color: var(--v-background-darken2);
  border-radius: 8px;
}

.loading-description {
  margin-top: 8px;
}
</style>