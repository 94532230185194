<template>
  <v-text-field
    :value="text"
    ref="textInput"
    @click="selectInput"
    @click:append="share()"
    readonly
    :label="label"
    :append-icon="isShareable ? 'share' : 'file_copy'"
  />
</template>

<script>
import { isRunningInCordova } from '../cordova/cordovaApi';
export default {
  name: 'shareable-text',
  props: {
    text: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'url',
    },
    label: {
      type: String
    }
  },
  computed: {
    isShareable: function() {
      return window.navigator.share || isRunningInCordova();
    }
  },
  methods: {
    share() {
      if (this.isShareable) {
        if (isRunningInCordova()) {
          const options = {
            chooserTitle: this.label,
            url: this.text
          }
          window.plugins.socialsharing.shareWithOptions(options)
        } else {
          window.navigator.share({
            [this.type]: this.text
          })
        }
      } else {
        const inputElement = this.$refs.textInput.$el.querySelector('input');
        inputElement.select();
        document.execCommand("copy");
        inputElement.selectionEnd = inputElement.selectionStart;
      }
    },
    selectInput(event) {
      event.target.select();
    }
  }
}
</script>