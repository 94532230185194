<template>
  <v-layout shrink ma-0>
    <v-flex xs-6>
      <v-btn outlined color="primary" block @click="playSound('horn')" light :aria-label="$t('sounds.horn')">{{ $t("sounds.horn") }}</v-btn>
    </v-flex>
    <v-flex xs-6>
      <v-btn outlined color="primary" block @click="playSound('clap')" light :aria-label="$t('sounds.clap')">{{ $t("sounds.clap") }}</v-btn>
    </v-flex>

    <audio class="soundPlayer" ref="crowdPlayer" preload="auto" src="../assets/sounds/crowd.mp3" type="audio/mpeg" />    
  </v-layout>
</template>

<script>
const AIRHORN_SOUND = require('../assets/sounds/airhorn.mp3');
const APPLAUSE_SOUND = require('../assets/sounds/applause.mp3');
const CROWD_SOUND = require('../assets/sounds/crowd.mp3');

export default {
  name: "soundBoard",
  data: () => ({
    soundsEnabled: false,
    muted: false,
  }),
  props: {
    onSoundDistribution: Function
  },
  methods: {
    enableSounds: function() {
      if (!this.soundsEnabled) {
        const player = this.$refs.crowdPlayer;
        player.muted = true
        player.play()
        this.soundsEnabled = true
      }
    },
    requestSound: function(sound) {
      if (!this.soundsEnabled) this.enableSounds()
      this.onSoundDistribution(sound)
    },
    playSound: function(sound) {
      const audioFile = this.getAudioFile(sound);
      const player = new Audio(audioFile);
      player.play();
    },
    playCheering: function() {
      const player = this.$refs.crowdPlayer;
      player.currentTime = 0;
      player.muted = false;
      player.play();
    },
    getAudioFile: function(file) {
      switch (file) {
        case 'horn':
          return AIRHORN_SOUND;
        case 'clap':
          return APPLAUSE_SOUND;
        case 'crowd':
          return CROWD_SOUND;
        default:
          return AIRHORN_SOUND;
      }
    },
  }
}
</script>

<style scoped>
  .soundPlayer {
    visibility: hidden;
  }
</style>
