<template>
  <v-banner color="warning" two-line class="issue-banner">
    <template v-slot:icon>
      <v-icon size="32" icon="warning">warning</v-icon>
    </template>

    {{ $t('tournament.issues.issuesPresent') }}

    <template v-slot:actions>
      <v-btn text @click="goToIssues">{{ $t('tournament.issues.viewIssues') }}</v-btn>
    </template>
  </v-banner>
</template>

<script>
const VIEW_ISSUES_EVENT = 'viewIssues';

export default {
  name: 'issue-banner',
  methods: {
    goToIssues: function() {
      this.$emit(VIEW_ISSUES_EVENT)
    }
  }
}
</script>

<style scoped>
</style>