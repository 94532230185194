









































import StackedPageToolbar from '@/toolbar/StackedPageToolbar.vue'
import ConstellationSelection from '@statistic/components/ConstellationSelection/ConstellationSelection.vue';
import { getStatistic } from '@statistic/api-services/statistics-api';
import ErrorState from '@shared/states/error-state/ErrorState.vue'
import { Statistic } from '@statistic/models/statistic';
import Vue, { PropType } from 'vue';
import ConstellationStatistics from '@statistic/components/ConstellationStatistics/ConstellationStatistics.vue';
import { toConstellation, toParams } from './params-constellation-mapper';
import { GETTERS as AuthGetters } from '@/vuex/auth.module';
import { mapGetters } from 'vuex';
import { debounce } from 'lodash';
import { addFavourite, isFaved, removeFavourite } from '../../api-services/statistic-favorites-api';
import { Constellation } from '../../models/constellation';

type Side = (string | null)[]

const DEBOUNCE_DELAY = 500;

export default Vue.extend({
  name: 'statistic',
  data() {
    return {
      statistics: null as Statistic | null,
      errorDuringStatisticFetch: false,
      isFaved: false,
      isFetchingStatistics: false,
      committedFavState: false
    }
  },
  props: {
    one: String as PropType<string | undefined>,
    two: String as PropType<string | undefined>,
    three: String as PropType<string | undefined>,
    four: String as PropType<string | undefined>,
  },
  components: {
    StackedPageToolbar,
    ConstellationSelection,
    ErrorState,
    ConstellationStatistics
  },
  computed: {
    constellation: function(): { home: Side, away: Side } {
      const param = {
        one: this.one,
        two: this.two,
        three: this.three,
        four: this.four
      }
      return toConstellation(param)
    },
    haventPlayerAnyMatches: function(): boolean {
      return this.statistics != null &&
             this.statistics.matchesWon + this.statistics.matchesDrawn + this.statistics.matchesLost === 0
    },
    notParticipating: function(): boolean {
      return this.one !== this.userId &&
             this.two !== this.userId &&
             this.three !== this.userId &&
             this.four !== this.userId
    },
    ...mapGetters({
      userId: AuthGetters.USER_ID
    })
  },
  methods: {
    constellationChanged: function({ home, away }): void {
      this.$router.replace({
        name: 'statistic',
        replace: true,
        query: toParams({ home, away })
      })
    },
    updateStatistics: async function(): Promise<void> {
      this.statistics = null;
      this.errorDuringStatisticFetch = false;

      if (this.constellation.home.every(p => p == null) ||
          this.constellation.away.every(p => p == null) ||
          this.notParticipating) {
        return;
      }

      try {
        const apiResponse = await getStatistic(this.constellation.home, this.constellation.away);
        if (apiResponse.status === 200) {
          this.statistics = apiResponse.data;
        }
      } catch(error) {
        this.errorDuringStatisticFetch = true;
      }
    },
    toggleFav: function() {
      this.isFaved = !this.isFaved
      this.debouncedFavStatistic(this.constellation.home, this.constellation.away, this.isFaved, this.committedFavState, state => this.committedFavState = state)
    },
    debouncedFavStatistic: debounce(function(home: Side, away: Side, isFaved: boolean, isFavedCommitted: boolean, updateCommittedState) {
      if (isFaved == isFavedCommitted) {
        // when the faved state is the same as the comitted one, an update isn't necessary
        return;
      }

      var request;
      if (isFaved) {
        request = addFavourite(home, away)
      } else {
        request = removeFavourite(home, away)
      }
      request.then(() => {
        updateCommittedState(isFaved);
      })
    }, DEBOUNCE_DELAY)
  },
  watch: {
    'constellation': {
      handler: async function(constellation: Constellation): Promise<void> {
        this.isFetchingStatistics = true;
        await this.updateStatistics();
        isFaved(constellation.home, constellation.away).then(response => {
          this.isFetchingStatistics = false;
          this.isFaved = response.status == 200;
          this.committedFavState = this.isFaved;
        })
      },
      immediate: true
    }
  }
})
