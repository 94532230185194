<template>
  <section class="your-team">
    <header class="team-title mb-2">
      <span class="subtitle-2 grey--text text--darken-2">{{ $t('tournament.registeredHeadline') }}:</span>
      <h1 class="title team-name">{{ team.info.name }}</h1>
    </header>
    <section class="team-members">
      <h1 class="subtitle-1 font-weight-medium">{{ $t('tournament.teamMembers') }}:</h1>
      <ul>
        <li v-for="member in team.participants" :key="member.id">
          {{ member.name }}
        </li>
      </ul>
    </section>
    <section class="team-actions">
      <v-spacer />
      <v-btn outlined color="primary" class="mx-1" @click="isEditDialogOpen = true">{{ $t('tournament.editTeam') }}</v-btn>
    </section>

    <v-dialog v-model="isEditDialogOpen">
      <team-edit :team="team" :tournamentId="tournamentId"
        :onUpdateTeam="onUpdateTeam"
        @close="isEditDialogOpen = false"
      />
    </v-dialog>
  </section>
</template>

<script>
import TeamEdit from '@tournament/components/TeamEdit';

export default {
  name: 'registered-team',
  data: function() {
    return {
      isEditDialogOpen: false
    }
  },
  props: {
    team: {
      type: Object,
      required: true
    },
    tournamentId: {
      type: String,
      required: true
    },
    onUpdateTeam: {
      type: Function,
      required: true
    }
  },
  components: {
    TeamEdit,
  }
}
</script>

<style scoped>
.team-name {
  margin-top: -4px;
}

.team-actions {
  display: flex;
  flex-direction: row;
}
</style>