<template>
    <v-app-bar-nav-icon :dark="dark"
      aria-label="toggle menu"
      @click='toggleDrawer'>
    </v-app-bar-nav-icon>  
</template>

<script>
import { mapActions } from 'vuex';
import { ACTIONS } from '../vuex/shell.module';
export default {
  name: 'drawer-toggler',
  props: [ 'dark' ],
  methods: {
    ...mapActions({
      toggleDrawer: ACTIONS.TOGGLE_DRAWER
    })
  }
}
</script>
