/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'qr-code': {
    width: 401.994,
    height: 401.994,
    viewBox: '0 0 401.994 401.994',
    data: '<path pid="0" d="M0 401.991h182.724V219.265H0v182.726zm36.542-146.178h109.636v109.352H36.542V255.813z"/><path pid="1" d="M73.089 292.355h36.544v36.549H73.089zM292.352 365.449h36.553v36.545h-36.553zM365.442 365.449h36.552v36.545h-36.552z"/><path pid="2" d="M365.446 255.813h-36.542v-36.548H219.265v182.726h36.548V292.355h36.539v36.549h109.639V219.265h-36.545zM0 182.728h182.724V0H0v182.728zM36.542 36.542h109.636v109.636H36.542V36.542z"/><path pid="3" d="M73.089 73.089h36.544v36.547H73.089zM219.265 0v182.728h182.729V0H219.265zm146.181 146.178H255.813V36.542h109.633v109.636z"/><path pid="4" d="M292.352 73.089h36.553v36.547h-36.553z"/>'
  }
})
