<template>
  <v-app-bar fixed color="primary white--text" :class="{ 'transparent-app-bar': !hasScrolled }" elevate-on-scroll>
    <router-link class="app-logo" :to="{ name: 'home' }">
      <v-avatar class="mr-2" tile><v-img src="@/assets/logo.png"></v-img></v-avatar>
      <span class="white--text title">Vuzzler</span>
    </router-link>
    <div class="d-none d-sm-inline-block">
      <slot></slot>
    </div>

    <landing-page-menu v-if="!!$slots.default" class="ml-auto">
      <slot></slot>
    </landing-page-menu>
  </v-app-bar>
</template>

<script>
export default {
  data: function() {
    return {
      hasScrolled: false
    }
  },
  name: 'nav-bar',
  mounted: function() {
    window.addEventListener('scroll', () => {
      this.hasScrolled = window.scrollY > 0;
    })
  }
}
</script>

<style scoped>
.transparent {
  opacity: 0;
}

.app-logo {
  margin-right: 24px;
  text-decoration: none;
}

header.transparent-app-bar {
  background-color: transparent !important;
}
</style>