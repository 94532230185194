import { parseDeepLink } from "../routing/deepLinkRouter";

/**
 * Detect whether the application is running in a cordova environment.
 */
export function isRunningInCordova() {
    return window.cordova !== undefined;
}

/**
 * Add NFC deep linking capabilities to a cordova application.
 * @param {VueRouter} router - used for navigating to deep link targets
 */
export function addNfc(router) {
    if (!window.nfc) return;

    window.nfc.enabled(() => {
      nfcState = NFC_STATES.ENABLED;
    }, errorState => {
      nfcState = errorState;
    });

    if (window.device.platform === 'iOS') {
      window.nfc.addNdefListener(ndefEvent => {
          const nfcPayload = ndefEvent.tag.ndefMessage[0].payload;
          const url = window.nfc.bytesToString(nfcPayload);
          
          const routeConfig = parseDeepLink(url);
          if (routeConfig !== null) {
            router.push(routeConfig);
          }
        });
    } else {
      window.nfc.addMimeTypeListener('text/any', ndefEvent => {
        const nfcPayload = ndefEvent.tag.ndefMessage[0].payload;
        const url = window.nfc.bytesToString(nfcPayload);
        
        const routeConfig = parseDeepLink(url);
        if (routeConfig !== null) {
          router.push(routeConfig);
        }
      });
    }
}

export const NFC_STATES = {
  ENABLED: 'ENABLED',
  NO_NFC: 'NO_NFC',
  NFC_DISABLED: 'NFC_DISABLED'
};

let nfcState = NFC_STATES.NO_NFC;

/**
 * Get the state of the nfc interface
 */
export function getNfcState() {
  return nfcState;
}

/**
 * Add QR deep linking capabilities to a cordova application
 * @param {VueRouter} router - used for navigating to deep link targets.
 */
export function addQr(router) {
    window.universalLinks.subscribe('link_launch', function (eventData) {
      const routeConfig = parseDeepLink(eventData.url);
      if (routeConfig !== null) {
        router.push(routeConfig);
      }
    });
}

/**
 * Prevent sleep mode in the app.
 */
export function preventSleep() {
  window.plugins.insomnia.keepAwake();
}

/**
 * Reenable sleep mode in the app.
 */
export function reenableSleep() {
  window.plugins.insomnia.allowSleepAgain();
}

export function isRunningInAndroid() {
  return window.device && window.device.platform === 'Android';
}

export function isRunningInIOS() {
  return window.device && window.device.platform === 'iOS';
}