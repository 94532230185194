<template>
  <v-app-bar app dark class="primary">
    <drawer-toggler />
    
    <v-toolbar-title>
      {{ $t('shell.appTitle') }}
    </v-toolbar-title>
  </v-app-bar>
</template>

<script>
import DrawerToggler from '../components/DrawerToggler';

export default {
  components: {
    DrawerToggler
  }
}
</script>
