import axios, { AxiosResponse } from "axios";
import { ConstellationSide } from "../models/constellation-side";
import { FavoriteStatus } from "../models/favorite-state";
import { FavouriteEntry } from "../models/favourite-entry";
import { Statistic } from "../models/statistic";

export function getFavourites(): Promise<AxiosResponse<FavouriteEntry[]>> {
  return axios.get(`${window.$config.vuzzlerApi}/api/statistics/favorites`)
}

export function addFavourite(teamOne: ConstellationSide, teamTwo: ConstellationSide): Promise<AxiosResponse<void>> {
  return axios.put(`${window.$config.vuzzlerApi}/api/statistics/favorites`, {}, {
    params: { teamOne, teamTwo },
  })
}

export function removeFavourite(teamOne: ConstellationSide, teamTwo: ConstellationSide): Promise<AxiosResponse<void>> {
  return axios.delete(`${window.$config.vuzzlerApi}/api/statistics/favorites`, {
    params: { teamOne, teamTwo },
  })
}

export function isFaved(teamOne: ConstellationSide, teamTwo: ConstellationSide): Promise<AxiosResponse<FavoriteStatus>> {
  return axios.get(`${window.$config.vuzzlerApi}/api/statistics/favorites/query`, {
    params: { teamOne, teamTwo },
  })
}
