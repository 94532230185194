<template>
  <div class="page">
    <slot name="toolbar"></slot>
    <vue-pull-refresh :on-refresh="emitRefresh" :config="pullRefreshConfig">
      <slot></slot>
    </vue-pull-refresh>
  </div>
</template>

<script>
import VuePullRefresh from 'vue-pull-refresh';

const REFRESH_EVENT = 'refresh'

// Page wireframe that takes up the entire height.
export default {
  name: 'p2r-page',
  data: function() {
    return {
      pullRefreshConfig: {
        readyLabel: this.$i18n.t('common.refresh'),
        startLabel: this.$i18n.t('common.refresh'),
        loadingLabel: this.$i18n.t('common.refresh'),
        errorLabel: ''
      },
    }
  },
  methods: {
    emitRefresh: function() {
      this.$emit(REFRESH_EVENT)
    }
  },
  components: {
    VuePullRefresh,
  }
}
</script>

<style scoped>
.page {
  height: 100%;
}
</style>
