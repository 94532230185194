import { getIssues, resolveIssue } from "@/services/vuezlerApi/tournamentService";

export const Actions = {
  GetIssues: '[🐞 Issues] Get issues',
  ResolveRankingIssue: '[🐞 Issues] Resolve ranking issue'
}

export const Mutations = {
  SetIssues: '[🐞 Issues] Set issues',
  RemoveIssue: '[🐞 Issues] Remove issue'
}


const state = {
  issues: [],
}

const actions = {
  [Actions.GetIssues]: ({commit}, tournamentId) => {
    commit(Mutations.SetIssues, []);
    return getIssues(tournamentId).then(({ data: issues}) => {
      commit(Mutations.SetIssues, issues);
    });
  },
  [Actions.ResolveRankingIssue]: ({ commit }, { tournamentId, issue, updatedRanking }) => {
    return resolveIssue(tournamentId, issue.group.id, { updatedRanking }).then(() => {
      commit(Mutations.RemoveIssue, issue);
    })
  }
}

const mutations = {
  [Mutations.SetIssues]: (state, issues) => {
    state.issues = issues;
  },
  [Mutations.RemoveIssue]: (state, resolvedIssue) => {
    const idx = state.issues.findIndex(issue => issue === resolvedIssue);
    if (idx !== -1) {
      state.issues.splice(idx, 1);
    }
  }
}

export default {
  state,
  mutations,
  actions
}