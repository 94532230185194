<template>
  <component :is="issueComponent" :tournamentId="tournamentId" :issue="issue"></component>
</template>

<script>
import GroupRankingIssue from '@/modules/tournament/components/GroupRankingIssue.vue';
import KnockoutRankingIssue from '@/modules/tournament/components/KnockoutRankingIssue.vue';

export default {
  name: 'issue',
  props: {
    tournamentId: {
      type: String,
      required: true
    },
    issue: {
      type: Object,
      required: true
    }
  },
  computed: {
    issueComponent: function() {
      switch(this.issue.type) {
        case 0:
          return GroupRankingIssue;
        case 1:
          return KnockoutRankingIssue
        default:
          return undefined;
      }
    }
  }
}
</script>
