import { NavigationGuard } from "vue-router";

export const noEmptyPlayerParamGuard: NavigationGuard = (to, from, next) => {
  if (to.query.one === null || to.query.two === null || to.query.three === null || to.query.four === null) {
    const query = {
      one: to.query.one || undefined,
      two: to.query.two || undefined,
      three: to.query.three || undefined,
      four: to.query.four || undefined
    }

    next({
      name: 'statistic',
      query,
    })
  } else {
    next();
  }
}