


































import DefaultToolbar from '@/toolbar/DefaultToolbar.vue'
import Vue from 'vue'
import { getFavourites } from '../../api-services/statistic-favorites-api'
import { getPlayer } from '@core/services/playerService'
import { FavouriteEntry } from '../../models/favourite-entry'
import StatisticEntry from '../../components/StatisticEntry/StatisticEntry.vue'
import ListItemContentLoader from '@shared/components/content-loader/ListItemContentLoader.vue'
import { mapActions } from 'vuex'
import { ACTIONS as ShellActions } from "@/vuex/shell.module";

export default Vue.extend({
  components: { DefaultToolbar, StatisticEntry, ListItemContentLoader },
  name: 'statisticSelection',
  data() {
    return {
      favedStatistics: [] as FavouriteEntry[],
      profiles: {} as { [key: string]: { name: string, profileImage: string }},
      isLoadingFavourites: false
    }
  },
  computed: {
    allPlayers: function(): string[] {
      const teamOnePlayers = this.favedStatistics.flatMap(f => f.teamOne)
      const teamTwoPlayers = this.favedStatistics.flatMap(f => f.teamTwo)
      return teamOnePlayers.concat(teamTwoPlayers)
        .filter(v => v != null)
        .filter(v => v != 'any')
        .filter((v, index, self) => self.indexOf(v) === index);
    },
    favorites: function(): any {
      return this.favedStatistics.map(fav => ({
        teamOne: fav.teamOne.map(playerId => ({
          key: playerId,
          profile: playerId == null ? null : playerId == 'any' ? 'any' : this.profiles[playerId],
        })),
        teamTwo: fav.teamTwo.map(playerId => ({
          key: playerId,
          profile: playerId == null ? null : playerId == 'any' ? 'any' : this.profiles[playerId],
        }))
      }))
    },
  },
  mounted() {
    this.fetchFavourites();
  },
  watch: {
    'allPlayers': function(players: string[]) {
      players.forEach(playerId => {
        getPlayer(playerId).then(({data}) => {
          if (!this.profiles[playerId]) {
            Vue.set(this.profiles, playerId, data);
          }
        })
      })
    }
  },
  methods: {
    async fetchFavourites(): Promise<void> {
      this.isLoadingFavourites = true;
      try {
        const response = await getFavourites();
        if (response.status === 200) {
          this.favedStatistics = response.data;
        } else {
          this.showError(this.$t('statistics.error.couldntFetchFavourites'))
        }
      } catch (error) {
        this.showError(this.$t('statistics.error.couldntFetchFavourites'))
      }
      
      this.isLoadingFavourites = false;
    },
    ...mapActions({
      showError: ShellActions.SHOW_ERROR,
    })
  }
})
