import { getTournament, getTournamentTeams } from "../../../services/vuezlerApi/tournamentService"
import { getTournamentState } from "../../../logic/tournaments/tournamentState"
import { GETTERS as AuthGetters } from "../../../vuex/auth.module"
import { TournamentHub } from "../external/tournamentHub/tournamentHubConnection";
import tournamentHubMethods from "../external/tournamentHub/tournamentHubMethods";

export const Actions = {
  GatherTournamentInfo: '[🏆 Tournament] Gather tournament info',
  SetTournamentState: '[🏆 Tournament] Set tournament state',
  ResetTournament: '[🏆 Tournament] Reset tournament',
  Initialize: '[🏆 Tournament] Initialize',
  Destroy: '[🏆 Tournament] Destroy',
  FetchTeams: '[🏆 Tournament] Fetch teams',
}

export const Getters = {
  TournamentState: '[🏆 Tournament] Tournament State',
  Tournament: '[🏆 Tournament] Tournament',
  MyTeam: '[🏆 Tournament] My Team'
}

const Mutations = {
  SetTournament: '[🏆 Tournament] Set tournament',
  SetTournamentState: '[🏆 Tournament] Set tournament state',
  SetTeams: '[🏆 Tournament] Set teams',
  AddTeam: '[🏆 Tournament] Add team',
  RemoveTeam: '[🏆 Tournament] Remove team',
  SetHubInstance: '[🏆 Tournament] Set hub instance',
  ResetTournament: '[🏆 Tournament] Reset tournament',
  UpdateTeam: '[🏆 Tournament] Update team'
}

const state = {
  state: undefined,
  tournament: undefined,
  hubInstance: undefined,
  teams: []
}

const getters = {
  [Getters.Tournament]: () => state.tournament,
  [Getters.TournamentState]: () => state.tournament != null ? getTournamentState(state.tournament) : -1,
  [Getters.MyTeam]: (state, getters) => state.teams.find(team => isParticipating(getters[AuthGetters.USER_ID], team))
}

const actions = {
  [Actions.SetTournamentState]: ({commit}, state) => {
    commit(Mutations.SetTournamentState, state);
  },
  [Actions.ResetTournament]: ({commit}) => {
    commit(Mutations.ResetTournament);
  },
  [Actions.GatherTournamentInfo]: ({ commit, state }, tournamentId) => {
    if (state.id !== tournamentId) {
      return getTournament(tournamentId).then(({ data }) => {
        commit(Mutations.SetTournament, data);
        return data;
      });
    } else {
      return Promise.resolve(state.tournament);
    }
  },
  [Actions.Initialize]: ({ commit, state, dispatch }, tournamentId) => {
    if (state.hubInstance) {
      state.hubInstance.destroyConnection();
    }

    const tournamentHub = new TournamentHub(tournamentId);
    tournamentHub.addHandler(tournamentHubMethods.AddTeam, team => commit(Mutations.AddTeam, team));
    tournamentHub.addHandler(tournamentHubMethods.RemoveTeam, teamId => commit(Mutations.RemoveTeam, teamId));
    tournamentHub.addHandler(tournamentHubMethods.TeamUpdated, (teamId, team) => commit(Mutations.UpdateTeam, { teamId, team }));
    tournamentHub.addHandler(tournamentHubMethods.TournamentStarted, () => dispatch(Actions.GatherTournamentInfo, tournamentId));
    commit(Mutations.SetHubInstance, tournamentHub);
    const connectionPromise = tournamentHub.establishConnection();
    return connectionPromise;
  },
  [Actions.Destroy]: ({ commit, state }) => {
    state.hubInstance && state.hubInstance.destroyConnection();
    commit(Mutations.SetHubInstance, undefined);
  },
  [Actions.FetchTeams]: ({ commit }, tournamentId) => {
    return getTournamentTeams(tournamentId).then(({data}) => {
      commit(Mutations.SetTeams, data);
    });
  }
}

const mutations = {
  [Mutations.SetTournamentState]: (state, tournamentState) => {
    state.state = tournamentState;
  },
  [Mutations.ResetTournament]: (state) => {
    state.state = undefined;
  },
  [Mutations.SetTournament]: (state, tournament) => {
    state.tournament = tournament;
  },
  [Mutations.SetTeams]: (state, teams) => {
    state.teams = teams;
  },
  [Mutations.AddTeam]: (state, team) => {
    state.teams.push(team);
  },
  [Mutations.RemoveTeam]: (state, teamId) => {
    const idx = state.teams.findIndex(t => t.info.id === teamId);
    if (idx == null) return;

    state.teams = [...state.teams];
    state.teams.splice(idx, 1);
  },
  [Mutations.UpdateTeam]: (state, { teamId, team }) => {
    const idx = state.teams.findIndex(t => t.info.id === teamId);
    if (idx == null) return;

    state.teams[idx].info.name = team.name;
  },
  [Mutations.SetHubInstance]: (state, hubInstance) => {
    state.hubInstance = hubInstance;
  }
}

function isParticipating(userId, team) {
  return team
    .participants
    .find(participant => participant.id === userId) !== undefined;
}

export default {
  state,
  getters,
  actions,
  mutations
}