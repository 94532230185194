<template>
  <v-dialog v-model='active' persistent light>
    <v-card>
      <v-card-title class="headline">{{ title }}</v-card-title>
      <v-card-text class="description">{{ description }}</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="okAction">{{ $t("common.ok" )}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'errorDialog',
  props: {
    active: {
      type: Boolean,
      default: () => false
    },
    title: String,
    description: String,
  },
  methods: {
    okAction: function() {
      this.$emit("ok");
    }
  }
}
</script>

<style scoped>
.description {
  white-space: pre-wrap;
}
</style>
