import firebase from 'firebase/app';

export default () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      unsubscribe();
      user.getIdToken().then(token => {
        resolve(token);
      }).catch(reason => {
        reject(reason)
      })
    }, reject);
  })
}