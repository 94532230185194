<template>
    <v-list-item-content>
      <v-list-item-title>
        <div class="match-headline">
        <multi-avatar :avatars="avatarsForFirstTeam" />
          <span class="title score" :class="textColorClass">{{ match.home.score }} : {{ match.away.score }}</span>
        <multi-avatar :avatars="avatarsForSecondTeam" />
        </div>
      </v-list-item-title>
      <v-list-item-subtitle>
        <v-icon small left>access_time</v-icon>
        <label>{{ match.time | datetime }}</label>
      </v-list-item-subtitle>
    </v-list-item-content>
</template>

<script>
import { mapGetters } from 'vuex';
import { getSideById, extractPlayers, opposingSide } from '../logic/matchHelper';
import { GETTERS as AuthGetters } from '../vuex/auth.module';
import MultiAvatar from '@/components/MultiAvatar';

export default {
  name: 'match-summary',
  computed: {
    players: function() {
      return extractPlayers(this.match);
    },
    side: function() {
      return getSideById(this.players, this.playerId);
    },
    textColorClass: function() {
      const score = this.match[this.side].score;
      const opposingScore = this.match[opposingSide(this.side)].score;
      if (score > opposingScore) return 'green--text';
      else if (score < opposingScore) return 'red--text';
      else return '';
    },
    ...mapGetters({
      playerId: AuthGetters.USER_ID
    }),
    avatarsForFirstTeam() {
      return this.players.home.map(p => p.profileImage);
    },
    avatarsForSecondTeam() {
      return this.players.away.map(p => p.profileImage);
    }
  },
  props: {
    match: Object
  },
  components: {
    MultiAvatar
  }
}
</script>

<style scoped>
.score {
  text-align: center
}

.info-block .flex {
  margin-top: 4px;
  margin-bottom: 4px;
}

.match-headline {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>
