<template>
  <v-dialog
    :value="value"
    @input="close">
    <v-card>
      <v-card-title
        class="headline"
        primary-title>
        {{ $t("group.createGroup") }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="isFormValid">
          <v-text-field v-model="name" :label="$t('group.name')" :rules="[requiredRule]"></v-text-field>
          <v-text-field v-model="description" :label="$t('group.description')"></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="primary" @click="close">{{ $t("common.cancel") }}</v-btn>
        <v-btn text color="primary" @click="createGroup" :disabled="!isFormValid" :loading="isCreating">
          {{ $t("common.create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ACTIONS as ShellActions } from '../../vuex/shell.module';
import { createGroup } from '../../services/vuezlerApi/group.service';
import { ACTIONS as GroupActions } from '../../vuex/group.module';
import { mapActions } from 'vuex';
import { required } from '../../logic/common/inputValidationRules';

export default {
  name: 'group-creation-dialog',
  data: () => ({
    name: '',
    description: '',
    isFormValid: false,
    isCreating: false
  }),
  props: {
    value: Boolean
  },
  methods: {
    requiredRule: required,
    ...mapActions({
      addGroupMembership: GroupActions.ADD_GROUPMEMBERSHIP,
      showError: ShellActions.SHOW_ERROR
    }),
    close: function() {
      this.$refs.form.reset();
      this.$emit('input');
    },
    createGroup: function() {
      this.isCreating = true;
      createGroup({
        name: this.name,
        description: this.description
      }).then(({data}) => {
        this.addGroupMembership(data);
        this.isCreating = false;
        this.close();
        this.$router.push(`/group/${data.id}`);
      }).catch(() => {
        this.isCreating = false;
        this.showError('Could not create group');
      });
    }
  }
}
</script>
