export const ACTIONS = {
  SET_SUMMARY: 'SET_SUMMARY',
  RESET_SUMMARY: 'RESET_SUMMARY'
}

export const MUTATIONS = {
  SET_MATCH: 'SET_MATCH'
}

const state = {
  match: null
}

const actions = {
  [ACTIONS.SET_SUMMARY]: ({ commit }, match) => {
    commit(MUTATIONS.SET_MATCH, match);
  },
  [ACTIONS.RESET_SUMMARY]: ({ commit }) => {
    commit(MUTATIONS.SET_MATCH, null);
  }
}

const mutations = {
  [MUTATIONS.SET_MATCH]: (state, match) => {
    state.match = match;
  }
}

export default {
  state,
  actions,
  mutations
}