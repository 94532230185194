<template>
  <v-avatar v-bind="$attrs" v-on="$listeners" class="avatar">
    <v-img :src="avatarSrc" :style="{border}" alt="Player Avatar"/>
  </v-avatar>
</template>

<script>
export default {
  name: 'avatar',
  data() {
    return {
      defaultAvatar: require('@/assets/images/default-avatar.png')
    }
  },
  props: ["src", "border"],
  computed: {
    avatarSrc() {
      return this.src || this.defaultAvatar;
    }
  }
}
</script>


<style scoped>
  .avatar {
    background-color: white;
  }
</style>
