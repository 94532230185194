import _ from 'lodash';

export function prepareErrorMessages(errorObject) {
  if (typeof errorObject === 'string')
    return [ errorObject ];

  const errorMessages = [];
  for (const [key, errors] of Object.entries(errorObject)) {
    for (const error of errors) {
       errorMessages.push(`${error.replace('{0}', key)}`);
    }
  }
  return _.uniq(errorMessages);
}