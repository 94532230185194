<template>
  <div style="overflow: hidden; position: absolute; right: 0px; left: 0px;">
    <div style="width: 100%; height: 20px; background: linear-gradient(to bottom, lightgrey 5%, grey, lightgrey 95%); position: relative; top: 50px"></div>

    <div class="swiper" :class="swiperMoveClass" :style="swiperMoveTransition">
      <v-touch @swiperight="handleIncrementGoals" @swipeleft="handleDecrementGoals" class="touchComponent">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 380 380">
          <rect fill="#cc0000" id="Rectangle_1" data-name="Rectangle 1" class="cls-1" width="380" height="380" rx="57" ry="57"/>
          <rect fill="#ff0000" id="Rectangle_2" data-name="Rectangle 2" class="cls-1" width="380" height="380" rx="170" ry="170"/>
        </svg>
      </v-touch>
    </div>

    <audio class="soundPlayer" ref="goalSoundPlayer" preload="auto" />  
  </div>
</template>

<script>
const SLIDING_TIME = 300;
const ADD_GOAL_SOUND_FILE = require('../assets/sounds/score_up.mp3');
const REMOVE_GOAL_SOUND_FILE = require('../assets/sounds/score_down.mp3');

export default {
  data: () => ({
    goLeft: false,
    goRight: false,
    slidingTime: SLIDING_TIME,
    swiperMoveTransition: {
      transition: `${SLIDING_TIME}ms ease all`,
    },
    defaultScoreSound: ADD_GOAL_SOUND_FILE
  }),
  computed: {
    swiperMoveClass: function() {
      return this.goLeft ? 'movedLeft' :
             this.goRight ? 'movedRight' :
             'movedBack';
    }
  },
  props: {
    onIncrementGoals: Function,
    onDecrementGoals: Function
  },
  methods: {
    handleIncrementGoals: function() {
      this.goRight = true;
      this.$emit('onIncrementGoals');
      setTimeout(() => {
        this.goRight = false;
      }, this.slidingTime);
      this.playSlidingSound(ADD_GOAL_SOUND_FILE);
    },
    handleDecrementGoals: function() {
      this.goLeft = true;
      this.$emit('onDecrementGoals');
      setTimeout(() => {
        this.goLeft = false;
      }, this.slidingTime);
      this.playSlidingSound(REMOVE_GOAL_SOUND_FILE);
    },
    playSlidingSound: function(soundFile) {
      setTimeout(() => {
        this.$refs.goalSoundPlayer.src = soundFile;
        this.$refs.goalSoundPlayer.currentTime = 0;
        this.$refs.goalSoundPlayer.play();
      }, this.slidingTime-150);
    }
  }
}
</script>

<style scoped>
  .movedRight{
    margin-left: 90%;
  }

  .movedBack {
    margin-left: 0px;
  }

  .movedLeft{
    margin-left: -50%;
  }

  .swiper {
    width: 100px;
    position: relative;
    left: 50px;
    top: -10px;
  }

  .soundPlayer {
    visibility: hidden;
  }

  .touchComponent {
    height: 100px;
  }
</style>
