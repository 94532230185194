<template>
  <img src="../assets/loader.gif" style="width: 100%; height: 100%" />
</template>

<script>
import { getMatchAtVenue } from '../services/vuezlerApi/venue.service';
import { ACTIONS } from '../vuex/shell.module';
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions({
      showErrorMessage: ACTIONS.SHOW_ERROR
    })
  },
  mounted: function() {
    getMatchAtVenue(this.$route.params.venueId)
      .then(({ data: matchRegistration }) => {
        const target = {
          name: 'match',
          params: {
            id: matchRegistration.matchId
          }
        }

        if (this.$route.query.pos) {
          target.query = {
            pos: this.$route.query.pos
          }
        }

        this.$router.replace(target);
      }).catch(() => {
        this.showErrorMessage(this.$t("match.errorOpeningMatch"));
        this.$router.replace('/');
      });
  }
}
</script>
