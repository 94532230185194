import Login from '../components/Login.vue';
import Home from '../pages/Home';
import QrScanner from '../pages/QrScanner';
import Match from '../pages/Match';
import Overview from '../pages/Overview';
import VenueGateway from '../pages/VenueGateway';
import MatchHistory from '../pages/MatchHistory';
import Support from '../pages/Support/Support';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Group from '../pages/Group.vue';
import SignUp from '../pages/SignUp';
import MatchToolbar from '../toolbar/MatchToolbar';
import GroupToolbar from '../toolbar/GroupToolbar';
import VueRouter from 'vue-router';
import { guards as multi } from '../logic/guardsHelper';
import { authenticationGuard } from './guards/authenticationGuard';
import { matchSummaryGuard } from './guards/matchSummaryGuard';
import { unauthorizedOnlyGuard } from './guards/unauthorizedOnlyGuard';
import { store } from '../vuex/store';
import { withToolbar } from './routeHelpers';
import LandingPage from '../pages/LandingPage';
import CoreRoutes from '@core/routes';
import TournamentRoutes, { globalGuards } from '@tournament/routes'
import StatisticRoutes from '@statistic/routes';

const guards = {
  authentication: authenticationGuard,
  matchSummary: matchSummaryGuard(store),
  unauthorizedOnly: unauthorizedOnlyGuard
};

const routes = [
  { name: 'landingpage', path: '/landingpage', component: LandingPage },
  { path: '/:lang/landingpage', component: LandingPage, props: true },

  { name: 'home', path: '/', component: Home },
  
  { name: 'qrScanner', path: '/qrScanner', component: QrScanner, beforeEnter: guards.authentication },
  { name: 'match', path: '/match/:id', components: withToolbar(Match, MatchToolbar), beforeEnter: guards.authentication },
  { name: 'group', path: '/group/:id', components: withToolbar(Group, GroupToolbar), beforeEnter: guards.authentication },
  { name: 'matchHistory', path: '/history', components: withToolbar(MatchHistory), beforeEnter: guards.authentication },
  { name: 'login', path: '/login', components: withToolbar(Login), beforeEnter: guards.unauthorizedOnly },
  { name: 'signUp', path: '/sign-up', components: withToolbar(SignUp), beforeEnter: guards.unauthorizedOnly},
  { path: '/overview', components: withToolbar(Overview), beforeEnter: multi([guards.authentication, guards.matchSummary]) },
  { name: 'venue', path: '/venue/:venueId', component: VenueGateway, beforeEnter: guards.authentication },

  { name: 'support', path: '/support', components: withToolbar(Support), beforeEnter: guards.authentication },
  { name: 'privacy', path: '/privacy', components: withToolbar(PrivacyPolicy)},
  ...CoreRoutes,
  ...TournamentRoutes,
  ...StatisticRoutes
];

const router = new VueRouter({
  mode: process.env.CORDOVA_PLATFORM === undefined ? 'history' : 'hash',
  routes
})

router.beforeEach(multi(globalGuards))

export default router;