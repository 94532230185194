<template>
  <v-app-bar app dark class="primary">
    <v-btn dark text icon @click="navigateBack">
      <v-icon>arrow_back</v-icon>
    </v-btn>
    
    <v-toolbar-title>
      {{ toolbarTitle || $t('shell.appTitle') }}
      <div v-if="subtitle" class="subtitle-1">{{ subtitle }}</div>
    </v-toolbar-title>

    <v-spacer />

    <slot name="action" />

    <template v-slot:extension v-if="hasSlot">
      <slot />
    </template>
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'stacked-page-toolbar',
  props: {
    title: String
  },
  computed: {
    ...mapState({
      titleFromState: state => state.shell.title,
      subtitle: state => state.shell.subtitle
    }),
    toolbarTitle: function() {
      return this.title || this.titleFromState
    },
    hasSlot() {
      return !!this.$slots.default;
    }
  },
  methods: {
    navigateBack: function() {
      this.$router.back();
    }
  }
}
</script>
