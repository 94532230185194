import Vue, { VNode } from 'vue'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import router from './routes';
import VueRouter from 'vue-router';
import VueTouch from 'vue-touch';
import {store} from './vuex/store';
import {i18n} from './i18n';
import './registerServiceWorker'
import './filters/formatFilter'
import setupAuthorizationInterceptor from './logic/setupAuthorizationInterceptor';
import VueSvgIcon from 'vue-svgicon';
import './compiled-icons';
import { registerAuthStateHandler } from './modules/core/auth/firebaseAuthProvider';
import { VueFirebaseAuth } from './modules/core/auth/firebaseAuthPlugin';
import * as cordovaApi from './modules/core/cordova/cordovaApi';
import pageTitleMixin from './mixins/pageTitleMixin';
import StoryblokVue from 'storyblok-vue'
import { StoryBlok } from './modules/crm/plugins/storyBlokPlugin';
import { registerGlobalComponents } from '@shared/index';
import Axios from 'axios';
import VueAppInsights from '@core/logging'
import { ThisTypedComponentOptionsWithArrayProps } from 'vue/types/options';
import { setupParamSerialization } from './services/vuezlerApi/param-serialization';

type Options = ThisTypedComponentOptionsWithArrayProps<Vue, object, object, object, never>

Vue.config.productionTip = false

Vue.mixin(pageTitleMixin);

Vue.use(StoryblokVue)
Vue.use(VueRouter);
Vue.use(VueTouch);
Vue.use(VueSvgIcon);
Vue.use(VueFirebaseAuth);
Vue.use(StoryBlok);

registerGlobalComponents();

const vueInstanceOptions: Options = {
  router,
  i18n,
  vuetify,
  render (createElement): VNode {
    return createElement(App)
  },
  store
}

function configureApp() {
  const appsettingsLocation = cordovaApi.isRunningInCordova() ?
    // This paths must be hardcoded and should be bundled/copied with wepback.
    // The reason for this is, that the settings should be loaded at runtime rather than during buildtime.
    // This way we can substitute the settings by replacing files on the host system.
    // This process can be automated by the release pipeline, meaning we don't need separate builds for each env.
    'static/config/appsettings.json' :
    '/static/config/appsettings.json';
  
  // This should be done by @csiber/audible. Somehow the window target didn't work on cordova iOS though.
  Axios.get(appsettingsLocation).then(({ data }) => {
    window.$config = data;

    Vue.use(VueAppInsights, {
      instrumentationKey: data.applicationInsightsKey,
      router
    });

    initializeApp(vueInstanceOptions);
  })
}


function initializeApp(instanceOptions: Options) {
  setupAuthorizationInterceptor(store, router);
  setupParamSerialization();

  if (cordovaApi.isRunningInCordova()) {
    cordovaApi.addNfc(router);
    cordovaApi.addQr(router);    
  }

  registerAuthStateHandler(store).then(() => {
    new Vue(instanceOptions).$mount('#app')
  })
}

if (window.cordova !== undefined) {
  document.addEventListener('deviceready', configureApp, false)
} else {
  configureApp();
}
