<template>
    <div class="header-container">
      <div class="background">
        <div class="blue-overlay"></div>
      </div>
      
      <nav-bar>
        <v-btn text link color="white" class="text-capitalize" href="#features">Features</v-btn>
        <v-btn text link color="white" class="text-capitalize" href="#download">Download</v-btn>
        <v-btn text link color="white" class="text-capitalize" href="#feedback">Feedback</v-btn>
      </nav-bar>

      <v-spacer />

      <div class="main-offer white--text headline px-4" style="text-align: center">
        <div class="carousel-text small">{{$t('landingPage.carousel.text')}}</div>

        <v-carousel class="carousel-options" continuous cycle vertical touchless :show-arrows="false" height="auto" hide-delimiters interval="2000">
          <v-carousel-item>{{$t('landingPage.carousel.options.yourFriends')}}</v-carousel-item>
          <v-carousel-item>{{$t('landingPage.carousel.options.yourCoworkers')}}</v-carousel-item>
          <v-carousel-item>{{$t('landingPage.carousel.options.playersAroundTheWorld')}}</v-carousel-item>
        </v-carousel>

        <div class="carousel-text small">using our digital foosball companion.</div>
      </div>

      <v-spacer />

      <div class="call-to-action" :class="{ 'small': isSmallScreen }">
        <v-btn class="call-to-action-button" elevation="8" rounded x-large color="secondary" :to="{ name: 'login' }">Sign In and Play</v-btn>
      </div>

      <v-spacer />

      <div class="bottom-separator">
        <div class="foosball-figure"></div>
        <div class="foosball-figure right"></div>
      </div>
    </div>
</template>

<script>
import { isRunningInCordova } from '../../core/cordova/cordovaApi';

export default {
  name: 'crm-header',
  props: ['blok'],
  computed: {
    isRunningInCordova: function() {
      return isRunningInCordova();
    },
    isSmallScreen: function() {
      return this.$vuetify.breakpoint.xs;
    },
  },
}
</script>

<style scoped>
.header-container {
  display: flex;
  flex-direction: column;
  height: 75vh;
  position: relative;
}

.background {
  background-image: url('~@/assets/images/stock/landing-image.jpg');
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.blue-overlay {
  position: relative;
  width: 100%;
  height: 100%;
  
  background-color: var(--v-primary-base);
  opacity: 0.75;
}

.header {
  padding: 16px;
  color: white;
}

.main-offer {
  margin-top: 56px;
  position: relative;
}

.toolbar {
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
}

.call-to-action {
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 1;
}

.call-to-action.small {
  margin-bottom: 80px;
}

.carousel-text {
  display: inline-block;
  padding-right: 8px;
  overflow: hidden;

  /* bottom margin because of the border on the options */
  margin-bottom: 2px;
}

.carousel-text.small {
  display: block;
}

.carousel-options {
  width: auto;
  display: inline-block;
  border-bottom: 2px solid white;
  color: white !important;
}

 /deep/ .v-responsive__content {
   color: white !important;
 }

.bottom-separator {
  position: relative;
}

.foosball-figure {
  position: absolute;
  bottom: -1px;
  height: 100px;

  background-image: url('~@/assets/images/foosball-figures/foosball-figure-top.svg');
  background-position-y: bottom;
  width: 25%;
}

.foosball-figure.right {
  right: 0;
  transform: scaleX(-1);
}
</style>
