function getTitle (vm) {
    const { pageTitle } = vm;
    return pageTitle;
  }
  
export default {
    created () {
        if (this.pageTitle == null) return;
        document.title = getTitle(this);
    }
}