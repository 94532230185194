<template>
  <p2r-page @refresh="fetchGamePlan">
    <stacked-page-toolbar :title="$t('tournament.gamePlanTitle')" slot="toolbar">
      <v-btn text icon @click="previousStage" aria-label="go to previous group" :disabled="!hasPreviousStage"><v-icon>skip_previous</v-icon></v-btn>
      <v-btn text icon @click="previousGroup" aria-label="go to previous stage" :disabled="!hasPreviousGroup"><v-icon>navigate_before</v-icon></v-btn>
      <v-spacer />
      {{ currentStage && currentStage.title }}
      <v-spacer />
      <v-btn text icon @click="nextGroup" aria-label="go to next group" :disabled="!hasNextGroup"><v-icon>navigate_next</v-icon></v-btn>
      <v-btn text icon @click="nextStage" aria-label="go to next stage" :disabled="!hasNextStage"><v-icon>skip_next</v-icon></v-btn>
    </stacked-page-toolbar>
    <div v-if="isLoading" class="loading my-2">
      <v-progress-circular indeterminate color="primary" />
    </div>
    <section class="game-plan-container" v-if="gamePlan" @touchstart.capture="reDispatchTouchEvent" @touchend.capture="reDispatchTouchEvent">
      <v-tabs-items v-model="currentGroupIndex" class="game-plan">
        <v-tab-item v-for="(stage, idx) in gamePlan" :key="stage.id" :value="idx">
          <group-stage v-if="isGroupStage" :value="currentStage.value" />
          <knockout-stage v-else :value="currentStage.value" />
        </v-tab-item>
      </v-tabs-items>
    </section>
  </p2r-page>
</template>

<script>
import StackedPageToolbar from '@/toolbar/StackedPageToolbar';
import GroupStage from '@tournament/components/GroupStage';
import KnockoutStage from '@tournament/components/KnockoutStage';
import { getGamePlan } from '@/services/vuezlerApi/tournamentService';
import { GroupStageType } from '@/services/vuezlerApi/models/stageType';
import { getStageName } from '@/logic/tournaments/tournamentStageFormatter';

// Pull to refresh doesn't work with VWindows that are used by Vuetifys Tabs
export default {
  name: 'tournament-game-plan',
  data: function() {
    return {
      gamePlan: [],
      currentGroupIndex: 0,
      stageTitle: undefined,
      subStageIndex: 0,
      isLoading: false
    }
  },
  computed: {
    currentStage() {
      return this.gamePlan && this.gamePlan[this.currentGroupIndex];
    },
    hasPreviousStage() {
      return this.currentStage && this.currentStage.previousStageIndex !== undefined;
    },
    hasNextStage() {
      return this.currentStage && this.currentStage.nextStageIndex < this.gamePlan.length;
    },
    hasPreviousGroup() {
      return this.currentGroupIndex > 0;
    },
    hasNextGroup() {
      return this.gamePlan && this.currentGroupIndex < this.gamePlan.length - 1;
    },
    isGroupStage() {
      return this.currentStage.type === GroupStageType;
    }
  },
  props: {
    tournamentId: {
      type: String,
      required: true
    }
  },
  methods: {
    nextStage() {
      if (this.hasNextStage) {
        this.currentGroupIndex = this.currentStage.nextStageIndex;
      }
    },
    reDispatchTouchEvent(ev) {
      // Vuetifys v-tabs-item component stops propagating touch events (like touchstart and touchend).
      // The P2R library needs those events to provide it's functionality though. What we're doing here is therefore taking the event
      // in the capture phase (before it reaches the vuetify component), creating a copy of it and redispatch it.
      const newEvent = new event.constructor(ev.type, { touches: ev.touches })
      // Redispatch is required from the parent because otherwise it'll retrigger itself.
      ev.currentTarget.parentElement.dispatchEvent(newEvent);
    },
    previousStage() {
      if (this.hasPreviousStage) {
        this.currentGroupIndex = this.currentStage.previousStageIndex;
      }
    },
    nextGroup() {
      if (this.hasNextGroup) {
        this.currentGroupIndex++;
      }
    },
    previousGroup() {
      if (this.hasPreviousGroup) {
        this.currentGroupIndex--;
      }
    },
    fetchGamePlan() {
      this.isLoading = true;

      getGamePlan(this.tournamentId).then(({ data }) => {
        this.gamePlan = this.mapToViewModel(data.stages);
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },
    buildKnockoutLayers(knockoutStage) {
      const layers = []
      for(var stage of knockoutStage.groups) {
        if (layers[stage.order] === undefined) {
          layers[stage.order] = [ stage ]
        } else {
          layers[stage.order].push(stage);
        }
      }
      return layers;
    },
    mapToViewModel(stages) {
      const self = this;
      const viewModels = [];
      let previousStage = undefined;

      for(const stage of stages) {
        if (stage.type === GroupStageType) {
          const nextStage = viewModels.length + stage.groups.length;
          const mappedViewModels = stage.groups
            .map(group => {
              return {
                title: `${this.$t('tournament.groupPrefix')} ${group.name}`,
                previousStageIndex: previousStage,
                nextStageIndex: nextStage,
                type: stage.type,
                value: group
                }
            })
            .sort((first, second) => first.value.order-second.value.order);
          viewModels.push(...mappedViewModels);
          previousStage = viewModels.length - stage.groups.length;
        } else {
          const layers = this.buildKnockoutLayers(stage);
          const nextStage = viewModels.length + layers.length;
          const mappedViewModels = layers.map(knockoutLayer => {
            return {
              previousStageIndex: previousStage,
              nextStageIndex: nextStage,
              title: getStageName.call(self, knockoutLayer.length),
              type: stage.type,
              value: knockoutLayer
            }
          });
          viewModels.push(...mappedViewModels);
          previousStage = viewModels.length - layers.length;
        }
      }

      return viewModels;
    }
  },
  mounted() {
    this.fetchGamePlan();
  },
  components: {
    StackedPageToolbar,
    GroupStage,
    KnockoutStage
  }
}
</script>

<style scoped>
.game-plan-container {
  padding: 12px;
  height: 100%;
}

.game-plan {
  height: 100%;
}

.loading {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>