<template>
<v-card>
  <v-card-title>
    <h1 class="title">{{$t('tournament.registerTitle')}}</h1>
  </v-card-title>
  <v-card-text>
    <p>{{ $t('tournament.participationNotice') }}</p>
    <v-text-field label="Team Name" required :rules="[requiredRule]" v-model="teamName" clearable></v-text-field>
    <div style="text-align: center"></div>
    <v-btn block color="primary" @click="createTeam">Create Team</v-btn>
    <v-divider class="my-3"/>
    <v-btn block outlined @click="scanQrCode">Join Team</v-btn>
  </v-card-text>
  <v-card-actions>
    <v-spacer/>
    <v-btn color="error" text @click="closeDialog">Cancel</v-btn>
  </v-card-actions>
</v-card>
</template>

<script>
import { required } from '@/logic/common/inputValidationRules';
import { ACTIONS as AuthActions, GETTERS as AuthGetters } from '@/vuex/auth.module';
import { mapActions, mapGetters } from 'vuex';
import { createTeam } from '@/services/vuezlerApi/tournamentService';
import { shellInteractorMixin } from '@core/mixins/shellInteractorMixin';

export default {
  name: 'participation-dialog',
  data: () => ({
    teamName: ''
  }),
  mixins: [shellInteractorMixin],
  props: {
    tournamentId: String
  },
  computed: {
    ...mapGetters({
      userName: AuthGetters.USER_NAME
    })
  },
  methods: {
    requiredRule: required,
    closeDialog() {
      this.$emit('close')
    },
    createTeam() {
      createTeam(this.tournamentId, {
        name: this.teamName
      }).then(() => {
        this.$emit('close');
      }).catch(({ response }) => {
        if (response.status == 400 && response.data.key) {
          const errorMessage = this.$t(`errorCodes.${response.data.key}`);
          this.showError(errorMessage);
        } else {
          const errorMessage = this.$t('tournament.error.registrationError');
          this.showError(errorMessage);
        }
      });
    },
    scanQrCode() {
      this.$router.push('/qrScanner');
    },
    ...mapActions({
      gatherUserInfo: AuthActions.GATHER_USER_INFO
    })
  },
  mounted: function() {
    this.gatherUserInfo().then(() => {
      this.teamName = this.userName
    })
  }
}
</script>

<style scoped>
.card-container {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
</style>