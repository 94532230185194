<template>
    <v-form v-model="isFormValid" class="support-form" v-if="!isSuccessfullySubmitted">
        <v-flex v-if="isUnavailable" shrink>
            <v-alert :value="true" color="error">{{ error }}</v-alert>
        </v-flex>
        <v-flex shrink>
            <v-textarea solo name="description" :label="$t('support.description')" :rules="[descriptionRule, lengthRule]" v-model="description" counter="500" required></v-textarea>
        </v-flex>
        <v-flex shrink>
            <v-btn :loading="isSubmitting" color="primary" @click="submitSupportTicket" :disabled="!isRequestPermitted">
                <v-icon left>send</v-icon>
                {{ $t('common.send' ) }}
            </v-btn>
        </v-flex>
    </v-form>
</template>

<script>
import { createSupportTicket } from '../../services/vuezlerApi/support.service';
import { required, maxLength } from '../../logic/common/inputValidationRules';
import { getVersion } from '../../logic/common/shell';
import { mapGetters, mapActions } from 'vuex';
import { GETTERS } from '../../vuex/auth.module';
import { ACTIONS } from '../../vuex/shell.module';
import { format } from 'date-fns';

export default {
    name: 'authorized-support',
    data: () => ({
        isFormValid: false,
        description: '',
        isSubmitting: false,
        isUnavailable: false,
        isSuccessfullySubmitted: false,
        error: undefined
    }),
    computed: {
        isRequestPermitted: function() {
            return this.isFormValid;
        },
        ...mapGetters({
            mail: GETTERS.USER_MAIL
        })
    },
    methods: {
        descriptionRule: required,
        lengthRule: maxLength(500),
        submitSupportTicket: function() {
            this.isSubmitting = true;

            if (!this.isRequestPermitted) {
                return;
            }

            const version = getVersion();

            createSupportTicket({
                description: this.description,
                mail: this.mail,
                version,
            }).then(() => {
                this.isSubmitting = false;
                this.error = undefined;

                this.$emit('submitted');
            }).catch(error => {
                const { response } = error;
                if (response && response.status === 400 && response.data.key === 'tooManySupportTickets') {
                    const tillDate = format(new Date(response.data.data.till), 'p');
                    this.error = this.$t('support.tooMany').replace("%TIME%", tillDate)
                } else {
                    this.error = this.$t('support.unavailable');
                }

                this.isSubmitting = false;
                this.isUnavailable = true;
            })
        },
        ...mapActions({
            showErrorMessage: ACTIONS.SHOW_ERROR
        })
    },
}
</script>

<style scoped>
.re-captcha>div{
    margin-left: auto;
    margin-right: auto;
}

.support-form>*:not(:last-child) {
    margin-bottom: 8px;
}
</style>