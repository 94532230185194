<template>
  <v-card>
    <v-card-title class="title">{{ $t('tournament.issues.knockoutRankingTitle' )}}</v-card-title>
    <v-card-subtitle>{{ groupName }}</v-card-subtitle>
    <v-card-text>
        <p>{{ $t('tournament.issues.knockoutRankingDescription') }}</p>
        <v-radio-group hide-details class="team-selection" v-model="selectedTeam">
          <v-radio v-for="team in teams" :key="team.id"
            class="team" active-class="selected-team"
            :label="team.name" on-icon="done" :off-icon="null"
          ></v-radio>
        </v-radio-group>
    </v-card-text>
    <v-card-actions class="actions">
      <v-spacer/>
      <v-btn text color="primary" :disabled="selectedTeam == null"
        @click="resolveIssue" :loading="isSubmitting">{{ $t('common.submit') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import { Actions } from '@/modules/tournament/states/issues';

export default {
  name: 'knockout-ranking-issue',
  data: function(){
    return {
      selectedTeam: undefined,
      isSubmitting: false
    };
  },
  props: {
    issue: {
      type: Object,
      required: true
    },
    tournamentId: String
  },
  computed: {
    teams: function() {
      return this.issue.group.participants.map(p => p.team);
    },
    groupName: function() {
      return this.$t('tournament.knockoutStage');
    }
  },
  methods: {
    ...mapActions({
      resolveRankingIssue: Actions.ResolveRankingIssue
    }),
    resolveIssue: function() {
      if (this.selectedTeam == null) {
        return;
      }
      
      const ranking = [...this.teams]
      ranking.splice(this.selectedTeam, 1);
      ranking.unshift(this.teams[this.selectedTeam]);
      const updatedRanking = ranking.map((team, rank) => ({ rank, team }));

      this.isSubmitting = true;

      this.resolveRankingIssue({ tournamentId: this.tournamentId, issue: this.issue, updatedRanking }).then(() => {
        this.isSubmitting = false;
      }).then().catch(({ response }) => {
        this.isSubmitting = false;
        if (response.status == 400 && response.data.key) {
          const errorMessage = this.$t(`errorCodes.${response.data.key}`);
          this.showError(errorMessage);
        } else {
          this.showError(this.$t('common.unknownError'));
        }
      });
    },
  }
}
</script>

<style scoped>
.team-selection {
  border-radius: 8px;
  border: 1px solid var(--v-grey-base);
  padding-top: inherit !important;
}

.team {
  margin-bottom: inherit !important;
  padding: 8px;
}

.team:not(:last-child) {
  border-bottom: 1px solid var(--v-grey-base);
}

.selected-team {
  background-color: var(--v-grey-lighten2);
}
</style>
