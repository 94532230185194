<template>
<v-container>
  <tournament-edit-toolbar @save="saveTournament" :disableSave="!isGamePlanValid"></tournament-edit-toolbar>
  <v-layout column justify-start v-if="tournament">
    <v-flex class="section" shrink>
      <h1 class="title">{{$t('tournament.basicInformation')}}</h1>
      <v-text-field clearable 
        :label="$t('tournament.name')"
        v-model="tournament.name"></v-text-field>

      <v-textarea rows="3" auto-grow :label="$t('tournament.editor.descriptionLabel')" :placeholder="$t('common.optional')" v-model="tournament.description">
      </v-textarea>

      <v-textarea rows="3" auto-grow :label="$t('tournament.editor.rulesLabel')" :placeholder="$t('common.optional')" v-model="tournament.rules">
      </v-textarea>
    </v-flex>

    <v-flex class="section" shrink>
      <h1 class="title">{{$t('tournament.gamePlanTitle')}}</h1>
      <v-form v-model="isGamePlanValid">
        <game-plan-editor v-model="gamePlanSpecification" @input="touchGamePlan"
          :nrOfParticipants="teams.length"
          :errors="gamePlanSpecificationErrors"></game-plan-editor>
      </v-form>
    </v-flex>
    
    <v-flex class="section" shrink>
      <h1 class="title">{{$t('tournament.administrative')}}</h1>
      <v-switch v-model="tournament.isRegistrationOpen" @change="changeRegistrationState"
        :disabled="isUpdatingRegistrationStatus"
        :label="tournament.isRegistrationOpen ? $t('tournament.registrationOpen') : $t('tournament.registrationClosed')"></v-switch>
      <v-btn block color="error" @click="deleteTournament" :loading="isDeletingTournament">{{ $t("tournament.deleteTournament") }}</v-btn>
    </v-flex>
  </v-layout>
</v-container>
</template>

<script>
import { removeTournament, updateTournament, updateGamePlan, getGamePlanSpec, setRegistrationState } from "@/services/vuezlerApi/tournamentService";
import { mapActions, mapState, mapGetters } from 'vuex';
import { Actions as TournamentActions } from '@tournament/states/tournament';
import { ACTIONS as TournamentListActions } from '@tournament/states/tournament-list';
import { shellInteractorMixin } from '@core/mixins/shellInteractorMixin';
import TournamentEditToolbar from '@tournament/toolbars/TournamentEditToolbar';
import GamePlanEditor from '@tournament/components/GamePlanEditor';
import * as dot from 'dot-object';
import { GETTERS as AuthGetters } from '@/vuex/auth.module';

export default {
  name: 'tournament-edit',
  data: () => ({
    isDeletingTournament: false,
    tournament: undefined,
    gamePlanSpecification: [],
    gamePlanSpecificationErrors: {},
    gamePlanTouched: false,
    isGamePlanValid: true,
    isUpdatingRegistrationStatus: false
  }),
  mixins: [ shellInteractorMixin ],
  computed: {
    ...mapState({
      fetchedTournament: state => state.tournament.tournament,
      teams: state => state.tournament.teams,
    }),
    ...mapGetters({
      currentUserId: AuthGetters.USER_ID,
    })
  },
  methods: {
    changeRegistrationState: function(isRegistrationOpen) {
      setRegistrationState(this.tournament.id, isRegistrationOpen).then(() => {
        this.isUpdatingRegistrationStatus = false;
      }).catch(() => {
        this.isUpdatingRegistrationStatus = false;
        this.tournament.isRegistrationOpen = !isRegistrationOpen;
      });
    },
    deleteTournament: function() {
      this.isDeletingTournament = true;
      removeTournament(this.tournament.id).then(() => {
        this.isDeletingTournament = false;
        this.refreshTournaments(true);
        this.$router.back();
      }).catch(() => {
        this.isDeletingTournament = false;
      })
    },
    saveTournament: function() {
      const apiCalls = [];

      if (this.gamePlanTouched) {
        var promise = updateGamePlan(this.tournament.id, { stages: this.gamePlanSpecification }).then(() => {
          this.gamePlanSpecificationErrors = {};
          this.gamePlanTouched = false;
        }).catch((error) => {
          if (error.response && error.response.data) {
            this.gamePlanSpecificationErrors = dot.object(error.response.data);
          }
          throw error;
        });
        apiCalls.push(promise);
      } 

      const updateTournamentPromise = updateTournament(this.tournament.id, {
        name: this.tournament.name,
        description: this.tournament.description,
        rules: this.tournament.rules,
      });
      apiCalls.push(updateTournamentPromise);

      Promise.all(apiCalls).then(() => {
        this.$router.back();
      }).catch(() => {
        const errorMessage = this.$t('tournament.error.cantEditTournament');
        this.showError(errorMessage);
      })
    },
    touchGamePlan() {
      this.gamePlanTouched = true;
      this.gamePlanSpecificationErrors = {};
    },
    gatherGamePlanSpec(tournamentId) {
      getGamePlanSpec(tournamentId).then(({ data })=> {
        this.gamePlanSpecification = data.stages;
        this.gamePlanTouched = false;
      })
    },
    ...mapActions({
      refreshTournaments: TournamentListActions.INITIALIZE_TOURNAMENTS,
      gatherTournamentInfo: TournamentActions.GatherTournamentInfo
    }),
  },
  watch: {
    fetchedTournament: {
      handler: function(tournament) {
        if (!this.tournament) {
          this.tournament = tournament;
        }
      },
      immediate: true
    }
  },
  mounted: function() {
    const tournamentId = this.$route.params.id;
    this.gatherTournamentInfo(tournamentId).then(data => {
      if (data.administratorId !== this.currentUserId) {
        this.$router.back();
      }
    }).catch(() => {
      this.showError(this.$t('tournament.error.notExisting'));
      this.$router.back();
    });

    this.gatherGamePlanSpec(tournamentId);
  },
  components: {
    TournamentEditToolbar,
    GamePlanEditor
  }
}
</script>

<style scoped>
.section {
  margin-bottom: 8px;
}

.section>h1 {
  padding: 8px 0;
}
</style>