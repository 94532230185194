<template>
  <v-input :value="value" class="group-row" :error-messages="errorMessages" :hide-details="hideDetails" :rules="[validateNameRequirement, validateStartingLargerThanAdvancing]">
    <v-text-field hide-details type="text" :value="value.name" @input="nameUpdated" class="editor-input group-name-input"></v-text-field>
    <number-input hide-details class="editor-input number-input" :value="value.starting" @input="startingUpdated" :min="value.advancing"/>
    <number-input hide-details class="editor-input number-input" :value="value.advancing" @input="advancingUpdated" :min="0" :max="value.starting"/>
  </v-input>
</template>

<script>
import { MoreAdvancingTeamsThanStarting } from '@/services/vuezlerApi/errorCodes/tournamentErrorCodes';
export default {
  name: 'group-editor',
  props: {
    value: Object,
    errors: Object
  },
  computed: {
    hideDetails: function() {
      return !this.errors && this.value.starting >= this.value.advancing && !!this.value.name;
    },
    errorMessages() {
      if (this.errors == null) return;

      if (this.errors.Name != null) {
        return this.errors.Name.map(code => `${this.$t('tournament.gamePlan.fields.name')}: ${this.$t(`errorCodes.${code}`)}`);
      }
      if (this.errors.Starting != null) {
        return this.errors.Starting.map(code => `${this.$t('tournament.gamePlan.fields.starting')}: ${this.$t(`errorCodes.${code}`)}`);
      }
      if (this.errors.Advancing != null) {
        return this.errors.Advancing.map(code => `${this.$t('tournament.gamePlan.fields.advancing')}: ${this.$t(`errorCodes.${code}`)}`);
      }

      return '';
    },
  },
  methods: {
    validateNameRequirement() {
       return !!this.value.name || this.$t("common.validation.required");
    },
    validateStartingLargerThanAdvancing(value) {
      if (value.starting >= value.advancing) {
        return true;
      } else {
        return this.$t(`errorCodes.${MoreAdvancingTeamsThanStarting}`);
      }
    },
    updateGroup(group) {
      this.$emit('input', group);
    },
    nameUpdated(updatedName) {
      var updatedGroup = { ...this.value };
      updatedGroup.name = updatedName;
      this.updateGroup(updatedGroup);
    },
    startingUpdated(updateEvent) {
      var updatedGroup = { ...this.value };
      updatedGroup.starting = updateEvent;
      this.updateGroup(updatedGroup);
    },
    advancingUpdated(updateEvent) {
      var updatedGroup = { ...this.value };
      updatedGroup.advancing = updateEvent;
      this.updateGroup(updatedGroup);
    }
  },
}
</script>

<style scoped>
.group-name-input {
  padding-top: 0;
  margin-top: 0;
}
.group-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}

.editor-input {
  padding: 0px 4px;
}

.number-input {
  max-width: 105px;
  flex: 1 0 auto;
}
</style>