<template>
  <v-container fill-height>
    <v-layout
      text-xs-center
      column
    >
      <v-spacer />

      <v-flex class="app-header">
        <div class="app-logo" />
      </v-flex>

      <v-flex>
        <user-auth class="flex"/>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import UserAuth from './UserAuth.vue';

export default {
  name: 'login',
  components: {
    UserAuth
  },
  computed: {
    pageTitle: function() {
      return this.$t('pageTitles.default');
    }
  }
}
</script>

<style scoped>
.flex{
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-header {
  margin-bottom: 25px;
}

.app-logo {
  width:  80%;
  height: 200px;

  background-image: url(../assets/logo.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

</style>