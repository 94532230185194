<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left" v-if="editable">#</th>
          <th class="text-left" >{{ $t('tournament.group.team') }}</th>
          <th class="text-left">{{ $t('tournament.group.won') }}</th>
          <th class="text-left">{{ $t('tournament.group.lost') }}</th>
          <th class="text-left">{{ $t('tournament.group.goalDifference') }}</th>
        </tr>
      </thead>
      <draggable v-if="editable" v-model="teamRanking" tag="tbody" @change="emitNewRanking"> 
        <!-- stop propagation of the touchstart event, to prevent pull to refresh while dragging. -->
        <tr v-for="(team, idx) of teamRanking" :key="idx" class="team" @touchstart.stop :class="{ 'advancing': idx < (group.advancingTeams || 1) }">
          <td>{{ team.rank+1 }}</td>
          <td :class="{ 'disabled': team.hasForfeited }">{{ team.team && team.team.name }}</td>
          <td class="outcomes">{{ team.wins }}</td>
          <td class="outcomes">{{ team.loss }}</td>
          <td class="outcomes">{{ team.goalDifference }}</td>
        </tr>
      </draggable>
      <tbody v-else>
        <tr v-for="(team, idx) in teamRanking" :key="idx" :class="{ 'advancing': idx < group.advancingTeams }">
          <td :class="{ 'disabled': team.hasForfeited }">{{ team.team && team.team.name }}</td>
          <td class="outcomes">{{ team.wins }}</td>
          <td class="outcomes">{{ team.loss }}</td>
          <td class="outcomes">{{ team.goalDifference }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import draggable from 'vuedraggable'

const CHANGE_EVENT = 'change';

export default {
  name: 'group-table',
  data: function() {
    return {
      teamRanking: []
    }
  },
  props: {
    group: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    participants: function() {
      return this.group.participants.reduce((result, participant) => {
        return {
          ...result,
          [participant.participantId]: participant
        };
      }, {});
    }
  },
  methods: {
    isFixtureEligible: function(fixture) {
      const hasFirstParticipantForfeited = 
        (this.participants[fixture.firstTeam] && 
        this.participants[fixture.firstTeam].forfeitTimestamp != null) ||
        false;

      const hasSecondParticipantForfeited = 
        (this.participants[fixture.secondTeam] && 
        this.participants[fixture.secondTeam].forfeitTimestamp != null) ||
        false;
      
      return !hasFirstParticipantForfeited && !hasSecondParticipantForfeited;
    },
    emitNewRanking: function() {
      this.$emit(CHANGE_EVENT, this.teamRanking);
    },
    createTeamRanking: function(group) {
      // create a object that maps team ids to their performance measures
      const teamPerformances = group.participants.reduce((prev, curr) => {
        prev[curr.participantId] = {
          team: curr.team,
          rank: curr.rank,
          wins: 0,
          loss: 0,
          goalDifference: 0,
          hasForfeited: curr.forfeitTimestamp != null,
        }
        return prev;
      }, {});

      for(const fixture of group.fixtures.filter(this.isFixtureEligible)) {
        if (fixture.firstScore != null && fixture.secondScore != null) {
          if (fixture.firstScore > fixture.secondScore) {
            teamPerformances[fixture.firstTeam].wins++;
            teamPerformances[fixture.secondTeam].loss++;
          } else if (fixture.firstScore < fixture.secondScore) {
            teamPerformances[fixture.firstTeam].loss++;
            teamPerformances[fixture.secondTeam].wins++;
          }
          teamPerformances[fixture.firstTeam].goalDifference += fixture.firstScore;
          teamPerformances[fixture.firstTeam].goalDifference -= fixture.secondScore;

          teamPerformances[fixture.secondTeam].goalDifference += fixture.secondScore;
          teamPerformances[fixture.secondTeam].goalDifference -= fixture.firstScore;
        }
      }
      
      return group.participants
        .map(rankedTeam => teamPerformances[rankedTeam.participantId]);
    }
  },
  watch: {
    group: {
      // Moved team ranking to the watchers because we need to manipulate the ranking in edit mode.
      // If it would still be a computed property, we wouldn't be able to do that.
      handler: function(group) {
        this.teamRanking = this.createTeamRanking(group);
        this.emitNewRanking();
      },
      immediate: true
    }
  },
  components: {
    draggable
  }
}
</script>

<style scoped>
.advancing {
  background-color: var(--v-grey-lighten2);
}

.disabled {
  text-decoration: line-through;
}
</style>