<template>
  <v-container fill-height grid-list-lg>
    <v-form v-model="valid" class="form">
      <v-layout column fill-height>
        <v-flex class="profile" shrink>
          <div class="profile-image-container" @click="$router.push({ name: 'avatarSelection' })">
            <div class="profile-image-shader"></div>
            <v-avatar size="100">
              <v-img :src="profileImage" class="profile-image"></v-img>
            </v-avatar>
            <v-icon left class="edit-icon" dark>edit</v-icon>
          </div>
        </v-flex>
        <v-flex grow>
          <vue-content-loading :width="300" :height="136" v-if="isLoadingSettings">
            <rect x="0" y="0" rx="3" ry="3" width="300" height="48" />
            <rect x="0" y="68" rx="3" ry="3" width="300" height="48" />
          </vue-content-loading>
          <div v-else>
            <v-text-field @keydown.space.prevent :label="$t('settings.name')" v-model="editedData.name" :rules="userNameRules"></v-text-field>
            <v-text-field :label="$t('settings.fullName')" v-model="editedData.fullName" :rules="fullNameRules"></v-text-field>
          </div>
        </v-flex>
      </v-layout>
    </v-form>
    <action-footer>
      <v-btn :disabled="!valid || !isDirty" :loading="isUpdatingSettings"
        block color="primary"
        @click="updateSettings()">
        {{$t('common.save')}}
      </v-btn>
    </action-footer>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { GETTERS as AuthGetters } from '../../../vuex/auth.module';
import { getPlayer, updatePlayer } from '../services/playerService';
import { required, maxLength } from '../../../logic/common/inputValidationRules';
import { playerDataMixin } from '../mixins/playerDataMixin';
import { shellInteractorMixin } from '../mixins/shellInteractorMixin';
import ActionFooter from '../components/ActionFooter';
import VueContentLoading from 'vue-content-loading';

export default {
  name: 'settings',
  mixins: [playerDataMixin, shellInteractorMixin],
  data: function() {
    return {
      valid: false,
      editedData: {
        name: '',
        fullName: ''
      },
      defaultData: {
        name: '',
        fullName: ''
      },
      isLoadingSettings: false,
      isUpdatingSettings: false,
      userNameRules: [required.bind(this), maxLength(15).bind(this), this.noWhitespace],
      fullNameRules: [maxLength(50).bind(this)]
    }
  },
  computed: {
    ...mapGetters({
      playerId: AuthGetters.USER_ID
    }),
    isDirty: function() {
      return this.editedData.name !== this.defaultData.name || this.editedData.fullName !== this.defaultData.fullName;
    }
  },
  methods: {
    noWhitespace(value) {
      return /^\S+$/.test(value) || this.$t('settings.validation.noWhitespace');
    },
    updateSettings: function() {
      if (!this.valid) return;

      this.isUpdatingSettings = true;
      updatePlayer(this.playerId, this.editedData).then(() => {
        this.isUpdatingSettings = false;
        this.defaultData = this.copyPlayer(this.editedData);
      }).catch(() => {
        this.isUpdatingSettings = false;
        this.showError(this.$t('settings.errors.cantSaveProfile'));
      });
    },
    copyPlayer: function(origin) {
      const { name, fullName } = origin;
      return {
        name,
        fullName
      };
    },
    fetchPlayer: function() {
      this.isLoadingSettings = true;
      getPlayer(this.playerId).then(({data}) => {
        this.editedData = this.copyPlayer(data);
        this.defaultData = this.copyPlayer(data);

        this.isLoadingSettings = false;
      }).catch(() => {
        this.isLoadingSettings = false;
      })
    },
  },
  mounted: function() {
    this.setTitle({ title: this.$t('shell.drawer.settings') });
    this.fetchPlayer();
  },
  destroyed: function() {
    this.resetTitle();
  },
  components: {
    VueContentLoading,
    ActionFooter,
  },
}
</script>

<style scoped>
.form {
  height: 100%;
  width: 100%;
}

.profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.settings * :not(:last-child) {
  padding-bottom: 8px;
}

.edit-icon {
  position: absolute;
  background-color: var(--v-primary-base);
  border-radius: 50%;
  padding: 4px;
  right: 0px;
  bottom: 0px;
  margin-right: 0px;
}

.profile-image {
  border: 2px solid var(--v-primary-base);
  background-color: white;
}

.profile-image-container {
  position: relative;
}

.profile-image-shader {
   position: absolute;
   right: -10px;
   top: -10px;
   width: 120px;
   height: 120px;

   background-image: radial-gradient(var(--v-primary-lighten1) 50%, rgba(255,255,255,0) 70%);
 }

 .user-settings {
   position: relative;
 }
</style>
