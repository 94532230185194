<template>
  <component :is="stageComponent" v-bind="$props" v-on="$listeners"/>
</template>

<script>
import GroupStageEditor from '@tournament/components/GroupStageEditor';
import KnockoutStageEditor from '@tournament/components/KnockoutStageEditor';

export default {
  name: 'stage-editor',
  props: {
    value: Object,
    errors: Object
  },
  computed: {
    stageComponent() {
      return this.value.type === 'groupstage' ? GroupStageEditor :
        this.value.type === 'knockoutstage' ? KnockoutStageEditor : null
    }
  }
}
</script>