export function getStageName(matchCount) {
  const stageTranslationKey = `tournament.stages.${matchCount}`;
  
  //bind the vuei18n required functions to the component instance context
  const $te = this.$te.bind(this);
  const $t = this.$t.bind(this);
  
  if ($te(stageTranslationKey)) {
    return $t(stageTranslationKey)
  } else {
    return $t('tournament.stages.others', { matchCount })
  }
}

export function createStageNameMapping(gamePlan) {
  const self = this;
  return gamePlan.stages
    .map(stage => getStageName.call(self, stage.matches.length));
}