<template>
  <v-container v-if="isGroupFetched" fill-height >
    <v-layout column>
      <group-player-search />
      <v-flex xs12>
        <v-list subheader style="width: 100%" class="background base">
          <v-subheader class="title">{{ $t("group.currentRanking") }}</v-subheader>
          <v-list-item
            v-for="(player, index) in orderedMembers"
            :key="player.id">

            <span class="place" :class="{ 'top-3': index < 3 }">{{index+1}}.</span>

            <v-list-item-avatar class="mr-2">
                <avatar :src="player.profileImage || defaultImage" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="player.name"></v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <span>{{ player.currentElo }}</span>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ACTIONS } from '../vuex/group.module';
import { ACTIONS as ShellActions } from '../vuex/shell.module';
import GroupPlayerSearch from '../components/GroupPlayerSearch';
import Avatar from '../modules/core/components/Avatar';
import * as _ from 'lodash';

export default {
  components: {
    GroupPlayerSearch,
    Avatar
  },
  data: () => ({
    defaultImage: require('@/assets/images/default-avatar.png')
  }),
  computed: {
    pageTitle: function() {
      return this.$t('pageTitles.default');
    },
    isGroupFetched() {
      return this.currentGroup != null;
    },
    ...mapState({
      currentGroup: state => state.group.currentGroup,
      members: state => state.group.members
    }),
    orderedMembers: function() {
      return _.orderBy(this.members, member => member.currentElo, 'desc');
    }
  },
  methods: {
    ...mapActions({
      fetchGroup: ACTIONS.FETCH_GROUP,
      setTitle: ShellActions.SET_TITLE,
      resetTitle: ShellActions.RESET_TITLE,
    })
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler: function(groupId) {
        this.fetchGroup({
          groupId,
          errorCallback: () => { this.$router.replace('/') }
        });
      }
    },
    'currentGroup': {
      immediate: true,
      handler: function(groupInfo) {
        if (groupInfo) {
          this.setTitle({ title: this.$t('group.group'), subtitle: groupInfo.name })
        }
      }
    }
  },
  destroyed: function() {
    this.resetTitle();
  } 
}
</script>

<style>
.place {
  font-weight: bold;
  padding-right: 15px;
  font-size: large;
}

.top-3 {
  font-size: x-large;
}
</style>
