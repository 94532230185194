








import Vue, { PropType } from 'vue'

type ErrorStateType = 'broken' | 'missing';

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const iconMap: { [key in ErrorStateType]: string } = {
  'broken': require('@/assets/images/states/broken.png'),
  'missing': require('@/assets/images/states/missing.png')
}

export default Vue.extend({
  name: 'error-state',
  props: {
    type: String as PropType<ErrorStateType | undefined>,
    title: String,
    description: String
  },
  computed: {
    stateIcon: function(): string {
      const definitType = this.type || 'broken';
      return iconMap[definitType];
    }
  }
})
