<template>
  <v-flex shrink>
    <v-snackbar top v-model="showToolTip" :timeout="2000">
      <slot name="warning" />
    </v-snackbar>
    <v-btn :aria-label="ariaLabel" dark :style="transitionStyle" block class="lp-btn" v-bind:class="{ clicked: clickTimestamp != null }"  @mousedown="clickInitiated"  @touchstart="clickInitiated" @mouseup="clickEnded" @touchend="clickEnded">
      <slot />
    </v-btn>
  </v-flex>
</template>

<script>
export default {
  name: 'long-press-button',
  props: {
    duration: Number,
    ariaLabel: String
  },
  data: () => ({
    clickTimestamp: null,
    showToolTip: false
  }),
  computed: {
    transitionStyle: function () {
      return `transition: all ${this.duration}ms`
    }
  },
  methods: {
    clickInitiated: function() {
      this.clickTimestamp = Date.now();
      this.showToolTip = false;
      setTimeout(() => this.clickEnded(), this.duration);
    },
    clickEnded: function() {
      if (this.clickTimestamp == null) return;

      const clickDuration = Date.now() - this.clickTimestamp;
      if (clickDuration >= this.duration) {
        this.$emit('click');
      } else {
        this.showToolTip = true;
      }
      this.clickTimestamp = null;
    }
  }
}
</script>

<style scoped>
.lp-btn {
  transition: all 1s;

  background: linear-gradient(to right, var(--v-primary-lighten1) 50%, var(--v-primary-base) 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  border-color: var(--v-primary-base) !important;
}

.lp-btn.clicked {
  background-position: left bottom;
  border-color: var(--v-primary-lighten1) !important;
}
</style>
