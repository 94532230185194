<template>
<v-container fill-height class="overview">
    <section class="text-center mb-4">
      <h1>{{ title }}</h1>
      <h2>{{ subtitle }}</h2>
    </section>
  
    <section class="player-list mb-4">
    <player-list v-if='players' :players='players' showEloDelta/>
    </section>

    <h2>{{ $t('matchSummary.result') }}:</h2>
    <p class="match-result">{{ match.home.score }} : {{ match.away.score }}</p>

    <h2>Your ELO is:</h2>
    <p class="elo">
      {{ currentPlayer.eloRanking.current }}
      <span class="white--text"
           :class="playerEloDelta > 0 ? 'success' : 'failure'">
        {{ playerEloDelta }}
      </span>
    </p>
  
  <v-spacer />

  <section class="actions">
    <v-btn
      class="mb-2"
      block outlined color="primary"
      v-on:click.prevent="playAirhorn"
    >
      {{ $t("matchSummary.hornSound") }}
    </v-btn>
    
    <v-btn v-if="match.tournamentId"
      class="mb-2" 
      block outlined color="primary"
      :to="{ name: 'tournament', params: { id: match.tournamentId }}"
    >
      {{ $t("matchSummary.goToTournament")}}
    </v-btn>
    
    <v-btn
      block light color="primary"
      @click="goToHome"
    >
      {{ $t("common.returnHome") }}
    </v-btn>
  </section>
</v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { getPlayersFromMatch, hasPlayerWon, getPlayerFromMatch } from '../logic/matchHelper';
import PlayerList from '../components/PlayerList.vue';
import { ACTIONS as OverviewActions } from '../vuex/matchSummary.module';
import { GETTERS as AuthGetters } from '../vuex/auth.module';

const AIRHORN_SOUND = require('../assets/sounds/airhorn.mp3');

export default {
  name: 'overview',
  computed: {
    ...mapState({
      match: state => state.matchSummary.match,
    }),
    ...mapGetters({
      userId: AuthGetters.USER_ID
    }),
    players: function() {
     return getPlayersFromMatch(this.match);
    },
    currentPlayer: function() {
      if (!this.match || !this.userId) return undefined;
      return getPlayerFromMatch(this.match, this.userId);
    },
    playerEloDelta: function() {
      if (!this.currentPlayer || !this.currentPlayer.eloRanking) return undefined;
      const eloDelta = this.currentPlayer.eloRanking.current - this.currentPlayer.eloRanking.previous;
      return eloDelta >= 0 ? `+${eloDelta}` : eloDelta;
    },
    title: function() {
      if (!this.match) return undefined;
      return hasPlayerWon(this.match, this.userId) ?
        this.$t("match.winningTitle") :
        this.$t("match.losingTitle");
    },
    subtitle: function() {
      if (!this.match) return undefined;
      return hasPlayerWon(this.match, this.userId) ?
        this.$t("match.winningSubtitle") :
        this.$t("match.losingSubtitle")
    }
  },
  components: {
    PlayerList
  },
  methods: {
    playAirhorn: function () {
      var audio = new Audio(AIRHORN_SOUND);
      audio.play();
    },
    goToHome: function() {
      this.$router.replace('/')
    }
  },
  destroyed() {
    this.$store.dispatch(OverviewActions.RESET_SUMMARY);
  }
}
</script>

<style scoped>
.overview {
  display: flex;
  flex-direction: column;
  align-content: stretch;
}

.success {
  background-color: green;
  padding: 2px 5px;
}

.failure {
  background-color: red;
  padding: 2px 5px;
}

.match-result {
  font-size: 30px;
}

.player-list {
  width: 100%;
}

.actions {
  width: 100%;
}
</style>
