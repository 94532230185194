<template>
  <v-container fill-height>
    <v-layout column>
      <v-flex shrink class="uploadSection">
        <avatar-upload @uploaded="onAvatarUpload"></avatar-upload>
      </v-flex>
      <v-flex class="images" >
        <avatar-preview v-for="image in images" :key="image.id"
          :src="image.location"
          :class="{ 'selected': selectedImage === image.id }"
          @click="selectImage(image.id)"
          ></avatar-preview>
        <vue-content-loading v-for="i in isLoadingImages ? 4 : 0" :key="i" :width="100" :height="100" class="skeletton-image">
          <rect x="0" y="0" width="100" height="100" rx="4" ry="4" />
        </vue-content-loading>
      </v-flex>
    </v-layout>
    <action-footer>
      <v-btn color="primary" block :disabled="selectedImage === null"
        @click="setAvatar"
        :loading="isSettingAvatar"
      >{{ $t('settings.setAvatar') }}</v-btn>
    </action-footer>
  </v-container>
</template>

<script>
import { getAvatars, setAvatar } from '../services/playerService';
import { GETTERS } from '../../../vuex/auth.module';
import { mapGetters, mapActions } from 'vuex';
import { ACTIONS as PlayerActions } from '../../../vuex/playerModule';
import { ACTIONS as ShellActions } from '../../../vuex/shell.module';
import { playerDataMixin } from '../mixins/playerDataMixin';
import VueContentLoading from 'vue-content-loading';
import AvatarPreview from '../components/AvatarPreview';
import AvatarUpload from '../components/AvatarUpload';
import ActionFooter from '../components/ActionFooter';

export default {
  name: 'avatar-selection',
  mixins: [playerDataMixin],
  data: () => ({
    images: [],
    selectedImage: null,
    isLoadingImages: true,
    isSettingAvatar: false
  }),
  computed: {
    ...mapGetters({
      playerId: GETTERS.USER_ID
    })
  },
  methods: {
    ...mapActions({
      updateProfileImage: PlayerActions.UPDATE_PROFILE_IMAGE,
      showError: ShellActions.SHOW_ERROR,
      setTitle: ShellActions.SET_TITLE,
      resetTitle: ShellActions.RESET_TITLE
    }),
    selectImage: function(imageId) {
      this.selectedImage = this.selectedImage === imageId ? null : imageId;
    },
    onAvatarUpload: function(avatar) {
      this.images.unshift(avatar);
      this.selectedImage = avatar.id;
    },
    setAvatar: function() {
      this.isSettingAvatar = true;
      setAvatar(this.playerId, this.selectedImage).then(() => {
        const image = this.images.find(image => image.id === this.selectedImage);
        this.updateProfileImage(image.location);
        this.isSettingAvatar = false;
        this.$router.back();
      }).catch(() => {
        this.isSettingAvatar = false;
        this.showError(this.$t('settings.errors.settingImage'))
      })
    }
  },
  mounted: function() {
    this.isLoadingImages = true;
    getAvatars(this.playerId).then(({ data }) => {
      this.images = data.sort((first, second) => second.uploadDate - first.uploadDate);
      this.isLoadingImages = false;
      const currentImage = data.find(image => image.location === this.profileImage)
      if (currentImage && !this.selectedImage) this.selectedImage = currentImage.id;
    });
    this.setTitle({ title: this.$t('settings.avatarTitle') })
  },
  destroyed: function() {
    this.resetTitle();
  },
  components: {
    VueContentLoading,
    AvatarPreview,
    AvatarUpload,
    ActionFooter
  }
}
</script>

<style scoped>
.images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
}

.uploadSection {
  margin-bottom: 8px;
}

.selected {
  border: solid 4px var(--v-primary-base);
}

.skeletton-image {
  width: 100px;
  height: 100px;
  margin: 4px;
  border: solid 4px transparent;
  flex: 0 1 auto;
  border-radius: 8px;
}
</style>

