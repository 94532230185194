<template>
<p2r-page @refresh="fetchTournaments">
  <tournament-list-toolbar v-model="selectedTab" slot="toolbar"></tournament-list-toolbar>
  <div v-if="isLoading" class="loading my-2">
    <v-progress-circular indeterminate color="primary" />
  </div>
  <div v-else-if="errorLoading" class="error-message">
    <error-message @refresh="initializeTournaments"/>
  </div>
  <section class="fill-height" @touchstart.capture="reDispatchTouchEvent" @touchend.capture="reDispatchTouchEvent">
    <v-tabs-items class="fill-height" v-model="selectedTab">
      <v-tab-item v-for="tab in tournamentTabs" :key="tab.tournamentState">
        <v-list two-line>
          <tournament-list-item
            v-for="tournament of tab.tournaments"
            :key="tournament.id"
            :tournament="tournament"
            :tournamentType="tab.tournamentState"
          />
        </v-list>
      </v-tab-item>
    </v-tabs-items>
  </section>

  <v-btn fixed fab bottom right class="secondary" @click="goToQrScanner">
    <v-icon>photo_camera</v-icon>
  </v-btn>
</p2r-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ACTIONS as TournamentActions } from '@tournament/states/tournament-list';
import TournamentListItem from '@tournament/components/TournamentListItem';
import ErrorMessage from '@/components/ErrorMessage';
import { ACTIONS as ShellActions } from '@/vuex/shell.module';
import { shellInteractorMixin } from '@core/mixins/shellInteractorMixin';
import TournamentListToolbar from '@tournament/toolbars/TournamentListToolbar';
import { TournamentState } from '../../../logic/tournaments/tournamentState';

export default {
  name: 'tournament-list',
  mixins: [shellInteractorMixin],
  data: function() {
    return {
      selectedTab: TournamentState.Active,
      isLoading: false,
    }
  },
  components: {
    TournamentListItem,
    ErrorMessage,
    TournamentListToolbar,
  },
  methods: {
    ...mapActions({
      initializeTournaments: TournamentActions.INITIALIZE_TOURNAMENTS,
      showError: ShellActions.SHOW_ERROR
    }),
    navigateToTournamentPage: function(id) {
      this.$router.push({ name: 'tournament', params: { id }});
    },
    goToQrScanner: function() {
      this.$router.push('/qrScanner');
    },
    fetchTournaments: function() {
      this.isLoading = true;
      this.initializeTournaments(true).then(() => {
        this.isLoading = false;
      }, () => {
        this.isLoading = false;
      });
    },
    reDispatchTouchEvent(ev) {
      // Vuetifys v-tabs-item component stops propagating touch events (like touchstart and touchend).
      // The P2R library needs those events to provide it's functionality though. What we're doing here is therefore taking the event
      // in the capture phase (before it reaches the vuetify component), creating a copy of it and redispatch it.
      const newEvent = new event.constructor(ev.type, { touches: ev.touches })
      // Redispatch is required from the parent because otherwise it'll retrigger itself.
      ev.currentTarget.parentElement.dispatchEvent(newEvent);
    },
  },
  computed: {
    pageTitle: function() {
      return this.$t('pageTitles.default');
    },
    tournamentTabs: function() {
      return [
        {
          tournamentState: TournamentState.Active,
          tournaments: this.tournaments[TournamentState.Active]
        },
        {
          tournamentState: TournamentState.Inactive,
          tournaments: this.tournaments[TournamentState.Inactive]
        },
        {
          tournamentState: TournamentState.Finished,
          tournaments: this.tournaments[TournamentState.Finished]
        },
      ];
    },
    ...mapState({
      tournaments: state => state.tournamentList.tournaments,
      errorLoading: state => state.tournamentList.errorLoading,
    }),
  },
  mounted: function() {
    this.setTitle({ title: this.$t('shell.drawer.tournaments')});
    this.fetchTournaments();
  },
  destroyed: function() {
    this.resetTitle();
  }
}
</script>

<style scoped>
.error-message {
  max-width: 600px !important;
}

.fill-height {
  height: 100%;
}

.loading {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>
