import firebase from "firebase/app";

export const authenticationGuard = (to, from, next) => {
  const unsubscribe = firebase.auth().onAuthStateChanged(user => {
    unsubscribe();
    if (user) {
      next();
    } else {
      const loginRoute = {
        name: 'login',
        query: { redirect: to.fullPath }
      };
      next(loginRoute);
    }
  })
}