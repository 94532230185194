import { ApplicationInsights } from '@microsoft/applicationinsights-web'

function install(Vue, options) {
  var config = { ...options.config };
  config.instrumentationKey = options.instrumentationKey;
  config.enableCorsCorrelation = true;
  config.correlationHeaderExcludedDomains = [ "googleapis.com" ]

  Vue.appInsights = new ApplicationInsights({ config });
  Vue.appInsights.loadAppInsights();
  // Vue.appInsights.trackPageView();

  Vue.config.errorHandler = function(err) {
    Vue.appInsights.trackException({ exception: err });
    throw err;
  }

  Object.defineProperty(Vue.prototype, '$appInsights', {
    get: () => Vue.appInsights
  })

  options.router.beforeEach((route, from, next) => {
    const name = route.name;
    Vue.appInsights.startTrackPage(name)
    next()
  })

  options.router.afterEach(route => {
    const name = route.name;
    const url = window.location.href;
    Vue.appInsights.stopTrackPage(name, url);
    Vue.appInsights.flush();
  })
}

export default install;