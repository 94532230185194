import firebase from 'firebase/app';
import 'firebaseui/dist/firebaseui.css'
import { ACTIONS as AuthActions } from '@/vuex/auth.module';

/**
 * Register a handler for the authentication state.
 * @param store the store providing the according auth actions.
 */
export function registerAuthStateHandler(store) {
  return new Promise((resolve) => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        user.getIdToken().then(token => {
          store.dispatch(AuthActions.SET_AUTH_TOKEN, token);
          resolve();
        });
      } else {
        store.dispatch(AuthActions.SIGN_OUT);
        resolve();
      }
    })
  });
}