<template>
    <v-form v-model="isFormValid">
        <v-container>
            <v-layout column>
                <v-flex>
                    <h1 class="title">{{ $t('auth.createAccount') }}</h1>
                </v-flex>

                <v-flex>
                    <v-text-field v-model="email" name="email" required
                        :rules="[rules.required, rules.email]"
                        :label="$t('auth.email')">
                    </v-text-field>
                </v-flex>

                <v-flex>
                    <v-text-field v-model="password" name="password" counter
                        :type="show ? 'text' : 'password'" :append-icon="show ? 'visibility' : 'visibility_off'"
                        :rules="[rules.required, rules.min]"
                        :label="$t('auth.password')" :hint="$t('auth.passwordHint')"
                        @click:append="show = !show">
                    </v-text-field>
                    <v-text-field v-if="!show" name="password-repeat" counter
                        type="password"
                        :rules="[rules.required, rules.equalsPassword]"
                        :label="$t('auth.repeatPassword')" :hint="$t('auth.passwordHint')">
                    </v-text-field>
                </v-flex>

                <v-flex v-if="creationError" class="error-message">
                    {{ $t(creationError) }}
                </v-flex>

                <v-flex class="action-bar">
                    <v-btn text @click="cancel">{{ $t('common.cancel') }}</v-btn>
                    <v-btn color="success" @click="submit" :disabled="!isFormValid">{{ $t('common.submit') }}</v-btn>
                </v-flex>
            </v-layout>
        </v-container>
    </v-form>
</template>

<script>
import firebase from 'firebase/app';
import { getPlayer } from '../services/vuezlerApi/player.service';
import { mapActions } from 'vuex';
import { ACTIONS as ShellActions } from '../vuex/shell.module';

export default {
    name: 'SignUp',
    data: function() {
        return {
            isFormValid: false,
            show: false,
            creationError: undefined,
            email: '',
            password: '',
            rules: {
                required: value => !!value || this.$i18n.t('common.validation.required'),
                min: v => v.length >= 8 || this.$i18n.t('common.validation.passwordLength'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$i18n.t('common.validation.email');
                },
                equalsPassword: password => password == this.password || this.$i18n.t('common.validation.passwordsMustMatch')
            }
        }
    },
    computed: {
        pageTitle: function() {
        return this.$t('pageTitles.default');
        }
    },
    methods: {
        ...mapActions({
            showError: ShellActions.SHOW_ERROR
        }),
        cancel: function() {
            this.$router.back();
        },
        submit: function() {
            if (!this.isFormValid) return;
            firebase.auth().createUserWithEmailAndPassword(this.email, this.password).then(({ user }) => {
                getPlayer(user.uid).then(() => {
                    this.$router.replace('/');
                }).catch(() => {
                    this.showError(this.$t('auth.creationError'))
                    this.$router.replace('/');
                })
            }).catch(error => {
                if (error.code === 'auth/weak-password') {
                    this.creationError = 'auth.error.weakPassword'
                } else {
                    this.creationError = 'auth.error.general'
                }
            });
        }
    }
}
</script>

<style scoped>
.action-bar {
    margin-left: auto;
}

.error-message {
    border: 1px solid var(--v-error-base);
    background-color: var(--v-error-lighten4);
    border-radius: 8px;
}
</style>
