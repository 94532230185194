







import Vue, { PropType } from 'vue'
import { Statistic } from '../../models/statistic'
import { StackedBarChart, StackedBarChartData } from '../Charts/StackedBarChart'

export default Vue.extend({
  name: 'constellation-statistics',
  props: {
    value: {
      type: Object as PropType<Statistic>,
      required: true
    }
  },
  computed: {
    matchStatistic: function(): StackedBarChartData {
      return [
        {
          label: 'won',
          value: this.value.matchesWon,
          color: 'var(--v-secondary-base)'
        },
        {
          label: 'drawn',
          value: this.value.matchesDrawn,
          color: 'var(--v-warning-base)'
        },
        {
          label: 'lost',
          value: this.value.matchesLost,
          color: 'var(--v-error-base)'
        }
      ]
    },
    goalStatistic: function(): StackedBarChartData {
      return [
        {
          label: 'scored',
          value: this.value.goalsScored,
          color: 'var(--v-secondary-base)'
        },
        {
          label: 'received',
          value: this.value.goalsReceived,
          color: 'var(--v-error-base)'
        },
      ]
    }
  },
  components: {
    StackedBarChart
  }
})
