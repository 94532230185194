import Vue from 'vue';
import * as Components from '@shared/components';

export function registerGlobalComponents() {
  Vue.component(Components.Page.name, Components.Page);
  Vue.component(Components.PullToRefreshPage.name, Components.PullToRefreshPage);
  Vue.component(Components.NumberInput.name, Components.NumberInput);
  Vue.component(Components.SvgIcon.name, Components.SvgIcon);
  Vue.component(Components.BouncingDots.name, Components.BouncingDots);
  Vue.component(Components.ProgressSpinner.name, Components.ProgressSpinner);
}