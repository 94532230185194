<template>
  <v-card>
    <v-card-title class="title">{{ $t("tournament.joinTeam") }}</v-card-title>
    <v-card-text v-if="error">
      <p>{{ $t(error) }}</p>
    </v-card-text>
    <v-card-text v-else>
      <p>{{ $t("tournament.joinTeamText") }}</p>
      <p class="team-name">{{ teamName }}</p>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <span v-if="error">
        <v-btn text @click="$emit('close')">{{ $t("common.close") }}</v-btn>
      </span>
      <span v-else>
        <v-btn text @click="$emit('close')">{{ $t("common.no") }}</v-btn>
        <v-btn text @click="joinTeam" :loading="isJoining">{{ $t("tournament.joinTeam") }}</v-btn>
      </span>
    </v-card-actions>
  </v-card>
</template>

<script lang="js">
import { getTeam } from '@/services/vuezlerApi/tournamentService';
import { ACTIONS as ShellActions } from '@/vuex/shell.module';
import { mapActions } from 'vuex';
import { joinTeam } from "@/services/vuezlerApi/tournamentService";

export default {
  name: 'join-team-dialog',
  data: () => ({
    team: undefined,
    error: undefined,
    isJoining: false
  }),
  props: {
    tournamentId: String,
    teamId: String,
  },
  computed: {
    teamName: function() {
      return (this.team && this.team.info.name) || this.teamId
    }
  },
  mounted: function() {
    getTeam(this.tournamentId, this.teamId)
      .then(({data}) => {
        this.team = data;
        this.error = undefined;
      })
      .catch(() => {
        this.error = 'tournament.teamDoesntExist'
      });
  },
  methods: {
    joinTeam: function() {
      this.isJoining = true;

      joinTeam(this.tournamentId, this.teamId).then(() => {
        this.$emit('joined');
        this.isJoining = false;
      }).catch(() => {
        this.showError(this.$t('tournament.error.joinTeam'));
        this.isJoining = false;
      });

    },
    ...mapActions({
      showError: ShellActions.SHOW_ERROR
    })
  }
}
</script>
