import StoryblokClient from 'storyblok-js-client'

export const storyblokMixin = {
  data() {
    return {
      story: {
        content: {
          body: []
        }
      },
      storyapi: new StoryblokClient({
        accessToken: window.$config.storyblokToken
      })
    }
  },
  props: ['lang'],
  methods: {
    getStory(slug, version, lang) {
      let url = 'cdn/stories/';
      if (lang) {
        url += `${lang}/`
      }
      url += slug;

      this.storyapi.get(url, {
        version: version
      })
      .then((response) => {
        this.story = response.data.story
      })
      .catch(() => {
      })
    },
    initialize(blokName) {
      window.storyblok.init({
        accessToken: window.$config.storyblokToken
      })
      window.storyblok.on('change', () => {
        this.getStory(blokName, 'draft', this.lang)
      })
      window.storyblok.pingEditor(() => {
        if (window.storyblok.isInEditor() || window.$config.env !== 'production') {
          this.getStory(blokName, 'draft', this.lang)
        } else {
          this.getStory(blokName, 'published', this.lang)
        }
      })
    }
  }
}