<template>
<p class="text-area mt-4 grey--text text--darken-3 body-1">{{ value }}</p>
</template>

<script>
export default {
  name: 'tournament-description',
  props: {
    value: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
  .text-area {
    white-space: pre-line;
    margin: 0px;
  }
</style>