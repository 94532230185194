<template>
  <component :is="component"></component>
</template>

<script>
import firebase from 'firebase/app';
import LandingPage from './LandingPage';
import Dashboard from './Dashboard';

export default {
  data: () => ({
    component: LandingPage
  }),
  mounted: function() {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      unsubscribe();
      if (user) {
        this.component = Dashboard
      } else {
        this.component = LandingPage
      }
    })
    }
}
</script>