<template>
  <div class="avatar-list">
    <avatar
      border="2px solid white"
      v-for="avatar in avatars"
      :key="avatar"
      :src="avatar"
      :size="40">
    </avatar>
  </div>
</template>

<script>
import Avatar from '@core/components/Avatar';

export default {
  name: 'multi-avatar',
  props: ['avatars'],
  components: {
    Avatar
  }
}
</script>

<style scoped>
.avatar-list {
  display: flex;
  flex-direction: row;
}

.avatar-list *:not(:first-child) {
  margin-left: -20px;
}
</style>