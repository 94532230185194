import { getTournaments } from "../../../services/vuezlerApi/tournamentService";
import { TournamentState } from "@/logic/tournaments/tournamentState";

export const ACTIONS = {
  INITIALIZE_TOURNAMENTS: '[🏆 TournamentList] Initialize Tournaments',
}

const MUTATIONS = {
  REPLACE_TOURNAMENTS: '[🏆 TournamentList] Replace Tournaments',
  SET_ERROR_STATE: '[🏆 TournamentList] Set Error State',
}

const state = {
  isInitialized: false,
  tournaments: {
    [TournamentState.Active]: [],
    [TournamentState.Inactive]: [],
    [TournamentState.Finished]: [],
  },
  errorLoading: false,
}

const actions = {
  [ACTIONS.INITIALIZE_TOURNAMENTS]: ({commit, state}, isForceRefresh) => {
    if (state.isInitialized && !isForceRefresh) return;

    commit(MUTATIONS.SET_ERROR_STATE, false);
    return getTournaments().then(({ data }) => {
      commit(MUTATIONS.SET_ERROR_STATE, false);
      commit(MUTATIONS.REPLACE_TOURNAMENTS, data);
    }).catch(() => {
      commit(MUTATIONS.SET_ERROR_STATE, true);
    })
  },
}

const mutations = {
  [MUTATIONS.REPLACE_TOURNAMENTS]: (state, data) => {
    state.isInitialized = true;
    state.tournaments[TournamentState.Active] = [];
    state.tournaments[TournamentState.Inactive] = [];
    state.tournaments[TournamentState.Finished] = [];

    data.forEach(tournament => {
      if (tournament.finishDate != null) {
        state.tournaments[TournamentState.Finished].push(tournament);
      } else if (tournament.startDate != null) {
        state.tournaments[TournamentState.Active].push(tournament);
      } else {
        state.tournaments[TournamentState.Inactive].push(tournament);
      }
    })

    state.tournaments[TournamentState.Inactive].sort(dateComparerDesc(tournament => tournament.creationDate));
    state.tournaments[TournamentState.Active].sort(dateComparerDesc(tournament => tournament.startDate));
    state.tournaments[TournamentState.Finished].sort(dateComparerDesc(tournament => tournament.finishDate));
  },
  [MUTATIONS.SET_ERROR_STATE]: (state, value) => {
    state.errorLoading = value;
  },
}

function dateComparerDesc (dateExtractor) {
  return function compare (first, second) {
    return dateExtractor(second)-dateExtractor(first);
  }
}

export default {
  state,
  actions,
  mutations
}