







import { GETTERS } from '@/vuex/auth.module'
import Vue, { PropType } from 'vue'
import { mapGetters } from 'vuex'

type TeamData = {
    key: string,
    profile: {
        name: string;
        profileImage: string;
    } | 'any' | null | undefined
}

type SideText = {
    nrOfKnowns: number;
    nrOfAnys: number;
    players: string[]
}

export default Vue.extend({
    props: {
        teamOne: Array as PropType<TeamData[]>,
        teamTwo: Array as PropType<TeamData[]>
    },
    computed: {
        ...mapGetters({ userId: GETTERS.USER_ID }),
        yourTeam: function(): TeamData[] {
            return this.teamOne.some(player => player.key == this.userId) ? this.teamOne : this.teamTwo
        },
        otherTeam: function(): TeamData[] {
            return this.teamOne.some(player => player.key == this.userId) ? this.teamTwo : this.teamOne
        },
        homeText: function(): SideText {
            const partnerProfile = this.yourTeam.filter(team => team.key != this.userId)[0]?.profile;

            return {
                nrOfKnowns: partnerProfile != 'any' && partnerProfile != null ? 1 : 0,
                nrOfAnys: partnerProfile == 'any' ? 1 : 0,
                players: [ partnerProfile?.['name'] ]
            }
        },
        awayText: function(): SideText {
            return {
                nrOfKnowns: this.otherTeam.filter(member => member.profile != 'any' && member.profile != null).length,
                nrOfAnys: this.otherTeam.filter(member => member.profile == 'any').length,
                players: this.otherTeam.map(member => member.profile?.['name'])
            }
        }
    }
})
