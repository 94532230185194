<template>
<v-container>
  <v-row justify="start">
    <v-col>
      <tournament-headline :name="tournament.name" :state="activeState" :tournamentId="tournament.id" shareable />
      <tournament-description v-if="tournament.description" :value="tournament.description" />
    </v-col>
  </v-row>

  <v-row v-if="isAdministrator && hasIssues" class="issues">
    <v-col>
      <issue-banner @viewIssues="goToIssuePage"></issue-banner>
    </v-col>
  </v-row>

  <v-row v-if="!!tournament.rules">
    <v-col class="py-0">
      <tournament-rules :rules="tournament.rules" />
    </v-col>
  </v-row>

  <v-row>
    <v-col>
      <v-divider />
      <v-list>
        <v-list-item :to="{ name: 'team' }">
          <div class="block-navigation">
            {{ $t('tournament.myTeam') }}
            <v-icon right>keyboard_arrow_right</v-icon>
          </div>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list>
        <v-list-item :to="{ name: 'tournamentTeams' }">
          <div class="block-navigation">
            {{ $t('tournament.participatingTeams') }}
            <v-icon right>keyboard_arrow_right</v-icon>
          </div>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list>
        <v-list-item :to="{ name: 'gamePlan' }" aria-label="show game plan">
          <div class="block-navigation">
            {{ $t('tournament.gamePlanTitle') }}
            <v-icon right>keyboard_arrow_right</v-icon>
          </div>
        </v-list-item>
      </v-list>
      <v-divider />
    </v-col>
  </v-row>

  <v-row>
    <v-col v-if="isLoadingNextFixture" class="loading">
      <v-progress-circular indeterminate color="primary" />
    </v-col>
    <v-col v-if="nextFixture">
      <next-fixture v-if="isNextFixtureFound" :value="nextFixture.match" />
      <v-alert v-if="isRankingIssuePresent" icon="hourglass_full" prominent text color="info">{{ $t('tournament.waitForResolvedIssue') }}</v-alert>
      <v-alert v-if="isNextFixtureNotYetDetermined" icon="hourglass_full" prominent text color="info">{{ $t('tournament.waitForGroupToFinish') }}</v-alert>
      <v-alert v-if="isEliminated" icon="clear" prominent text color="error">{{ $t('tournament.eliminatedMessage') }}</v-alert>
      <v-alert v-if="hasForfeited" icon="clear" prominent text color="error">{{ $t('tournament.forfeitMessage') }}</v-alert>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import { getMyTeam, getIssues } from '@/services/vuezlerApi/tournamentService';
import TournamentHeadline from '@tournament/components/TournamentHeadline';
import TournamentRules from '@tournament/components/TournamentRules';
import TournamentDescription from '@tournament/components/TournamentDescription';
import NextFixture from '@tournament/components/NextFixture';
import IssueBanner from '@tournament/components/IssueBanner';
import { getNextMatch } from '@/services/vuezlerApi/tournamentService';
import { TournamentState } from '../../../logic/tournaments/tournamentState';
import { GETTERS as AuthGetters } from '@/vuex/auth.module';
import { mapGetters } from 'vuex';
import { NextFixtureQueryState } from '@/services/vuezlerApi/models';

export default {
  name: 'active-tournament',
  data: () => ({
    team: undefined,
    nextFixture: undefined,
    activeState: TournamentState.Active,
    isLoadingNextFixture: false,
    hasIssues: false
  }),
  props: {
    tournament: Object
  },
  components: {
    TournamentRules,
    TournamentHeadline,
    TournamentDescription,
    NextFixture,
    IssueBanner
  },
  methods: {
    fetchMyTeam: function() {
      getMyTeam(this.tournament.id).then(({data}) => {
        this.team = data;
      });
    },
    fetchNextMatch: function() {
      this.isLoadingNextFixture = true;

      getNextMatch(this.tournament.id).then(({data}) => {
        this.nextFixture = data;
        this.isLoadingNextFixture = false;
      }).catch(({ response }) => {
        this.isLoadingNextFixture = false;
        if (response) {
          this.isBadRequest = response.status === 400;
        }
      });
    },
    fetchIssues: function() {
      getIssues(this.tournament.id).then(({data: issues}) => {
        this.hasIssues = issues.length !== 0;
      });
    },
    goToIssuePage: function() {
      this.$router.push({ name: 'issues' });
    }
  },
  computed: {
    isAdministrator: function() {
      return this.tournament.administratorId === this.userId;
    },
    ...mapGetters({
      userId: AuthGetters.USER_ID,
    }),
    isNextFixtureFound: function() {
      return this.nextFixture && this.nextFixture.state === NextFixtureQueryState.Found;
    },
    isNextFixtureNotYetDetermined: function() {
      return !this.hasForfeited && this.nextFixture && this.nextFixture.state === NextFixtureQueryState.NotYetDetermined;
    },
    isRankingIssuePresent: function() {
      return !this.hasForfeited && this.nextFixture && this.nextFixture.state === NextFixtureQueryState.RankingIssuePresent;
    },
    isEliminated: function() {
      return !this.hasForfeited && this.nextFixture && this.nextFixture.state === NextFixtureQueryState.Eliminated;
    },
    hasForfeited: function() {
      return this.team && this.team.hasForfeited;
    }
  },
  mounted: function() {
    this.fetchMyTeam();
    this.fetchNextMatch();

    if (this.isAdministrator) {
      this.fetchIssues();
    }
  }
}
</script>

<style scoped>
.block-navigation {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.loading {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>
