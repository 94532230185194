<template>
  <div column class="player">
    <div class="avatar">
      <avatar :src="profileImage" :class="{ 'idle': isIdle }" />
    </div>
    <div class="elo" v-if="showElo || showEloDelta">
      <v-chip small light pill>
        {{ player.eloRanking.current }}
        <span v-if="showEloDelta" right :class="eloDeltaColor" class="delta white--text">{{ signedEloDelta }}</span>
      </v-chip>
    </div>
  </div>
</template>

<script>
import { CONNECTION_STATE } from '../logic/matchSession/connectionState';
import Avatar from '../modules/core/components/Avatar';

export default {
  name: 'playerAvatar',
  data: () => ({
    defaultAvatar: require('@/assets/images/default-avatar.png'),
    elo: undefined,
    titles: []
  }),
  props: {
    player: Object,
    showElo: Boolean,
    showEloDelta: Boolean
  },
  computed: {
    profileImage: function() {
      return this.player.profileImage || this.defaultAvatar;
    },
    isIdle: function() {
      return this.player.connectionState === CONNECTION_STATE.Disconnected;
    },
    eloDeltaColor: function() {
      return this.eloDelta >= 0 ? "green darken-2" : "red darken-2";
    },
    eloDelta: function() {
      return this.player.eloRanking.current - this.player.eloRanking.previous;
    },
    signedEloDelta: function() {
      return this.eloDelta >= 0 ? `+${this.eloDelta}` : this.eloDelta;
    },
  },
  components: {
    Avatar
  }
}
</script>

<style scoped>
  .profileImage {
    width: 50px;
    height: 50px;
    background-size: cover;
    border-radius: 50%;
    margin: 0px auto;
  }

  .player {
    display: flex;
    flex-direction: column;
    align-content: center;
  }

  .avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .idle {
    filter: grayscale(100%);
  }

  .delta {
    border-radius: 0px 12px 12px 0px;
    margin-left: 8px;
    margin-right: -12px;
    height: 24px;
    padding: 2px 6px 0px 4px;
  }
</style>
