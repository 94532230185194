<template>
  <v-container>
    <div class="loading-container">{{text}}</div>
    <div class="img-bg"></div>
  </v-container>
</template>

<script>
export default {
  name: 'loadingScreen',
  props: {
    text: {
      default: '',
      type: String
    }
  }
}
</script>

<style scoped>
.container{
  height: 100%;
  padding: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.loading-container{
  color: #EF2341;
  position: absolute;
  bottom: 10px;
}

.img-bg{
  background-image: url("../../../assets/loader.gif");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
}
</style>



