<template>
  <v-container fill-height>
    <default-toolbar></default-toolbar>
    <v-layout column>
      <v-flex shrink>
        <player-statistic :statistic="statistic" :avatar="profileImage" aria-label="player-statistic"></player-statistic>
      </v-flex>
      <v-flex class="action-info-text" shrink>
        <span>{{ $t("match.joinMatch") }}</span>
      </v-flex>
      <v-flex class="join-button-container" grow>
        <v-btn @click="goToQrScanner" class="action-button"
          outlined color="grey darken-4" :aria-label="$t('match.qrCodeOption')">
          <div class="btn-content">
            <svg-icon block size="64px" name="qr-code-scan" color="grey-darken4"></svg-icon>
            <div class="btn-text">{{ $t("match.qrCodeOption") }}</div>
          </div>
        </v-btn>
        <v-btn @click="openNfcInfo"
          v-if="isNfcPresentOnMobile" class="action-button"
          outlined color="grey darken-4" :aria-label="$t('match.nfcOption')">
          <div class="btn-content">
            <svg-icon block size="64px" name="nfc" color="grey-darken4"></svg-icon>
            <div class="btn-text">{{ $t("match.nfcOption") }}</div>
          </div>
        </v-btn>
      </v-flex>
      <v-flex class="action-info-text" shrink>
        <span>{{ $t("match.orHostMatch") }}</span>
      </v-flex>
      <v-flex class="join-button-container" grow>
        <v-btn @click="createMatch" class="action-button" outlined color="grey darken-4" :aria-label="$t('match.hostMatch')">
          <div class="btn-content">
            <v-icon size="64px">add</v-icon>
            <div class="btn-text">{{ $t("match.hostMatch") }}</div>
          </div>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-dialog v-model="isNfcInfoOpen">
      <nfc-information @close="isNfcInfoOpen = false"></nfc-information>
    </v-dialog>
    <v-dialog @input="closeMatchDialog" :value="newMatchId != null">
      <new-match :matchId="newMatchId" @join="joinMatch" @cancel="closeMatchDialog"></new-match>
    </v-dialog>
  </v-container>  
</template>

<script>
import PlayerStatistic from '../components/core/PlayerStatistic';
import NfcInformation from '../components/core/NfcInformation';
import { GETTERS as AuthGetters } from '../vuex/auth.module';
import { mapGetters, mapState, mapActions } from 'vuex';
import { generateUUID } from '../logic/uuidGenerator';
import { getNfcState, NFC_STATES, isRunningInCordova } from '../modules/core/cordova/cordovaApi';
import { GETTERS as PlayerGetters, ACTIONS } from '../vuex/playerModule';
import NewMatch from '../components/matches/NewMatch';
import DefaultToolbar from '../toolbar/DefaultToolbar';
import { playerDataMixin } from '../modules/core/mixins/playerDataMixin';
import { shellInteractorMixin } from '../modules/core/mixins/shellInteractorMixin';

export default {
  name: 'home',
  mixins: [playerDataMixin, shellInteractorMixin],
  data: () => ({
    menuOpened: false,
    isNfcInfoOpen: false,
    newMatchId: undefined,
  }),
  computed: {
    pageTitle: function () {
      return this.$t('pageTitles.default');
    },
    isNfcPresentOnMobile: function() {
      return !(isRunningInCordova() && getNfcState() === NFC_STATES.NO_NFC)
    },
    ...mapGetters({
      playerId: AuthGetters.USER_ID,
      profileImage: PlayerGetters.PROFILE_IMAGE
    }),
    ...mapState({
      statistic: state => state.player.playerStatistics
    })
  },
  methods: {
    goToQrScanner: function() {
      this.$router.push('/qrScanner');
    },
    createMatch: function() {
      const id = generateUUID();
      this.newMatchId = id;
    },
    openNfcInfo: function() {
      if (window.nfc && window.device.platform === 'iOS') {
        window.nfc.beginSession();
      } else {
        this.isNfcInfoOpen = true;
      }
    },
    closeMatchDialog: function() {
      this.newMatchId = undefined;
    },
    joinMatch: function() {
      this.$router.push({name: 'match', params: { id: this.newMatchId }});
    },
    ...mapActions({
      updatePlayerStatistics: ACTIONS.UPDATE_STATISTICS
    })
  },
  mounted() {
    this.updatePlayerStatistics(this.playerId).catch(() => {
      this.showError(this.$t('player.errors.cantFetchInfo'));
    });
  },
  components: {
    PlayerStatistic,
    NfcInformation,
    NewMatch,
    DefaultToolbar
  }
}
</script>

<style scoped>
/deep/.v-btn__content {
  width: 100%;
}

.btn-content {
  display: block;
  padding: 16px;
  max-width: 160px;
}

.btn-text {
  white-space: normal;
}

.join-button-container {
  flex-basis: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

.join-button-container>.action-button{
  flex-grow: 1;
  flex-basis: 0;
  height: auto;
  border-color: var(--v-grey-darken4);
  margin: 8px;
  overflow: hidden;
}

.action-info-text {
  text-align: center;
  background: linear-gradient(to bottom, white 48%, var(--v-grey-darken4) 50%, white 52%);
  margin: 8px;
}
.action-info-text>span{
  color: var(--v-grey-darken4);
  background-color: white;
  padding-left: 8px;
  padding-right: 8px;
}
</style>
