import { Dictionary } from "vue-router/types/router"
import { Constellation } from "../../models/constellation"

type Params = Dictionary<string | undefined>

/**
 * Map all the players in a constellation from the param to the constellation representation.
 * 
 * @param params identifies all the players in the constellation in the form of url params.
 */
export function toConstellation(params: Params): Constellation {
  return {
    home: [
      params.one || null,
      params.two || null
    ],
    away: [
      params.three || null,
      params.four || null
    ]
  }
}

/**
 * Map all the players in a constellation from the constellation to the param form.
 * 
 * @param constellation identifies all the players in the constellation in the internal constellation form.
 */
export function toParams(constellation: Constellation): Params {
  return {
    one: constellation.home[0] || undefined,
    two: constellation.home[1] || undefined,
    three: constellation.away[0] || undefined,
    four: constellation.away[1] || undefined
  }
}