<template>
  <v-list-item :to="{ name: 'tournament', params: { id: tournament.id }}" :aria-label="'open tournament named ' + tournament.name">
    <v-list-item-content>
      <v-list-item-title>
        {{ tournament.name || $t("tournament.untitledTournament") }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ $t(subtitleKey, dateObject) }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { dateTimeFilter } from '@/filters/formatFilter';
import { TournamentState } from '@/logic/tournaments/tournamentState';

const SUBTITLE_DATEOBJECTS = {
  [TournamentState.Inactive]: (tournament) => ({
    date: dateTimeFilter(tournament.creationDate)
  }),
  [TournamentState.Active]: (tournament) => ({
    date: dateTimeFilter(tournament.startDate)
  }),
  [TournamentState.Finished]: (tournament) => ({
    fromDate: dateTimeFilter(tournament.startDate),
    toDate: dateTimeFilter(tournament.finishDate)
  })
}

const SUBTITLE_KEYS = {
  [TournamentState.Inactive]: 'tournament.createdOn',
  [TournamentState.Active]: 'tournament.runningSince',
  [TournamentState.Finished]: 'tournament.ranFrom',
}

export default {
  name: 'tournament-list-item',
  props: {
    tournament: Object,
    tournamentType: Number,
  },
  computed: {
    subtitleKey: function() {
      return SUBTITLE_KEYS[this.tournamentType]
    },
    dateObject: function() {
      return SUBTITLE_DATEOBJECTS[this.tournamentType](this.tournament)
    }
  }
}
</script>

