<template>
  <div>
    <page-header>
      <template v-slot:image>
        <img src="~@/assets/images/stock/impressum.jpg">
      </template>
      <nav-bar></nav-bar>
      <h1 class="text-center display-1 mt-4 mb-4">{{ $t("landingPage.pageTitles.about") }}</h1>
    </page-header>
    <div class="content">
      <v-avatar height="30%" width="30%" max-width="160px" max-height="160px" class="me">
        <v-img src="~@/assets/images/various/csiber.png"></v-img>
      </v-avatar>
      <component :blok="story.content" :is="story.content.component"></component>
    </div>
  </div>
</template>

<script>
import { storyblokMixin } from "@crm/mixins/storyblokMixin";

export default {
  name: 'impressum',
  mixins: [ storyblokMixin ],
  created() {
    this.initialize('about');
  }
}
</script>

<style scoped>

.me {
  float: left;
  margin-right: 24px;
  margin-bottom: 24px;
}

.content {
  margin: 0 auto;
  width: 80%;
}
</style>