<template>
  <div>
    <div class="game-plan-container">
      <v-card class="stage-card stage-connection primary lighten-1 white--text" :elevation="6">{{nrOfParticipants}} {{$t('tournament.teams')}} {{$t('tournament.gamePlan.registered')}}</v-card>
      
      <div class="mt-3" v-for="(stageSpecification, idx) in value" :key="idx">
        <v-card class="stage-card" :elevation="6" >
          <stage-editor
            :value="stageSpecification"
            @input="updateStage($event, idx)" @remove="removeStage(idx)"
            :errors="errors && errors.stages && errors.stages[idx]"
          />
        </v-card >
        <div color="error" class="stage-card error-chip"
          v-if="isMismatchInStage(idx)">
          <v-icon color="error" left>cancel</v-icon>
          {{ mismatchError(idx) }}
        </div>
      </div>

      <div class="action-buttons">
        <v-btn outlined type="button" @click="addToSpecification(createGroupStageTemplate())">{{ $t('tournament.gamePlan.addGroupStage') }}</v-btn>
        <v-btn outlined type="button" @click="addToSpecification(createKnockoutStageTemplate())">{{ $t('tournament.gamePlan.addKnockoutStage') }}</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import StageEditor from '@tournament/components/StageEditor';

export default {
  name: 'game-plan-editor',
  props: {
    value: {
      default: () => [],
      type: Array
    },
    nrOfParticipants: {
      default: () => 0,
      type: Number
    },
    errors: {
      default: () => {},
      type: Object
    }
  },
  methods: {
    isMismatchInStage: function(stageIdx) {
      return this.errors &&
        this.errors.stages &&
        this.errors.stages[stageIdx] &&
        this.errors.stages[stageIdx].mismatch
    },
    mismatchError: function(stageIdx) {
      return stageIdx >= this.value.length-1
        ? this.$t('tournament.error.singleAdvancingTeam')
        : this.$t(`errorCodes.${this.errors.stages[stageIdx].mismatch[0]}`)
    },
    specificationUpdated(specification) {
      this.$emit('input', specification);
    },
    addToSpecification(template) {
      const newValue = [...this.value]
      newValue[newValue.length] = template;
      this.specificationUpdated(newValue);
    },
    updateStage(stageDefinition, idx) {
      const precessors = this.value.slice(0, idx);
      const successors = this.value.slice(idx+1);
      this.specificationUpdated([ ...precessors, stageDefinition, ...successors]);
    },
    removeStage(idx) {
      const precessors = this.value.slice(0, idx);
      const successors = this.value.slice(idx+1);
      this.specificationUpdated([ ...precessors, ...successors]);
    },
    createGroupStageTemplate() {
      return {
        type: 'groupstage',
        rounds: 1,
        groups: [],
      }
    },
    createKnockoutStageTemplate() {
      return {
        type: 'knockoutstage',
        isRematchRequired: false,
        starting: 4,
        advancing: 1,
      }
    }
  },
  components: {
    StageEditor
  }
}
</script>

<style scoped>
.game-plan-container {
  padding: 0;
}

.game-plan-container>* {
  margin-bottom: 8px;
}

.stage-connection {
  padding: 8px;
}

.stage-card {
  margin-top: 8px;
  border-radius: 8px;
}

.action-buttons {
  display: flex;
  flex-direction: row;
}

.action-buttons>* {
  flex: 1 0 auto;
  margin: 8px;
}

.error-chip {
  display: flex;
  flex-direction: row;
  color: var(--v-error-base);
  padding: 8px;
}
</style>