export const ACTIONS = {
  SET_TITLE: 'SET_TITLE',
  RESET_TITLE: 'RESET_TITLE',
  SHOW_ERROR: 'SHOW_ERROR',
  CLOSE_ERROR: 'CLOSE_ERROR',
  TOGGLE_DRAWER: 'TOGGLE_DRAWER',
}

export const GETTERS = {
  IS_LOADING: 'IsLoading'
}

const MUTATIONS = {
  SET_TITLE: '[🐚 Shell] Set title',
  RESET_TITLE: '[🐚 Shell] Reset title',
  SET_ERROR: '[🐚 Shell] Set error',
  TOGGLE_DRAWER: '[🐚 Shell] Toggle drawer',
}

const getters = {
  [GETTERS.IS_LOADING]: state => state.loadingText !== undefined
}

const state = {
  title: undefined,
  subtitle: undefined,
  errorMessage: undefined,
  isDrawerOpen: false,
}

const actions = {
  [ACTIONS.SET_TITLE]: ({commit}, {title, subtitle}) => {
    commit(MUTATIONS.SET_TITLE, { title, subtitle });
  },
  [ACTIONS.RESET_TITLE]: ({commit},) => {
    commit(MUTATIONS.RESET_TITLE);
  },
  [ACTIONS.SHOW_ERROR]: ({state, commit, dispatch}, title) => {
    commit(MUTATIONS.SET_ERROR, { title });
    setTimeout(() => {
      if (state.errorMessage !== undefined) {
        dispatch(ACTIONS.CLOSE_ERROR)
      }
    }, 4000);
  },
  [ACTIONS.CLOSE_ERROR]: ({commit}) => {
    commit(MUTATIONS.SET_ERROR, { close: true });
  },
  [ACTIONS.TOGGLE_DRAWER]: ({commit}, data) => {
    commit(MUTATIONS.TOGGLE_DRAWER, data);
  },
}

const mutations = {
  [MUTATIONS.SET_TITLE]: (state, {title, subtitle}) => {
    state.title = title;
    state.subtitle = subtitle;
  },
  [MUTATIONS.RESET_TITLE]: (state) => {
    state.title = undefined;
    state.subtitle = undefined;
  },
  [MUTATIONS.SET_ERROR]: (state, data) => {
    state.errorMessage = data.close ? undefined : data.title;
  },
  [MUTATIONS.TOGGLE_DRAWER]: (state, data) => {
    state.isDrawerOpen = data;
  },
}

export default {
  state,
  actions,
  mutations,
  getters
}