/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'trophy': {
    width: 16,
    height: 16,
    viewBox: '0 0 297 297',
    data: '<path pid="0" d="M174.691 194.214c0-2.718-2.152-4.382-4.782-3.698 0 0-12.318 3.201-21.409 3.201s-21.409-3.201-21.409-3.201c-2.63-.684-4.782.98-4.782 3.698v14.329a4.956 4.956 0 004.941 4.941h42.5a4.956 4.956 0 004.941-4.941v-14.329zM223.615 9.884c0-5.458-4.426-9.884-9.884-9.884H83.269c-5.458 0-9.884 4.426-9.884 9.884v81.539c0 25.522 7.591 48.754 21.374 65.415 13.874 16.771 32.96 26.008 53.741 26.008s39.867-9.236 53.741-26.008c13.783-16.661 21.374-39.893 21.374-65.415V9.884zM63.981 111.227c-13.525-5.033-19.753-16.706-19.753-36.111V30.969h18.285V11.202H34.346c-5.459 0-9.884 4.425-9.884 9.884v54.029c0 32.961 15.788 53.039 44.818 57.729-2.385-6.859-4.171-14.095-5.299-21.617zM83.269 228.31c-5.458 0-9.884 4.425-9.884 9.884v48.923c0 5.458 4.426 9.884 9.884 9.884h130.463c5.458 0 9.884-4.426 9.884-9.884v-48.923c0-5.459-4.426-9.884-9.884-9.884H83.269zM262.654 11.202h-28.168v19.767h18.285v44.146c0 19.405-6.228 31.078-19.753 36.111-1.128 7.522-2.913 14.759-5.299 21.618 29.03-4.69 44.818-24.768 44.818-57.729V21.086c.001-5.459-4.424-9.884-9.883-9.884z"/>'
  }
})
