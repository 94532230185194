<template>
  <div>
    <stacked-page-toolbar :title="$t('tournament.teams')">
      <template slot="action" v-if="isAdministrator && isEditable">
        <v-btn icon @click="toggleEditState">
          <v-icon v-if="!isEditState">edit</v-icon>
          <v-icon v-if="isEditState">close</v-icon>
        </v-btn>
      </template>
    </stacked-page-toolbar>
    <div v-if="isLoading" class="loading my-2">
      <v-progress-circular indeterminate color="primary" />
    </div>
    <v-container v-if="!isLoading" full-height>
      <v-list two-line light>
        <v-list-item-group v-model="teamSelection" :multiple="isEditState">
          <v-list-item
            v-for="team in teams"
            :key="team.id"
          >
          <template v-slot:default="listItem">
            <v-list-item-action v-show="isEditState">
              <v-checkbox v-model="listItem.active">
              </v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title v-text="team.info.name"></v-list-item-title>
              <v-list-item-subtitle>
                {{ team.participants.map(p => p.name).join(', ') }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar width="auto" tile>
              <multi-avatar :avatars="team.participants.map(p => p.profileImage)" />
            </v-list-item-avatar>
          </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      
      <action-footer v-if="isEditState">
        <v-btn style="background-color: white" outlined block color="error" :disabled="teamSelection.length === 0" @click="kickTeams">
          <span class="default-text" v-if="teamSelection.length === 0">{{ $t("tournament.teamsPage.kickTeamsNote") }}</span>
          <span v-else>{{ $t("tournament.teamsPage.kickTeams") }}</span>
        </v-btn>
      </action-footer>
    </v-container>
  </div>
</template>

<script>
import MultiAvatar from '@/components/MultiAvatar';
import StackedPageToolbar from '@/toolbar/StackedPageToolbar';
import { mapState, mapActions, mapGetters } from 'vuex'
import { Actions as TournamentActions, Getters as TournamentGetters } from '@tournament/states/tournament';
import { GETTERS as AuthGetters } from '@/vuex/auth.module';
import ActionFooter from '@core/components/ActionFooter'
import { kickTeam } from '@/services/vuezlerApi/tournamentService';

export default {
  name: 'tournament-teams',
  data: function() {
    return {
      isEditState: false,
      teamSelection: [],
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      teams: state => state.tournament.teams,
    }),
    ...mapGetters({
      currentUserId: AuthGetters.USER_ID,
      tournament: TournamentGetters.Tournament,
    }),
    isAdministrator: function() {
      return this.tournament && this.tournament.administratorId == this.currentUserId;
    },
    isEditable: function() {
      return this.tournament && this.tournament.finishDate == null;
    },
  },
  props: {
    title: String,
    tournamentId: String,
  },
  watch: {
    isEditState: function() {
      this.teamSelection = []
    }
  },
  methods: {
    ...mapActions({
      fetchTeams: TournamentActions.FetchTeams,
      fetchTournament: TournamentActions.GatherTournamentInfo,
    }),
    toggleEditState: function() {
      this.isEditState = !this.isEditState;
    },
    kickTeams: function() {
      const kickRequests = this.teamSelection.map(selectedIdx => {
        const teamId = this.teams[selectedIdx].info.id;
        kickTeam(this.tournamentId, teamId)
      });

      Promise.all(kickRequests).then(() => {
        this.isEditState = false;
        this.teamSelection = [];
      })
    }
  },
  mounted(){
    const fetchTeamsPromise = this.fetchTeams(this.tournamentId);
    const fetchTournamentPromise = this.fetchTournament(this.tournamentId);

    this.isLoading = true;
    Promise.all([fetchTeamsPromise, fetchTournamentPromise]).then(() => {
      this.isLoading = false;
    }).catch(() => {
      this.isLoading = false;
    });
  },
  components: {
    MultiAvatar,
    StackedPageToolbar,
    ActionFooter,
  },
}
</script>

<style scoped>
.default-text {
  text-transform: initial !important;
}
.loading {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>
