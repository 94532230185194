<template>
  <div>
    <h1 class="title">{{ $t('tournament.group.table') }}</h1>
    <group-table :group="value" class="mb-5"/>

    <h1 class="title">{{ $t('tournament.group.fixtures') }}</h1>
    <div v-for="(fixture, idx) in fixtures" :key="fixture.id"  :class="{ 'disabled': !fixture.isFixtureEligible() }" class="fixture-wrapper">
      <p 
        v-if="isForfeitWarningVisible(fixture, fixtures[idx-1])"
        class="text--secondary body-2"
      >
        {{ $t('tournament.group.fixturesNotEligible') }}:
      </p>
      <div class="fixture">
        <div class="team-data">
          <div
            class="team-name"
            :class="{ 'strike-through': fixture.firstTeam.hasForfeited }"
          >
            {{ fixture.firstTeam.name }}
          </div>
          <div class="team-score">{{ fixture.firstScore }}</div>
        </div>
        <div class="team-data">
          <div
            class="team-name"
            :class="{ 'strike-through': fixture.secondTeam.hasForfeited }"
          >
            {{ fixture.secondTeam.name }}
          </div>
          <div class="team-score">{{ fixture.secondScore }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GroupTable from '@tournament/components/GroupTable';

export default {
  name: 'group-stage',
  props: {
    value: {
      type: Object,
      required: true
    },
  },
  computed: {
    participants: function() {
      const result = {};
      for(const participant of this.value.participants) {
        if (participant.team != null) {
          result[participant.participantId] = { ...participant.team, forfeitTimestamp: participant.forfeitTimestamp };
        }
      }
      return result;
    },
    fixtures: function() {
      return this.value.fixtures.map(fixture => ({
        id: fixture.id,
        firstTeam: this.getTeam(fixture.firstTeam),
        firstScore: fixture.firstScore,
        secondTeam: this.getTeam(fixture.secondTeam),
        secondScore: fixture.secondScore,
        isFixtureEligible: function() {
          return !this.firstTeam.hasForfeited && !this.secondTeam.hasForfeited;
        }
      })).sort((a,b) => b.isFixtureEligible() - a.isFixtureEligible());
    }
  },
  methods: {
    isForfeitWarningVisible(fixture, prevFixture) {
      if (prevFixture == null) {
        return !fixture.isFixtureEligible();
      } else {
        return prevFixture.isFixtureEligible() != fixture.isFixtureEligible();
      }
    },
    hasForfeited: function(team) {
      return team && team.forfeitTimestamp != null;
    },
    getTeam: function(teamId) {
      const team = this.participants[teamId];
      if (team == null) {
        return {
          name: '',
          hasForfeited: false
        };
      } else {
        return {
          name: team.name,
          hasForfeited: this.hasForfeited(team)
        }
      }
    }
  },
  components: {
    GroupTable
  }
}
</script>

<style scoped>

.fixture {
  display: flex;
  flex-direction: column;

  border: 1px solid var(--v-grey-base);
  border-radius: 4px;

}

.fixture-wrapper:not(:last-child) {
  margin-bottom: 16px;
}

.fixture-wrapper.disabled * {
  background-color: white;
}

.team-data {
  display: flex;
  flex-direction: row;
}

.team-data * {
  padding: 4px;
  min-height: 32px;
}

.team-data:nth-child(2) {
  border-top: 1px solid var(--v-grey-base);
}

.team-data:nth-child(odd) {
  background-color: var(--v-grey-lighten2);
}

.team-name {
  flex-grow: 1;
}

.team-score {
  border-left: 1px solid var(--v-grey-base);
  width: 40px;
}

.outcomes {
  width: 40px;
  text-align: center;
}

.strike-through {
  text-decoration: line-through;
}
</style>
