<template>
  <v-card>
    <v-card-title class="title">{{ $t('tournament.issues.groupRankingTitle' )}}</v-card-title>
    <v-card-subtitle>{{ groupName }}</v-card-subtitle>
    <v-card-text>
        <p>{{ $t('tournament.issues.grouRankingDescription') }}</p>
        <group-table :group="group" editable @change="updateRanking"/>
    </v-card-text>
    <v-card-actions class="actions">
      <v-spacer/>
      <v-btn text color="primary" @click="resolveIssue" :loading="isSubmitting">{{ $t('common.submit') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import GroupTable from '@tournament/components/GroupTable';
import { Actions } from '@/modules/tournament/states/issues';
import { mapActions } from 'vuex';

export default {
  name: "group-ranking-issue",
  data: function() {
    return {
      ranking: undefined,
      isSubmitting: false
    }
  },
  props: {
    issue: {
      type: Object,
      required: true
    },
    tournamentId: String
  },
  computed: {
    group: function() {
      return this.issue.group;
    },
    groupName: function() {
      return `${this.$t('tournament.groupPrefix', )} ${this.group.name}`
    }
  },
  methods: {
    updateRanking: function(updatedRanking) {
      this.ranking = updatedRanking;
    },
    ...mapActions({
      resolveRankingIssue: Actions.ResolveRankingIssue
    }),
    resolveIssue: function() {
      const updatedRanking = this.ranking.map((rank, idx) => ({
        rank: idx,
        team: rank.team
      }));

      this.isSubmitting = true;

      this.resolveRankingIssue({ tournamentId: this.tournamentId, issue: this.issue, updatedRanking }).then(() => {
        this.isSubmitting = false;
      }).then().catch(({ response }) => {
        this.isSubmitting = false;
        if (response.status == 400 && response.data.key) {
          const errorMessage = this.$t(`errorCodes.${response.data.key}`);
          this.showError(errorMessage);
        } else {
          this.showError(this.$t('common.unknownError'));
        }
      });
    },
  },
  components: {
    GroupTable
  }
}
</script>

<style scoped>

</style>