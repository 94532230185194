import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import Touch from 'vuetify/es5/directives/touch'

import 'material-design-icons-iconfont/dist/material-design-icons.css' 
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify, {
  directives: {
    Touch
  }
});

export default new Vuetify({
  icons: {
    iconfont: 'md'
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: {
          base: '#375e97',
          lighten1: '#417dbb',
          darken1: '#375e97'
        },
        secondary: {
          base: '#52c14c',
          lighten1: '#87f57b',
          darken1: '#09901b',
        },
        yellow: {
          base: '#FFC107'
        },
        grey: {
          lighten3: colors.grey.lighten5,
          lighten2: colors.grey.lighten4,
          lighten1: colors.grey.lighten3,
          base: colors.grey.lighten2,
          darken2: colors.grey.base,
          darken3: '#666666',
          darken4: colors.grey.darken2
        },
        white: {
          base: '#ffffff'
        },
        background: {
          base: '#ffffff'
        },
      },
    },
    options: {
      customProperties: true
    }
  },
})
