import { GETTERS as AuthGetters } from './auth.module';
import { getPlayer } from '../modules/core/services/playerService';
import { getPlayerStatistic } from "../services/vuezlerApi/statistics.service";

export const ACTIONS = {
    UPDATE_PROFILE_IMAGE: "UpdateProfileImage",
    FETCH_INFORMATION: "PlayerFetchInformation",
    UPDATE_STATISTICS: "UpdateStatistics"
}

export const GETTERS = {
    PROFILE_IMAGE: "ProfileImage",
    IS_PROFILE_AVAILABLE: "IsProfileAvailable"
}

const MUTATIONS = {
    SET_PROFILE_IMAGE: "[🧑 Player] Set Profile Image",
    SET_INFORMATION: "[🧑 Player] Set Information",
    SET_STATISTICS: "[🧑 Player] Set Player Statistics"
}

let cachedStatistics = null;
try {
    const cacheItem = localStorage.getItem('statistics');
    if (cacheItem != null) {
        cachedStatistics = JSON.parse(cacheItem)
    }
} catch(e) {
    cachedStatistics = null;
}

const state = {
    profileImage: undefined,
    playerStatistics: cachedStatistics || {
        elo: undefined,
        wins: undefined,
        draws: undefined,
        losses: undefined
    }
}

const DEFAULT_PROFILE_IMAGE = require('@/assets/images/default-avatar.png')

const getters = {
    [GETTERS.PROFILE_IMAGE]: state => state.profileImage || DEFAULT_PROFILE_IMAGE,
    [GETTERS.IS_PROFILE_FETCHED]: state => state.profileImage !== undefined
}

const actions = {
    [ACTIONS.UPDATE_PROFILE_IMAGE]: ({commit}, profileImage) => {
        commit(MUTATIONS.SET_PROFILE_IMAGE, profileImage);
    },
    [ACTIONS.FETCH_INFORMATION]: ({ commit, getters }) => {
        const playerId = getters[AuthGetters.USER_ID];
        return getPlayer(playerId).then(({ data }) => {
            commit(MUTATIONS.SET_PROFILE_IMAGE, data.profileImage);
        });
    },
    [ACTIONS.UPDATE_STATISTICS]: ({commit}, playerId) => {
        return getPlayerStatistic(playerId)
            .then(({data}) => commit(MUTATIONS.SET_STATISTICS, data))
    }
}

const mutations = {
    [MUTATIONS.SET_PROFILE_IMAGE]: (state, profileImage) => {
        state.profileImage = profileImage;
    },
    [MUTATIONS.SET_STATISTICS]: (state, playerStatistics) => {
        state.playerStatistics = playerStatistics
        localStorage.setItem('statistics', JSON.stringify(playerStatistics));
    },
}

export default {
    state,
    actions,
    mutations,
    getters
}