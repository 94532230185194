import { getGroupMemberships } from "../services/vuezlerApi/player.service";
import { getGroupMembers, getGroup } from "../services/vuezlerApi/group.service";
import { GETTERS as AuthGetters } from './auth.module';
import Vue from 'vue';

export const ACTIONS = {
  FETCH_MEMBERSHIPS: 'FETCH_MEMBERSHIPS',
  FETCH_GROUP: 'FETCH_GROUP',
  OPEN_ADDPLAYER_DIALOG: 'OPEN_ADDPLAYER_DIALOG',
  CLOSE_ADDPLAYER_DIALOG: 'CLOSE_ADDPLAYER_DIALOG',
  ADD_PLAYER_TO_GROUP: 'ADD_PLAYER_TO_GROUP',
  ADD_GROUPMEMBERSHIP: 'ADD_GROUPMEMBERSHIP',
  REMOVE_GROUPMEMBERSHIP: 'REMOVE_GROUPMEMBERSHIP'

}

export const GETTERS = {
  IsAdministrator: '[👨‍👩‍👦 Group] Is administrator',
}

const MUTATIONS = {
  SET_GROUPINFO: '[👨‍👩‍👦 Group] Set group info',
  SET_MEMBERSHIPS: '[👨‍👩‍👦 Group] Set memberships',
  SET_GROUPMEMBERS: '[👨‍👩‍👦 Group] Set group members',
  CHANGE_ADDPLAYER_DIALOG_STATE: '[👨‍👩‍👦 Group] Change "AddPlayer" dialog state',
  ADD_PLAYER_TO_GROUP: '[👨‍👩‍👦 Group] Add player to group',
  ADD_GROUPMEMBERSHIP: '[👨‍👩‍👦 Group] Add group membership',
  REMOVE_GROUPMEMBERSHIP: '[👨‍👩‍👦 Group] Remove group membership'
}

const state = {
  groupMemberships: [],
  administrators: [],
  currentGroup: undefined,
  members: [],
  isAddPlayerDialogOpen: false
}

const getters = {
  [GETTERS.IsAdministrator]: (state,rootGetters) => state.administrators.findIndex(playerId => playerId === rootGetters[AuthGetters.USER_ID]) !== -1
}

const actions = {
  [ACTIONS.FETCH_MEMBERSHIPS]: ({ commit}) => {
    getGroupMemberships().then(({ data }) => {
      commit(MUTATIONS.SET_MEMBERSHIPS, data)
    })
  },
  [ACTIONS.FETCH_GROUP]: ({ commit}, { groupId, errorCallback }) => {
    getGroup(groupId).then(({data: groupInfo}) => {
      commit(MUTATIONS.SET_GROUPINFO, { groupInfo })
    }, () => {
      errorCallback && errorCallback();
    })
    getGroupMembers(groupId).then(({ data }) => {
      commit(MUTATIONS.SET_GROUPMEMBERS, data);
    }, () => {
      errorCallback && errorCallback();
    })
  },
  [ACTIONS.OPEN_ADDPLAYER_DIALOG]: ({commit}) => {
    commit(MUTATIONS.CHANGE_ADDPLAYER_DIALOG_STATE, true);
  },
  [ACTIONS.CLOSE_ADDPLAYER_DIALOG]: ({commit}) => {
    commit(MUTATIONS.CHANGE_ADDPLAYER_DIALOG_STATE, false);
  },
  [ACTIONS.ADD_PLAYER_TO_GROUP]: ({commit}, player) => {
    commit(MUTATIONS.ADD_PLAYER_TO_GROUP, player)
  },
  [ACTIONS.ADD_GROUPMEMBERSHIP]: ({commit}, group) => {
    commit(MUTATIONS.ADD_GROUPMEMBERSHIP, group);
  },
  [ACTIONS.REMOVE_GROUPMEMBERSHIP]: ({commit}, groupId) => {
    commit(MUTATIONS.REMOVE_GROUPMEMBERSHIP, groupId);
  }
}

const mutations = {
  [MUTATIONS.SET_MEMBERSHIPS]: (state, memberships) => {
    state.groupMemberships = memberships;
  },
  [MUTATIONS.SET_GROUPINFO]: (state, { groupInfo }) => {
    state.currentGroup = { ...state.currentGroup };
    Vue.set(state.currentGroup, 'description', groupInfo.description);
    Vue.set(state.currentGroup, 'name', groupInfo.name);
    Vue.set(state.currentGroup, 'id', groupInfo.id);
  },
  [MUTATIONS.SET_GROUPMEMBERS]: (state, { members, administrators })  => {
    state.members = members;
    state.administrators = administrators;
  },
  [MUTATIONS.CHANGE_ADDPLAYER_DIALOG_STATE]: (state, newState) => {
    state.isAddPlayerDialogOpen = newState;
  },
  [MUTATIONS.ADD_PLAYER_TO_GROUP]: (state, newPlayer) => {
    state.members.push(newPlayer);
  },
  [MUTATIONS.ADD_GROUPMEMBERSHIP]: (state, group) => {
    state.groupMemberships.push({ id: group.id, name: group.name });
  },
  [MUTATIONS.REMOVE_GROUPMEMBERSHIP]: (state, groupId) => {
    const index = state.groupMemberships.findIndex(membership => membership.id === groupId);
    Vue.delete(state.groupMemberships, index);
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}