<template>
    <div>
        <v-form v-model="isFormValid" class="support-form">
            <v-alert :value="isUnavailable" color="error">{{ $t('support.unavailable') }}</v-alert>
            <v-text-field :label="$t('support.email')" v-model="email" :rules="emailRules" validate-on-blur name="email" type="email" autocomplete="email"></v-text-field>
            <v-textarea solo name="description" :label="$t('support.description')" :rules="descriptionRules" v-model="description" counter="500" required></v-textarea>
            <vue-recaptcha
                ref="recaptcha"
                :sitekey="reCaptchaConfig.sitekey"
                :loadRecaptchaScript="true"
                class="re-captcha"
                @verify="setReCaptchaToken"
                @expired="resetReCaptchaToken">
            </vue-recaptcha>
            <v-btn large
                class="mt-3"
                color="primary"
                :loading="isSubmitting"
                :disabled="!isRequestPermitted"
                @click="submitSupportTicket"
            >
                <v-icon left>send</v-icon>
                {{ $t('common.send' ) }}
            </v-btn>
        </v-form>
    </div>
</template>

<script>
import { createSupportTicket } from '../../services/vuezlerApi/support.service';
import VueRecaptcha from 'vue-recaptcha';
import { required, maxLength, email } from '../../logic/common/inputValidationRules';
import { getVersion } from '../../logic/common/shell';
import { mapActions } from 'vuex';
import { ACTIONS } from '../../vuex/shell.module';

export default {
    name: 'anonymous-support',
    data: function() {
        return {
            isFormValid: false,
            email: '',
            emailRules:  [ email.bind(this) ],
            description: '',
            descriptionRules: [ required.bind(this), maxLength(500).bind(this) ],
            reCaptchaToken: null,
            isSubmitting: false,
            isUnavailable: false,
        }
    },
    computed: {
        reCaptchaConfig: () => ({
            sitekey: window.$config.reCaptchaSitekey,
            client: "web"
        }),
        isRequestPermitted: function() {
            return this.isFormValid && this.reCaptchaToken !== null;
        },
    },
    methods: {
        submitSupportTicket: async function() {
            this.isSubmitting = true;

            if (!this.isRequestPermitted) {
                return;
            }

            const version = getVersion();
            try {
                await createSupportTicket({
                description: this.description,
                mail: this.email,
                version,
                reCaptcha: this.reCaptchaToken,
                reCaptchaClient: this.reCaptchaConfig.client
                });

                this.$emit('submitted');
            } catch {
                this.isUnavailable = true;
                this.$refs.recaptcha.reset();
            }
            
            this.isSubmitting = false;
        },
        setReCaptchaToken: function (token) {
            this.reCaptchaToken = token;
        },
        resetReCaptchaToken: function() {
            this.reCaptchaToken = null;
        },
        ...mapActions({
            showErrorMessage: ACTIONS.SHOW_ERROR
        })
    },
    components: {
        VueRecaptcha,
    }
}
</script>

<style scoped>
.re-captcha>>>div{
    margin-left: auto;
    margin-right: auto;
}

.support-form>*:not(:last-child) {
    margin-bottom: 8px;
}
</style>