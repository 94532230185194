<template>
    <v-form v-model="isFormValid" @submit.prevent="createTournament">
        <v-card>
            <v-card-title>
                <h1 class="title">{{ $t('tournament.create') }}</h1>
            </v-card-title>
            <v-card-text>
                <v-text-field :label="$t('tournament.name')" :rules="[ rules.required ]"
                    v-model="tournament.name">
                </v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn text @click="close">{{ $t('common.cancel') }}</v-btn>
                <v-btn class="primary" :disabled="!isFormValid" @click="createTournament">
                    {{ $t('common.create') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
export default {
    name: 'tournament-create',
    data: function() {
        return {
            isFormValid: false,
            tournament: {
                name: ''
            },
            rules: {
                required: value => !!value || this.$i18n.t('common.validation.required')
            }
        }
    },
    methods: {
        createTournament: function() {
            this.$emit('create', this.tournament);
        },
        close: function() {
            this.$emit('close')
        }
    }
}
</script>