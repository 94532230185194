<template>
<v-container>
  <v-row justify="start">
    <v-col>
      <tournament-headline :name="tournament.name" :state="activeState" :tournamentId="tournament.id" />
      <tournament-description v-if="tournament.description" :value="tournament.description" />
    </v-col>
  </v-row>

<v-row v-if="!!tournament.rules">
    <v-col class="py-0">
      <tournament-rules :rules="tournament.rules" />
    </v-col>
  </v-row>

  <v-row>
    <v-col>
      <v-divider />
      <v-list>
        <v-list-item :to="{ name: 'tournamentTeams' }">
          <div class="block-navigation">
            {{ $t('tournament.participatingTeams') }}
            <v-icon right>keyboard_arrow_right</v-icon>
          </div>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list>
        <v-list-item :to="{ name: 'gamePlan' }">
          <div class="block-navigation">
            {{ $t('tournament.gamePlanTitle') }}
            <v-icon right>keyboard_arrow_right</v-icon>
          </div>
        </v-list-item>
      </v-list>
      <v-divider />
    </v-col>
  </v-row>

  <v-row v-if="winner" class="winner">
    <winner-announcement :winner="winner"/>
  </v-row>
</v-container>
</template>

<script>
import { getWinner } from '@/services/vuezlerApi/tournamentService';
import WinnerAnnouncement from '@tournament/components/WinnerAnnouncement';
import TournamentHeadline from '@tournament/components/TournamentHeadline';
import TournamentRules from '@tournament/components/TournamentRules';
import TournamentDescription from '@tournament/components/TournamentDescription';
import { TournamentState } from '@/logic/tournaments/tournamentState';

export default {
  name: 'finished-tournament',
  data: () => ({
    winner: null,
    activeState: TournamentState.Finished,
  }),
  props: {
    tournament: Object
  },
  mounted: function(){
    getWinner(this.tournament.id)
      .then(({data}) => {
        this.winner = data;
      });
  },
  components: {
    WinnerAnnouncement,
    TournamentRules,
    TournamentHeadline,
    TournamentDescription,
  }
}
</script>

<style scoped>
.winner {
  margin-top: 16px;
}

.winner>* {
  margin-left: auto;
  margin-right: auto;
}
</style>
