export function required(value) {
  return !!value || this.$t("common.validation.required");
} 

export function maxLength(maxLength) {
  return function (value) {
    return value && value.length >= maxLength
      ?  this.$t("common.validation.tooLong")
      : true;
  }
}

export function minLength(minLength) {
  return function (value) {
    return !value || value.length < minLength
      ? this.$t("common.validation.tooShort")
      : true;
  }
}

export function email (value) {
  return /.+@.+\..+/.test(value) || this.$i18n.t("common.validation.email") 
}

export function equalOrLargerThan(restriction) {
  return function (value) {
    return value < restriction
      ? this.$t("common.validation.equalOrLarger", { value: restriction })
      : true
  }
}