import { render, staticRenderFns } from "./Team.vue?vue&type=template&id=5ce27dc3&scoped=true&"
import script from "./Team.vue?vue&type=script&lang=js&"
export * from "./Team.vue?vue&type=script&lang=js&"
import style0 from "./Team.vue?vue&type=style&index=0&id=5ce27dc3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ce27dc3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VBtn,VChip,VContainer,VDialog,VList,VListItem,VListItemAvatar,VListItemContent,VListItemTitle,VProgressCircular})
