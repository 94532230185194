<template>
    <v-container fill-height flex-column align-stretch>
        <section class="header">
            <h1 class="title">{{ $t('support.title') }}</h1>
            <p class="subtitle-1 mt-1">{{ $t('support.introduction') }}</p>
        </section>

        <authorized-support v-if="!isSuccessfullySubmitted"
            @submitted="isSuccessfullySubmitted = true"
        />
        <Success v-else
            :title="$t('common.thankYou')"
            :subtitle="$t('support.successfullySubmitted')">
        </Success>

        <v-spacer />

        <v-btn class="flex-grow-0" block color="primary" @click="navigateBack">{{ $t('common.back') }}</v-btn>
    </v-container>
</template>

<script>
import AuthorizedSupport from './AuthorizedSupport.vue';
import Success from '../../components/common/Success';

export default {
    name: 'support',
    data: () => ({
        isSuccessfullySubmitted: false,
    }),
    components: {
        AuthorizedSupport,
        Success
    },
    methods: {
        navigateBack: function() {
            this.$router.back();
        }
    }
}
</script>