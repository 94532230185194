import Issues from '@tournament/pages/Issues';
import Tournament from '@tournament/pages/Tournament';
import TournamentEdit from '@tournament/pages/TournamentEdit';
import TournamentList from '@tournament/pages/TournamentList';
import TournamentTeams from '@tournament/pages/TournamentTeams';
import TournamentGamePlan from '@tournament/pages/TournamentGamePlan';
import Team from '@tournament/pages/Team';
import { authenticationGuard } from '@/routes/guards/authenticationGuard';
import { guards as multi } from '@/logic/guardsHelper';
import { ensureTournamentHubConnection } from '@/modules/tournament/guards/tournamentHubGuards';

const routes = [
  {
    name: 'tournaments',
    path: '/tournaments',
    component: TournamentList
  },
  {
    name: 'tournament',
    path: '/tournaments/:id',
    component: Tournament,
    props: route => ({
      id: route.params.id,
      joinTeamId: route.query.join,
      title: 'tournament.tournament'
    }),
    meta: {
      requiresHubConnection: true
    }
  },
  {
    name: 'tournamentTeams',
    path: '/tournaments/:id/teams',
    component: TournamentTeams,
    props: route => ({
      tournamentId: route.params.id,
      title: 'tournament.tournament',
    }),
    meta: {
      requiresHubConnection: true
    }
  },
  {
    name: 'tournamentEdit',
    path: '/tournaments/:id/edit',
    component: TournamentEdit,
    props: route => ({
      id: route.params.id,
      title: 'tournament.tournament'
    }),
    meta: {
      requiresHubConnection: true
    }
  },
  {
    name: 'gamePlan',
    path: '/tournaments/:id/gamePlan',
    component: TournamentGamePlan,
    props: route => ({
      tournamentId: route.params.id,
      title: 'tournament.tournament'
    }),
    meta: {
      requiresHubConnection: true
    }
  },
  {
    name: 'team',
    path: '/tournaments/:id/team',
    component: Team,
    props: route => ({
      tournamentId: route.params.id,
    }),
    meta: {
      requiresHubConnection: true
    }
  },
  {
    name: 'issues',
    path: '/tournaments/:id/issues',
    component: Issues,
    props: route => ({
      tournamentId: route.params.id,
      tite: 'tournament.tournament'
    }),
    meta: {
      requiresHubConnection: true
    }
  }
]

for(let route of routes) {
  route.beforeEnter = multi([authenticationGuard]);
}

export const globalGuards = [ensureTournamentHubConnection];
export default routes;