<template>
  <v-expansion-panels flat accordion>
    <v-expansion-panel>
      <v-expansion-panel-header class="rules-header">
        <div>{{ $t('tournament.rules') }}</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <p class="text-area">{{ rules }}</p>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'tournament-rules',
  props: {
    rules: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.text-area {
  white-space: pre-line;
  margin: 0px;
}

.rules-header {
  padding: 16px;
  font-size: 16px;
}
</style>