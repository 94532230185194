<template>
  <v-card>
    <v-card-title primary-title>
      <h1 class="headline">{{ $t("qr.qrCode") }}</h1>
    </v-card-title>
    <v-card-text>
      <v-layout column>
        <v-flex>
          <p>{{ $t("qr.matchCodeInformation") }}</p>
        </v-flex>
        <v-flex>
          <canvas class="qrCode" ref="qrCanvas" />
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text @click="close">{{ $t("common.close") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import QrCode from 'qrcode';

export default {
  name: 'qr-dialog',
  props: {
    value: Boolean,
    matchId: {
      type: String,
      required: true
    }
  },
  methods: {
    close: function() {
      this.$emit('close');
    }
  },
  mounted() {
    const matchUrl = `${window.$config.vuzzlerApp}/match/${this.matchId}`;
    QrCode.toCanvas(this.$refs.qrCanvas, matchUrl);
  }
}
</script>

<style scoped>
.qrCode {
  width: 200px !important;
  height: 200px !important;
  display: block;
  margin: 0px auto;
}
</style>
