<template>
  <div class="tournament-container">
    <tournament-toolbar :tournamentState="tournamentState" :showAdministratorTools="isTournamentAdministrator"></tournament-toolbar>
    <vue-pull-refresh :on-refresh="fetchTournament" :config="pullRefreshConfig">
      <template v-if="!isLoading">
        <inactive-tournament :tournament="tournament" :joinTeamId="joinTeamId" v-if="isInactive" @tournamentChanged="fetchTournament" />
        <active-tournament :tournament="tournament" v-else-if="isActive" @tournamentChanged="fetchTournament" />
        <finished-tournament :tournament="tournament" v-else-if="isFinished" />
        <error-message v-else @refresh="fetchTournament"/>
      </template>

      <v-layout v-if="isLoading" column wrap justify-center align-center>
        <v-flex class="my-2" shrink>
          <v-progress-circular indeterminate  color="primary"/>
        </v-flex>
      </v-layout>
    </vue-pull-refresh>
  </div>
</template>

<script>
import ActiveTournament from '@tournament/components/ActiveTournament';
import InactiveTournament from '@tournament/components/InactiveTournament';
import FinishedTournament from '@tournament/components/FinishedTournament';
import ErrorMessage from '@/components/ErrorMessage';
import TournamentToolbar from '@tournament/toolbars/TournamentToolbar';
import { TournamentState } from "@/logic/tournaments/tournamentState";
import { mapActions, mapGetters } from 'vuex';
import { ACTIONS as ShellActions } from '@/vuex/shell.module';
import { GETTERS as AuthGetters } from '@/vuex/auth.module';
import { Actions as TournamentActions, Getters as TournamentGetters } from '@tournament/states/tournament';
import VuePullRefresh from 'vue-pull-refresh';

export default {
  name: 'tournament',
  data: function() {
    return {
      isLoading: true,
      pullRefreshConfig: {
        readyLabel: this.$i18n.t('common.refresh'),
        startLabel: this.$i18n.t('common.refresh'),
        loadingLabel: this.$i18n.t('common.refresh'),
        errorLabel: ''
      },
    }
  },
  props: ['title', 'id', 'joinTeamId'],
  components: {
    ErrorMessage,
    InactiveTournament,
    ActiveTournament,
    FinishedTournament,
    VuePullRefresh,
    TournamentToolbar
  },
  computed: {
    pageTitle: function() {
      return this.$t('pageTitles.default');
    },
    isInactive: function() {
      return this.tournamentState === TournamentState.Inactive;
    },
    isActive: function() {
      return this.tournamentState === TournamentState.Active;
    },
    isFinished: function() {
      return this.tournamentState === TournamentState.Finished;
    },
    ...mapGetters({
      tournamentState: TournamentGetters.TournamentState,
      tournament: TournamentGetters.Tournament,
      userId: AuthGetters.USER_ID,
    }),
    isTournamentAdministrator() {
      return this.tournament && this.tournament.administratorId === this.userId;
    }
  },
  methods: {
    fetchTournament: function() {
      this.isLoading = true;
      this.gatherTournamentInfo(this.id).then(() => {
        this.isLoading = false;
        this.setTitle({ title: 'tournament.tournament' });
      }).catch(() => {
        this.isLoading = false;
        this.$router.back();
      });
    },
    ...mapActions({
      setTitle: ShellActions.SET_TITLE,
      resetTitle: ShellActions.RESET_TITLE,
      gatherTournamentInfo: TournamentActions.GatherTournamentInfo
    })
  },
  mounted: function() {
    this.setTitle({ title: this.$t('tournament.tournament') });
    this.fetchTournament();
  },
  destroyed: function() {
    this.resetTitle();
  }
}
</script>

<style scoped>
.tournament-container {
  height: 100%;
}
</style>
