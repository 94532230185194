<template>
  <div class="feature">
    <div class="title">{{blok.name}}</div>
    <p>{{blok.description}}</p>
  </div>
</template>

<script>
export default {
  name: 'crm-feature',
  props: ['blok']
}
</script>

<style scoped>
.title {
  margin-bottom: 4px;
}
</style>