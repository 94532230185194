import Axios from "axios";

export function getPlayer(playerId) {
  return Axios.get(`${window.$config.vuzzlerApi}/api/players/${playerId}`);
}

export function updatePlayer(playerId, playerSettings) {
  return Axios.put(`${window.$config.vuzzlerApi}/api/players/${playerId}/profile`, playerSettings);
}

export function getAvatar(playerId) {
  return Axios.get(`${window.$config.vuzzlerApi}/api/players/${playerId}/avatar/current`);
}

export function getAvatars(playerId) {
  return Axios.get(`${window.$config.vuzzlerApi}/api/players/${playerId}/avatar`);
}

export function uploadAvatar(playerId, file) {
  return Axios.post(`${window.$config.vuzzlerApi}/api/players/${playerId}/avatar`, file);
}

export function setAvatar(playerId, avatarId) {
  return Axios.put(`${window.$config.vuzzlerApi}/api/players/${playerId}/avatar/${avatarId}`);
}
