<template>
  <v-app-bar app dark class="primary">
    <v-btn dark text icon @click="navigateBack">
      <v-icon>arrow_back</v-icon>
    </v-btn>   

    <v-toolbar-title>
      {{ $t('tournament.editTournament') }}
    </v-toolbar-title>

    <v-spacer />
    <v-btn :disabled="disableSave" text @click="saveTournament">{{ $t('common.save') }}</v-btn>
  </v-app-bar>
</template>

<script>
export default {
  name: 'tournament-edit-toolbar',
  props: {
    disableSave: Boolean,
    default: () => false
  },
  methods: {
    saveTournament: function() {
      this.$emit('save')
    },
    navigateBack: function() {
      this.$router.back();
    }
  }
}
</script>
