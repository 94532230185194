<template>
  <div>
    <component :blok="story.content" :is="story.content.component"></component>
  </div>
</template>

<script>
import { storyblokMixin } from "@crm/mixins/storyblokMixin";

export default {
  name: 'landing-page',
  mixins: [ storyblokMixin ],
  created () {
    this.initialize('landingpage');
  }
}
</script>
