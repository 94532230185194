<template>
  <div v-html="renderedBlok"></div>
</template>

<script>
import { storyblokMixin } from '../mixins/storyblokMixin';

export default {
  name: 'crm-content',
  props: ['blok'],
  mixins: [storyblokMixin],
  computed: {
    renderedBlok: function() {
      return this.storyapi.richTextResolver.render(this.blok.content);
    }
  }
}
</script>