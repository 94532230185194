<template>
  <section class="tournament-headline">
    <div class="tournament-icon">
      <svg-icon name="trophy" size="40px" color="yellow-base" />
    </div>
    <header class="content">
      <h1 class="headline tournament-title">{{name}}</h1>
      <div class="details">
        <v-chip class="state-chip" :color="stateColor" small>{{$t(`tournament.state.${state}`)}}</v-chip>
      </div>
    </header>
    <v-btn v-if="shareable" icon @click="share" class="ml-2">
      <v-icon size="40px">share</v-icon>
      <v-dialog v-model="isShareDialogVisible">
        <join-tournament-dialog :tournamentUrl="tournamentUrl" @close="isShareDialogVisible = false" />
      </v-dialog>
    </v-btn>
  </section>
</template>

<script>
import { TournamentState } from '@/logic/tournaments/tournamentState';
import JoinTournamentDialog from '@tournament/components/JoinTournamentDialog';

const LIGHT_BLUE = '#8dc3f5'

export default {
  name: 'tournament-header',
  data: function() {
    return {
      isShareDialogVisible: false
    }
  },
  props: {
    name: {
      type: String,
      required: true
    },
    state: TournamentState,
    shareable: {
      type: Boolean,
      default: false
    },
    tournamentId: {
      type: String,
      required: true
    }
  },
  methods: {
    share() {
      this.isShareDialogVisible = true;
    }
  },
  computed: {
    stateColor() {
      return this.state === TournamentState.Active ? 'yellow' :
        this.state === TournamentState.Inactive ? LIGHT_BLUE :
        this.state === TournamentState.Finished ? 'green' :
        'red';
    },
    tournamentUrl: function() {
      return `${window.$config.vuzzlerApp}/tournaments/${this.tournamentId}`;
    },
  },
  components: {
    JoinTournamentDialog,
  }
}
</script>

<style>
.tournament-headline {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tournament-icon {
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 359px) {
  .tournament-icon {
    display: none !important;
  }
}

.state-chip {
  margin-left: 0px !important;
}

.yellow {
  background-color: var(--v-yellow-base);
}

.tournament-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.content {
  overflow: hidden;
  flex-grow: 1;
}
</style>
