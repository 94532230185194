<template>
  <v-input :value="value" v-bind="$attrs" :hide-details="hideDetails" class="mb-2">
    <div class="editor-input">
      <label v-if="title" class="body-1 input-label">{{ title }}:</label>

      <div class="number-input-container" >
        <input type="button" value="-" class="counter-button left" @click="decrement"/>
        <div class="input-control-box">
          <input type="number" :min="min" :max="max" step="1" :value="value" @input="handleInput" class="input-control"/>
        </div>
        <input type="button" value="+" class="counter-button right" @click="increment"/>
      </div>
    </div>
  </v-input>
</template>

<script>
export const INPUT_MODE = {
  NORMAL: 'normal',
  EXPONENTIAL: 'exponential'
};

export default {
  name: 'number-input',
  props: {
    value: {
      type: Number,
      default: () => 0
    },
    min: {
      type: Number,
      default: () => undefined
    },
    max: {
      type: Number,
      default: () => undefined
    },
    mode: {
      type: String,
      default: () => INPUT_MODE.NORMAL
    },
    hideDetails: Boolean,
    title: String
  },
  methods: {
    handleInput(event) {
      this.$emit('input', +event.target.value)
    },
    increment() {
      const nextValue = this.calculateNextValue(this.value, this.mode);
      if (this.max != undefined && nextValue > this.max) return;
      this.$emit('input', nextValue)
    },
    decrement() {
      const nextValue = this.calculatePrevValue(this.value, this.mode);
      if (this.min != undefined && nextValue < this.min) return;
      this.$emit('input', nextValue)
    },
    calculateNextValue(value, mode) {
      let nextNumber = value;
      switch(mode) {
        case INPUT_MODE.EXPONENTIAL:
          var nextBase = Math.floor(Math.log2(value)+1);
          nextNumber = Math.pow(2, nextBase);
          if (isNaN(nextNumber)) nextNumber = this.min;
          break;
        default:
          nextNumber = value+1;
      }
      return nextNumber < this.min ? this.min :
             nextNumber > this.max ? this.max :
             nextNumber;
    },
    calculatePrevValue(value, mode) {
      let nextNumber = value;
      switch(mode) {
        case INPUT_MODE.EXPONENTIAL:
          var prevBase = Math.ceil(Math.log2(value)-1);
          nextNumber = Math.pow(2, prevBase);
          if (isNaN(nextNumber)) nextNumber = this.min;
          break;
        default:
          nextNumber = value-1;
      }

      return nextNumber < this.min ? this.min :
             nextNumber > this.max ? this.max :
             nextNumber;
    }
  }
}
</script>

<style scoped>
.input-control {
  width: 100%;
  padding: 0 8px;
}

.counter-button {
  padding: 0 12px;
  background-color: var(--v-primary-lighten1);
  color: white !important;
} 

.counter-button.left {
  border-radius: 4px 0 0 4px;
}

.counter-button.right {
  border-radius: 0 4px 4px 0;
}

.number-input-container {
  border: 1px solid var(--v-grey-base);
  border-radius: 4px;
  align-items: stretch;
  display: flex;
  flex-direction: row;
  width: 105px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.input-container {
  padding: 0;
}

.input-control-box {
  min-width: 0;
  width: 100%;
}

.input-control {
  padding: 8px;
  line-height: 20px;
}

.editor-input {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input-label {
  line-height: 34px;
}
</style>