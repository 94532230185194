<template>
  <v-card>
    <v-card-title class="title">{{ $t('info.update.requiredTitle') }}</v-card-title>
    <v-card-text>{{ $t('info.update.requiredText') }}</v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'update-recommended',
}
</script>