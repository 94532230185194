<template>
  <div>
    <div class="stage-connection start primary lighten-1 white--text">
      {{startingTeams}} {{startingTeams === 1 ? $t('tournament.team') : $t('tournament.teams')}} {{ $t('tournament.gamePlan.starting') }}
      <v-icon @click="removeStage" color="white">cancel</v-icon>
    </div>

    <div class="stage-body">
      <h1 class="subtitle-1 text-xs-center pb-2">{{ $t('tournament.groupStage') }}</h1>

      <number-input :rules="[atLeast1Rule]" :error-messages="invalidRounds" :title="$t('tournament.gamePlan.fields.rounds')"
        :min="1" :value="value.rounds" @input="roundsUpdated" />

      <number-input :rules="[atLeast1Rule]" :title="$t('tournament.gamePlan.fields.groups')"
        :min="1" :value="value.groups.length" @input="groupCountUpdated" />

      <div class="group-editor">
        <div class="group-headline">
          <label class="name-headline">{{ $t('tournament.gamePlan.fields.name') }}</label>
          <label class="input-headline">{{ $t('tournament.gamePlan.fields.starting') }}</label>
          <label class="input-headline">{{ $t('tournament.gamePlan.fields.advancing') }}</label>
        </div>
        <div v-if="value.groups.length === 0">
          <p class="hint">{{$t('tournament.gamePlan.noGroupsAdded')}}</p>
        </div>
        <group-editor v-else v-for="(group, idx) in value.groups" :key="idx"
          :errors="errors && errors.Groups &&  errors.Groups[idx]"
          :value="group" @input="groupsUpdated($event, idx)"></group-editor>
      </div>
    </div>

    <div class="stage-connection end primary lighten-1 white--text">
      {{advancingTeams}} {{advancingTeams === 1 ? $t('tournament.team') : $t('tournament.teams')}} {{ $t('tournament.gamePlan.advancing') }}
    </div>
  </div>
</template>

<script>
import GroupEditor from '@tournament/components//GroupEditor';
import { toBijectiveBase26 } from '@/logic/common/numbers';
import { equalOrLargerThan } from '@/logic/common/inputValidationRules';

const REMOVE_EVENT = 'remove';
const INPUT_EVENT = 'input';

export default {
  name: 'group-stage-editor',
  props: {
    value: Object,
    errors: {
      type: Object,
      default: () => {},
    }
  },
  computed: {
    startingTeams() {
      return this.value.groups.map(group => group.starting).reduce((prev, curr) => prev+curr, 0);
    },
    advancingTeams() {
      return this.value.groups.map(group => group.advancing).reduce((prev, curr) => prev+curr, 0);
    },
    invalidRounds() {
      return this.errors &&
        this.errors.Rounds &&
        this.errors.Rounds.map(errorCode => this.$t(`errorCodes.${errorCode}`));
    }
  },
  mounted: function() {
    if (this.value.groups.length === 0) {
      this.addInitialGroup();
    }
  },
  methods: {
    atLeast1Rule: equalOrLargerThan(1),
    updateStage(stage) {
      this.$emit(INPUT_EVENT, stage);
    },
    removeStage() {
      this.$emit(REMOVE_EVENT);
    },
    roundsUpdated(rounds) {
      const newStage = { ...this.value };
      newStage.rounds = +rounds;
      this.updateStage(newStage);
    },
    groupCountUpdated(nrOfGroups) {
      const previousNrOfGroups = this.value.groups.length;
      const groupDiff = nrOfGroups-previousNrOfGroups;
      const newStage = { ...this.value };
      newStage.groups = [ ...this.value.groups ];
      if (groupDiff >= 0) {
        for(let i = 0; i < groupDiff; ++i) {
          newStage.groups.push(this.createGroup(previousNrOfGroups+i+1));
        }
      } else {
        for(let i = 0; i < -groupDiff; ++i) {
          newStage.groups.pop();
        }
      }
      this.updateStage(newStage);
    },
    groupsUpdated(group, idx) {
      const precessors = this.value.groups.slice(0, idx);
      const successors = this.value.groups.slice(idx+1);
      const newStage = { ...this.value };
      newStage.groups = [...precessors, group, ...successors]
      this.updateStage(newStage);
    },
    addInitialGroup() {
      var newGroup = this.createGroup(1);

      const newStage = { ...this.value };
      newStage.groups.push(newGroup);
      this.updateStage(newStage);
    },
    createGroup(idx) {
      const lastValue = this.value.groups[this.value.groups.length-1];
      return {
        name: toBijectiveBase26(idx),
        starting: lastValue != undefined ? lastValue.starting : 4,
        advancing: lastValue != undefined ? lastValue.advancing : 2
      }
    }
  },
  components: {
    GroupEditor
  }
}
</script>

<style scoped>
.stage-connection {
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.stage-connection.start {
  border-radius: 4px 4px 0 0;
}

.stage-connection.end {
  border-radius: 0 0 4px 4px;
}

.stage-body {
  padding: 8px;
}

.editor-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.group-editor {
  border-top: 1px solid var(--v-background-darken1);
  padding-top: 8px;
  margin-top: 8px;
}

.group-headline {
  display: flex;
  flex-direction: row;
}

.group-headline * {
  padding-left: 4px;
}

.name-headline {
  flex: 1 0 auto;
}

.input-headline {
  width: 105px;
  padding-left: 6px;
}

.hint {
  color: var(--v-background-darken3);
  padding: 4px;
}
</style>