<template>
  <v-card>
    <v-card-title>
      <h1 class="headline"> {{ $t("match.choosePositionTitle") }}</h1>
    </v-card-title>

    <v-card-text>
      <v-layout class="selectionWrapper" align-center column>
        <position-selection v-model="position" :player="currentPlayer" :occupiedPositions="positions" />
      </v-layout>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn text @click="spectationPositionSelected">{{ $t("match.onlySpectating") }}</v-btn>
      <v-btn color="primary" @click="positionSelected" :disabled="position == null">{{ $t("common.ok") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import PositionSelection from '../PositionSelection';
import { mapGetters } from 'vuex';
import { GETTERS } from '../../vuex/auth.module';
import { playerDataMixin } from '../../modules/core/mixins/playerDataMixin';

export default {
  name: 'position-selection-dialog',
  mixins: [playerDataMixin],
  data: () => ({
    position: undefined,
    defaultAvatar: require('@/assets/images/default-avatar.png')
  }),
  props: {
    positions: undefined
  },
  watch: {
    '$route.query.pos':{
      immediate: true,
      handler: function(pos) {
        this.position = parseInt(pos, 10);
      }
    }
  },
  computed: {
    currentPlayer: function() {
      return {
        id: this.id, 
        image: this.profileImage
      }
    },
    ...mapGetters({
      id: GETTERS.USER_ID,
    }),
  },
  methods: {
    close: function() {
      this.$emit('close');
    },
    positionSelected: function() {
      this.close();
      this.$emit('positionSelected', this.position);
    },
    spectationPositionSelected: function() {
      this.close();
      this.$emit('positionSelected', 100);
    }
  },
  components: {
    PositionSelection
  },
}
</script>

<style scoped>
.selectionWrapper {
  height: 230px;
}
</style>
