<template>
  <v-card>
    <v-card-title>
      <h1 class="title">{{ $t('tournament.editTeam') }}</h1>
    </v-card-title>
    <v-card-text>
      <v-text-field
        class="mb-1"
        v-if="isAdministrator"
        :label="$t('tournament.teamName')"
        v-model="teamName"
        :rules="[minTeamNameRule, maxTeamNameRule]"
        clearable
        required
      />
      <section class="invite-players">
        <p>{{ $t('tournament.joinTeamDescription') }}</p>
        <qr-code class="mx-auto" :value="joinTeamLink" />
        <shareable-text :text="joinTeamLink" type="url" :label="$t('tournament.teamUrl')"></shareable-text>
      </section>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text @click="close">{{ $t('common.close')}}</v-btn>
      <v-btn text color="primary" @click="update" v-if="isAdministrator">{{ $t('common.save')}}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import QrCode from '@/components/QrCode';
import { mapGetters } from 'vuex';
import { GETTERS as AuthGetters } from '@/vuex/auth.module';
import { shellInteractorMixin } from '@core/mixins/shellInteractorMixin';
import { minLength, maxLength } from '@/logic/common/inputValidationRules';
import ShareableText from '@core/components/ShareableText';

export default {
  name: 'team-edit',
  mixins: [
    shellInteractorMixin
  ],
  data: () => ({
    teamName: ''
  }),
  props: {
    team: Object,
    tournamentId: String,
    onUpdateTeam: {
      type: Function,
      required: true
    }
  },
  mounted: function() {
    this.teamName = this.team.info.name;
  },
  computed: {
    isAdministrator: function() {
      return this.team.administratorId === this.userId;
    },
    joinTeamLink() {
      return `${window.$config.vuzzlerApp}/tournaments/${this.tournamentId}?join=${this.team.info.id}`;
    },
    ...mapGetters({
      userId: AuthGetters.USER_ID
    })
  },
  methods: {
    minTeamNameRule: minLength(1),
    maxTeamNameRule: maxLength(80),
    update() {
      this.onUpdateTeam({
        name: this.teamName
      }).then(() => {
        this.$emit('close');
      }).catch(() => {
        this.showError(this.$t('tournament.error.updateTeam'));
      });
    },
    close() {
      this.$emit('close');
    },
  },
  components: {
    QrCode,
    ShareableText
  }
}
</script>
