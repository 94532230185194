import { GETTERS as AuthGetters } from '@/vuex/auth.module';
import { store } from '@/vuex/store';
import { NavigationGuard, Route } from 'vue-router';
import { ANY_PLAYER_FILTER } from '../constants';

export const noEmptyStatisticGuard: NavigationGuard = (to, from, next) => {
  // when the query param is declared but not provided the default values should still be inserted.
  // eg. /statistic?home&away
  // therefore null and undefined must be considered (== null instead of === null)
  if (to.query.one == null && to.query.two == null && to.query.three == null && to.query.four == null) {
    const userId = store.getters[AuthGetters.USER_ID];
    next({
      name: 'statistic',
      query: {
        one: userId,
        three: ANY_PLAYER_FILTER
      },
    })
  } else {
    next();
  }
}