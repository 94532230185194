<template>
  <v-layout column justify-center align-center>
    <v-flex shrink>
      <v-progress-circular color="primary" size="60" indeterminate>

      <v-icon color="primary" x-large>{{ icon }}</v-icon>
      </v-progress-circular>
    </v-flex>
    <v-flex shrink class="loadingText subtitle-1">
      {{ text }}
    </v-flex>
    <v-flex shrink class="fallbackText" v-if="fallbackText && showFallback">
      {{ fallbackText }}
    </v-flex>
  </v-layout>
</template>

<script>
const TIMEOUT_IN_MS = 10 * 1000;

import { setTimeout } from 'timers';
export default {
  name: 'loading',
  data: () => ({
    showFallback: false
  }),
  props: {
    icon: String,
    text: String,
    fallbackText: String
  },
  mounted: function() {
    setTimeout(() => {
      this.showFallback = true;
    }, TIMEOUT_IN_MS)
  }
}
</script>

<style scoped>
.loadingText {
  margin-top: 8px;
  color: var(--v-primary-base);
}

.fallbackText {
  text-align: center;
  color: var(--v-error-base);  
}
</style>