<template>
  <div>
    <div class="stage-connection start primary lighten-1 white--text">
      {{value.starting}} {{value.starting === 1 ? $t('tournament.team') : $t('tournament.teams') }} {{ $t('tournament.gamePlan.starting') }}
      <v-icon @click="removeStage" color="white">cancel</v-icon>
    </div>

    <div class="stage-body">
      <h1 class="subtitle-1 text-xs-center pb-2">{{ $t('tournament.knockoutStage') }}</h1>

      <div class="editor-input">
        <label>{{ $t('tournament.gamePlan.fields.rematchRequired') }}:</label>
        <v-switch class="switch-input" v-model="isRematchRequired" />
      </div>

      <number-input :error-messages="invalidStartingPlayers" :title="$t('tournament.gamePlan.fields.starting')"
        :min="value.advancing" :value="value.starting" @input="startingUpdated" :mode="exponentialInputMode" />

      <number-input :error-messages="invalidAdvancingPlayers" :title="$t('tournament.gamePlan.fields.advancing')" :rules="[smallerThanStartingNumber]" class="number-input"
        :min="1" :max="value.starting" :value="value.advancing" @input="advancingUpdated" :mode="exponentialInputMode" />

    </div>

    <div class="stage-connection end primary lighten-1 white--text">
      {{value.advancing}} {{ value.advancing === 1 ? $t('tournament.team') : $t('tournament.teams') }} {{ $t('tournament.gamePlan.advancing') }}
    </div>
  </div>
</template>

<script>
import { INPUT_MODE } from '@shared/components/NumberInput.vue';
const REMOVE_EVENT = 'remove';
const INPUT_EVENT = 'input';

export default {
  name: 'knockout-stage-editor',
  data: () => ({
    isRematchRequired: false,
    exponentialInputMode: INPUT_MODE.EXPONENTIAL
  }),
  props: {
    value: Object,
    errors: {
      default: () => {},
    }
  },
  computed: {
    invalidAdvancingPlayers() {
      if (this.errors && this.errors.Advancing) {
        return this.errors.Advancing.map(errorCode => this.$t(`errorCodes.${errorCode}`));
      } else {
        return null;
      }
    },
    invalidStartingPlayers() {
      return this.errors && 
        this.errors.Starting && this.errors.Starting.map(errorCode => {
          return this.$t(`errorCodes.${errorCode}`)
        });
    }
  },
  methods: {
    smallerThanStartingNumber(value) {
      return value > this.value.starting
        ? this.$t("common.validation.equalOrSmaller", { value: this.value.starting })
        : true
    },
    updateStage(stage) {
      this.$emit(INPUT_EVENT, stage);
    },
    removeStage() {
      this.$emit(REMOVE_EVENT);
    },
    rematchRequiredUpdated(value) {
      const newStage = { ...this.value };
      newStage.isRematchRequired = value;
      this.updateStage(newStage);
    },
    startingUpdated(value) {
      const newStage = { ...this.value };
      newStage.starting = value;
      this.updateStage(newStage);
    },
    advancingUpdated(value) {
      const newStage = { ...this.value };
      newStage.advancing = value;
      this.updateStage(newStage);
    }
  },
  watch: {
    // value and isRematchRequired watcher are required to keep the according prop in sync with the state (data).
    // v-switch only supports the usage of v-model to set a value and react to changes. Somehow value and event binding does not work.
    // Since adding a deep prop value with v-model doesn't work we need to bind a local state to it. This needs to be in sync with the prop though.
    value: {
      handler: function(newVal) {
        this.isRematchRequired = newVal.isRematchRequired;
      },
      immediate: true
    },
    isRematchRequired: function(newVal) {
      this.rematchRequiredUpdated(newVal);
    }
  }
}
</script>

<style scoped>
.stage-connection {
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.stage-connection.start {
  border-radius: 4px 4px 0 0;
}

.stage-connection.end {
  border-radius: 0 0 4px 4px;
}

.stage-body {
  padding: 8px;
}

.editor-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.switch-input {
  max-width: 105px;
  margin-top: 0;
  padding-top: 0;
}

.error--text {
  display: block;
}
</style>