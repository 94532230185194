<template>
    <div class="spinner-container">
        <v-progress-circular class="spinner" indeterminate />
    </div>
</template>

<script>
export default {
    name: 'ProgressSpinner'
}
</script>


<style scoped>
.spinner-container {
    padding: 8px;
}

.spinner-container .spinner {
    margin: auto;
}
</style>
