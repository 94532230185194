import { parseMatchUrl, parseVenueUrl, parseTournamentUrl } from './pathParser';

/**
 * Parse the deep link provided by the url param and create a router link.
 * If the deep link can't be interpreted null will be returned.
 * @param {string} url describes the deeplink.
 */
export function parseDeepLink(url) {
    const matchUrl = parseMatchUrl(url);
    if (matchUrl !== null) {
      return matchUrl;
    }

    const venueUrl = parseVenueUrl(url);
    if (venueUrl !== null) {
      return venueUrl;
    }

    const tournamentUrl = parseTournamentUrl(url);
    if (tournamentUrl !== null) {
      return tournamentUrl;
    }

    return null;
}