<template>
  <div v-editable="blok">
    <template v-for="(item, index) in blok.body">
      <div :class="theme[index % theme.length]" :key="item._uid">
        <component 
          :blok="item"
          :is="item.component"
          :class="{ area: !isHeaderOrFooter(item), 'pt-16': !isHeaderOrFooter(item), 'pb-8': !isHeaderOrFooter(item) }"
          >
        </component>
      </div>
    </template>
  </div>
</template>

<script>
import HeaderComponent from './Header.vue';
import FooterComponent from './Footer.vue';

const headerName = HeaderComponent.name;
const footerName = FooterComponent.name

export default {
  name: 'crm-landing',
  props: ['blok'],
  data: () => ({
    theme: [
      'primary white--text',
      'white black--text',
    ]
  }),
  methods: {
    isHeaderOrFooter: function(blok) {
      return blok.component === headerName || blok.component === footerName;
    }
  },
}
</script>

<style scoped>
  .area {
    width: 75%;
    margin: 0 auto;
  }
</style>
