<template>
<section v-if="winner != null" column class="announcement">
  <h2 class="subtitle">{{ $t("tournament.winnerAnnouncement") }}:</h2>
  <svg-icon name="trophy" class="trophy mx-auto" color="yellow" />
  <p class="headline">{{ winner.info.name }}</p>
</section>
</template>

<script>
export default {
  name: 'winner-announcement',
  props: {
    winner: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.announcement {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.announcement>*:not(:last-child) {
  margin-bottom: 8px;
}

.trophy {
  display: flex;

  width: 25%;
  max-width: 200px;
}
</style>