import { mapActions } from "vuex";
import { ACTIONS } from "../../../vuex/shell.module";

export const shellInteractorMixin = {
  methods: {
    ...mapActions({
      showError: ACTIONS.SHOW_ERROR,
      setTitle: ACTIONS.SET_TITLE,
      resetTitle: ACTIONS.RESET_TITLE
    })
  }
}