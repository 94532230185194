<template>
  <div>
    <v-app-bar-nav-icon
      color="white" class="d-sm-none"
      v-if="!isMenuActive"
      @click="openMenu"
    />
    <v-overlay
      absolute class="menu-overlay" opacity="0.9"
      :value="isMenuActive"
    >
      <v-btn
        icon
        @click="closeMenu"
        class="close-button"
      >
        <v-icon>close</v-icon>
      </v-btn>
      <div class="content" @click="closeMenu">
        <slot></slot>
      </div>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: 'landing-page-menu',
  data: () => ({
    isMenuActive: false
  }),
  methods: {
    openMenu: function() {
      this.isMenuActive = true;
    },
    closeMenu: function() {
      this.isMenuActive = false;
    }
  }
}
</script>

<style scoped>
/deep/ .content > * {
  display: block;
  text-transform: capitalize !important;
  font-size: 2.125rem !important;
  font-weight: 400;
  line-height: 2.5rem;
  letter-spacing: .0073529412em !important;
  font-family: "Roboto",sans-serif !important;
}

.menu-overlay {
  height: 100vh;
}

.menu-overlay .close-button {
  position: fixed;
  right: 16px;
  top: 4px;
}

/deep/ .content > *:not(:last-child) {
  margin-bottom: 25px;
}
</style>