import StatisticSelection from '@statistic/pages/StatisticSelection/StatisticSelection.vue';
import Statistic from '@statistic/pages/Statistic/Statistic.vue';
import { authenticationGuard } from '@/routes/guards/authenticationGuard';
import { noEmptyStatisticGuard } from '@/modules/statistic/guards/no-empty-statistic-guard';
import { guards as multi } from '@/logic/guardsHelper';
import { noEmptyPlayerParamGuard } from './guards/no-empty-player-param-guard';

const routes = [
  {
    name: 'statisticSelection',
    path: '/statistics',
    component: StatisticSelection,
    beforeEnter: authenticationGuard
  },
  {
    name: 'statistic',
    path: '/statistic/',
    component: Statistic,
    props: statisticQueryToProps,
    beforeEnter: multi([noEmptyPlayerParamGuard, noEmptyStatisticGuard, authenticationGuard])
  }
]

/**
 * Create the props for the statistic page component based on the vue-router route object.
 * @param {VueRouter.Route} route - the route object provided by the vue-router library
 * @returns the prop object for the statistic component
 */
function statisticQueryToProps(route) {
  return {
    one: route.query.one,
    two: route.query.two,
    three: route.query.three,
    four: route.query.four,
  }
}

export default routes;
