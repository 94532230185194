<template>
  <div class="footer white--text">
    <div class="top-separator">
      <div class="foosball-figure" />
      <div class="foosball-figure right" />
    </div>

    <div class="vuzzler-links text-center mb-8">
      <router-link class="link white--text" :to="{ name: 'about'}" title="About">{{ $t('shell.drawer.about') }}</router-link>
      <router-link class="link white--text" :to="{ name: 'privacy'}" title="Privacy">{{ $t('shell.drawer.privacy') }}</router-link>
    </div>

    <div class="social-links">
      <div class="links">
        <a class="social-link" href="https://www.instagram.com/vuzzler/">
          <img src="~@/assets/images/social/instagram-White_100.png" alt="instagram">
        </a>
        <a class="social-link" href="https://facebook.com/vuzzler">
          <img class="link" src="~@/assets/images/social/f_logo_RGB-White_100.png" alt="facebook">
        </a>
      </div>
    </div>
    <p class="mt-8 pa-1 mb-0">© Copyright {{ currentYear }} - <a href="https://csiber.at/" target="_blank" rel="noopener">Christian Siber</a></p>
  </div>
</template>

<script>
export default {
  name: 'crm-footer',
  props: ['blok'],
  data: () => ({
    currentYear: new Date().getFullYear()
  })
}
</script>

<style scoped>
.made-with {
  margin-left: auto;
  margin-right: auto;
  color: white;
}

.footer {
  background-color: var(--v-primary-base);
}

.top-separator {
  position: relative;
  height: 40px;
}

.foosball-figure {
  position: absolute;
  top: -1px;
  height: 140px;

  background-image: url('~@/assets/images/foosball-figures/foosball-figure-bottom.svg');
  background-position-y: top;
  width: 25%;
}

.foosball-figure.right {
  right: 0;
  transform: scaleX(-1);
}

.vuzzler-links .link {
  display: block;
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.social-link {
  margin: 0 8px;
}

.social-link>img {
  height: 32px;
  width: 32px;
}
</style>