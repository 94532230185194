<template>
  <v-layout column>
    <div id="firebaseui-auth-container" ref="firebase"></div>
    <div class="account-creation">{{ $t('auth.needAccount') }} <a @click="signInWithEmail">{{ $t('auth.createAccount') }}</a></div>
  </v-layout>
</template>

<script>
import { ACTIONS as AuthActions } from '../vuex/auth.module';
import { mapActions } from 'vuex';
import firebase from 'firebase/app';
import * as firebaseui from 'firebaseui';
import { ensurePlayerExists } from '../services/vuezlerApi/player.service';
import { ACTIONS as ShellActions } from '../vuex/shell.module';
import { ACTIONS as PlayerActions } from '../vuex/playerModule';

export default {
  name: 'userAuth',
  data: () => ({
    hasLoginFailed: false,
  }),
  methods: {
    ...mapActions({
      setAuthToken: AuthActions.SET_AUTH_TOKEN,
      signOut: AuthActions.SIGN_OUT,
      showError: ShellActions.SHOW_ERROR,
      updateProfileImage: PlayerActions.UPDATE_PROFILE_IMAGE
    }),
    registerUser: function(user) {
      const newUser = {
        id: user.uid,
        email: user.email,
        fullName: user.displayName,
        profileImage: user.photoURL
      };
      return ensurePlayerExists(newUser);
    },
    redirect: function() {
      const redirectUrl = this.$route.query.redirect || '/';
      this.$router.push(redirectUrl);
    },
    initializeFirebaseUI: function() {
      var uiConfig = {
          callbacks: {
            signInSuccessWithAuthResult: (authResult) => {
              authResult.user.getIdToken().then(token => {
                this.setAuthToken(token);
                this.registerUser(authResult.user).then(() => {
                  this.redirect();
                }).catch(() => {
                  firebase.auth().signOut();
                  this.signOut();
                  this.redirect();
                  this.showError(this.$t('auth.loginError'));
                });
              })
            },
          },
          signInFlow: 'redirect',
          signInOptions: [
            {
              provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
              customParameters: {
                prompt: 'select_account'
              }
            },
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            {
              provider: 'apple.com',
              providerName: 'Apple',
              iconUrl: 'static/img/idp/apple-256.png', // shouldn't be provided via webpack
              // because a image reference is required, and webpack might bundle smaller images as base64.
              buttonColor: '#000000',
            },
            {
              provider: 'microsoft.com',
              providerName: 'Microsoft',
              iconUrl: 'static/img/idp/windows-256.png', // shouldn't be provided via webpack
              // because a image reference is required, and webpack might bundle smaller images as base64.
              buttonColor: '#00A1F1',
            },
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
          ],
          credentialHelper: firebaseui.auth.CredentialHelper.NONE
        };
      this.$firebaseui.start('#firebaseui-auth-container', uiConfig);
    },
    signInWithEmail: function() {
      const emailLogin = this.$refs.firebase.querySelector("[data-provider-id='password']");
      if (emailLogin) {
        emailLogin.click();
      }
    }
  },
  mounted: function() {
    this.initializeFirebaseUI();
    
  }
}
</script>

<style scoped>
  .login-button-logo {
    position: absolute;
    left: 0px;
    height: 20px;
    width: 20px;
  }

  .account-creation {
    margin-top: 8px;
  }
</style>
