<template>
    <v-container v-editable="blok" grid-list-xl id="features">
      <h1 class="display-1 text-center mb-4 blok-title">{{ blok.title }}</h1>
      <v-layout wrap>
        <v-flex xs12 sm6 v-for="item in blok.features" :key="item._uid">
          <component :blok="item" :is="item.component"></component>
        </v-flex>
      </v-layout>
    </v-container>
</template>

<script>
export default {
  name: 'crm-feature-list',
  props: ['blok' ]
}
</script>

<style scoped>
.headline {
  text-align: center;
}
</style>