/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nfc': {
    width: 510,
    height: 510,
    viewBox: '0 0 510 510',
    data: '<path pid="0" d="M459 0H51C22.95 0 0 22.95 0 51v408c0 28.05 22.95 51 51 51h408c28.05 0 51-22.95 51-51V51c0-28.05-22.95-51-51-51zm0 459H51V51h408v408zm-51-357H280.5c-28.05 0-51 22.95-51 51v58.65C214.2 219.3 204 237.15 204 255c0 28.05 22.95 51 51 51s51-22.95 51-51c0-17.85-10.2-35.7-25.5-43.35V153H357v204H153V153h51v-51H102v306h306V102z"/>'
  }
})
