




















import Vue, { PropType } from 'vue'
import { PlayerFilter } from '../../models/player-filter'
import { queryPlayer } from '@/services/vuezlerApi/player.service'
import Avatar from '@core/components/Avatar.vue';
import { getPlayer } from '@core/services/playerService';
import { GlobalEvents } from '@shared/global-events';
import debounce from 'lodash.debounce';
import { ANY_PLAYER_FILTER } from '../../constants';

const DEBOUNCE_DELAY = 500;

interface PlayerSelectionData {
  selectedPlayerId: String | null;
  // The value gets null when a selected element gets cleared and
  // undefined when the search text gets cleared
  playerQuery: String | null | undefined;
  queriedPlayers: PlayerFilter[],
  defaultFilter: PlayerFilter[]
  isLoading: Boolean
}

/**
 * A Autocomplete component to select players.
 * Additionally to the player id the 'any' keyword is a valid value as well and acts as a player wildcard.
 */
export default Vue.extend({
  name: 'playerSelection',
  data: function(): PlayerSelectionData {
    return {
      selectedPlayerId: null,
      playerQuery: undefined,
      queriedPlayers: [],
      isLoading: false,
      defaultFilter: [
        {
          id: ANY_PLAYER_FILTER,
          name: this.$t('statistics.selection.any').toString(),
        },
      ]
    }
  },
  props: {
    /**
     * The id of the currently selected player or 'any'.
     * @values <player-id>, any
     */
    value: String,
    /**
     * A optional list of player ids that should not be suggested for selection.
     */
    ignore: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  computed: {
    selectionItems: function(): PlayerFilter[] {
      return [
        ...this.queriedPlayers,
        ...this.defaultFilter
      ]
    }
  },
  methods: {
    /**
     * Update the autocomplete suggestions based on the provided query.
     * 
     * playerQuery contains a query for the player.
     */
    updateSuggestions: async function (playerQuery: string) {
      this.isLoading = true;
      
      let response;
      try {
        response = await queryPlayer(playerQuery);
      } finally {
        this.isLoading = false;
      }

      if (response.status === 200) {
        const players = response
          .data
          .filter(({ id }) => this.ignore.indexOf(id) === -1) as PlayerFilter[];
        this.queriedPlayers.splice(0, this.queriedPlayers.length, ...players);
      }
    },
    debouncedUpdateSuggestions: debounce(function (this: any, playerQuery: string) {
      this.updateSuggestions(playerQuery);
    }, DEBOUNCE_DELAY),
    /**
     * Change the currently selected player
     *
     * player contains the player that should be selected
     */
    emitSelection: function(playerId: string | null): void {
      /**
       * The currently selected player has changed
       * 
       * @event input
       * @property {string | null} the id of the newly selected player
       */
      this.$emit(GlobalEvents.Input, playerId);
    },
  },
  watch: {
    playerQuery: async function(query: string | null | undefined): Promise<void> {
      if (query == null || query.length <= 0) {
        return;
      }

      await this.debouncedUpdateSuggestions(query);
    },
    value: {
      handler: async function(playerId: string | null): Promise<void> {
        this.selectedPlayerId = playerId;

        // the currently selected player might be undefined when none is preselected.
        if (playerId === null || playerId === ANY_PLAYER_FILTER) {
          return;
        }

        try {
          const response = await getPlayer(playerId)
          if (response.status === 200) {
            this.queriedPlayers.splice(0, this.queriedPlayers.length, response.data)
          }
        } catch (e) {
          this.emitSelection(null);
        }
      },
      immediate: true
    }
  },
  components: {
    Avatar
  },
})
