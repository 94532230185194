<template>
  <v-layout column>
    <v-flex class="icon">
      <v-icon large>cloud_off</v-icon>
    </v-flex>
    <v-flex>
      {{ $t("shell.error.description") }}
    </v-flex>
    <v-layout row>
      <v-spacer />
      <v-btn text @click="refresh">{{ $t("shell.error.refresh") }}</v-btn>
    </v-layout>
  </v-layout>
</template>

<script>
export default {
  name: 'error-message',
  methods: {
    refresh: function() {
      this.$emit('refresh');
    }
  }
}
</script>

<style scoped>

.icon {
  text-align: center;
  padding: 10px;
}
</style>
