<template>
<p2r-page @refresh="reloadMatches">
  <v-container>
    <v-layout column>
      <v-flex shrink>
        <h1 class="headline">{{ $t("matchHistory.matchHistory") }}</h1>
      </v-flex>
      <v-flex grow>
        <v-container class="pa-0">
          <v-layout column>
            <v-list two-line >
              <template v-for="(match, index) in matches">
                <v-list-item :key="match.matchId" >
                  <match-summary :match="match" />
                </v-list-item>
                <v-divider
                  v-if="index + 1 < matches.length"
                  :key="index">
                </v-divider>
              </template>
            </v-list >
            <InfiniteLoading @infinite="fetchNextMatches">></InfiniteLoading>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</p2r-page>
</template>

<script>
import MatchSummary from '../components/MatchSummary';
import { getMatches } from '../services/vuezlerApi/match.service';
import InfiniteLoading from 'vue-infinite-loading';

export default {
  data: () => ({
    matches: [],
    batchSize: 10,
  }),
  components: {
    MatchSummary,
    InfiniteLoading,
  },
  computed: {
    pageTitle: function() {
      return this.$t('pageTitles.default');
    }
  },
  methods: {
    formatDate: function(timestamp) {
      return new Date(timestamp).toLocaleString();
    },
    fetchNextMatches: function(ininitLoadingState) {
      getMatches(this.matches.length, this.matches.length + this.batchSize).then(matches => {
        this.matches.push(...matches.data);
        ininitLoadingState.loaded();

        const isEndReached = matches.data.length != this.batchSize;
        if (isEndReached) {
          ininitLoadingState.complete();
        }
      }).catch(() => {
        ininitLoadingState.complete();
      })
    },
    reloadMatches: function() { 
      getMatches(0, this.batchSize).then(({ data: matches }) => {
        this.matches = matches;
      })
    }
  }
}
</script>