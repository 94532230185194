<template>
  <v-container v-editable="blok" class="text-center" id="feedback" v-if="!isCordova">
    <h1 class="display-1 text-center mb-4 blok-title">{{ blok.title }}</h1>
    <p>{{ blok.description }}</p>
    <anonymous-support v-if="!isSuccessMessageVisible"
      @submitted="supportMessageSubmitted" 
    />
    <Success v-else
      :title="$t('common.thankYou')"
      :subtitle="$t('support.successfullySubmitted')">
    </Success>
  </v-container>
</template>

<script>
import AnonymousSupport from '@/pages/Support/AnonymousSupport'
import { isRunningInCordova } from '@core/cordova/cordovaApi';
import Success from '../../../components/common/Success';

export default {
  name: 'crm-feedback',
  data: () => ({
    isSuccessMessageVisible: false,
  }),
  props: ['blok'],
  computed: {
    isCordova: function() {
      return isRunningInCordova();
    }
  },
  methods: {
    supportMessageSubmitted: function() {
      this.isSuccessMessageVisible = true;

      window.setTimeout(() => {
        this.isSuccessMessageVisible = false;
      }, 3000);
    }
  },
    components: {
    AnonymousSupport,
    Success
  },
}
</script>