<template>
  <canvas class="qrCode" ref="qrCanvas" />      
</template>

<script>
import QrCode from 'qrcode';

export default {
  name: 'qr-code',
  props: {
    value: {
      type: String,
      required: true
    }
  },
  mounted: function() {
    QrCode.toCanvas(this.$refs.qrCanvas, this.value);    
  }
}
</script>

<style scoped>
.qrCode {
  width: 200px !important;
  height: 200px !important;
  display: block;
}
</style>
