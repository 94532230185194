<template>
  <page fill-height>
    <match-toolbar @openQrDialog="openQrDialog" @selectPosition="isPositionSelectionVisible = true" />
    <connection-overlay v-if="!isConnectedToHub" />
    
    <loading-screen v-if="isMatchEnding" :text="$t('match.endingMatch')"/>
    <v-container v-else :class="{ blurred: !isConnectedToHub }" fill-height pa-0 @click="enableInteractiveFeatures" class="matchContainer">
      <v-dialog :value="isKickedFromSession" light persistent>
        <kicked-dialog
          @onReconnect='requestPositionUpdate(position)'
          @onQuit='leaveMatch'
          @changePosition='isPositionSelectionVisible = true' />
      </v-dialog>
      <v-layout column text-xs-center xs-12>
        <v-flex shrink class="mt-2">
          <player-list v-if='activePlayers' :players='activePlayers' showElo />
        </v-flex>

        <v-layout column grow justify-center>
          <v-flex shrink>
            <score-display v-if='score' :home='score.home' :away='score.away' :highlight='this.side'/>
          </v-flex>
        </v-layout>

        <v-layout shrink column  style="align-items: stretch" v-show="position != null" >
          <v-flex style="height: 120px; margin-bottom: 24px;">
            <score-slider @onIncrementGoals='requestScoreUpdate(+1)' @onDecrementGoals='requestScoreUpdate(-1)' />
          </v-flex>
          <v-flex>
            <v-container fill-height grid-list-sm pa-0>
              <v-layout column justify-end>
                <sound-board ref="soundBoard" :onSoundDistribution="handleSoundDistribution" class="mb-1"/>
                <long-press-button block v-on:click="handleEndGame" :duration="750" aria-label="end match">
                  <span>{{ $t("match.endGame") }}</span>
                  <template slot="warning">
                    <span>{{ $t("match.endGameInfo") }}</span>
                  </template>
                </long-press-button>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      </v-layout>
        
      <v-dialog v-model="isPositionSelectionVisible" light>
        <position-selection-dialog :positions="activePlayers" @positionSelected="onPositionSelected" @close="isPositionSelectionVisible = false">
        </position-selection-dialog>
      </v-dialog>
      <v-dialog light :value="isMatchFinished" persistent>
        <MatchFinished @leave="leaveMatch"></MatchFinished>
      </v-dialog>
      <v-dialog v-model="isQrDialogVisible">
        <qr-dialog :matchId="matchId" @close="isQrDialogVisible=false"/>
      </v-dialog>
    </v-container>
  </page>
</template>

<script>
import PlayerList from '../components/PlayerList.vue';
import KickedDialog from '../components/dialogs/KickedDialog.vue';
import ScoreSlider from '../components/ScoreSlider.vue';
import ScoreDisplay from '../components/ScoreDisplay.vue';
import SoundBoard from '../components/SoundBoard.vue';
import LongPressButton from '../components/LongPressButton.vue';
import MatchFinished from '../components/matches/MatchFinished';
import LoadingScreen from '../components/core/loading/LoadingScreen';
import PositionSelectionDialog from '../components/dialogs/PositionSelectionDialog';
import ConnectionOverlay from '../components/matches/ConnectionOverlay';
import MatchToolbar from '@/toolbar/MatchToolbar';
import { mapState, mapGetters, mapActions } from 'vuex';
import { getSide, createMatchRequest } from '../logic/matchHelper';
import { ACTIONS as MatchSessionActions, GETTERS as MatchSessionGetters, MatchState } from '../vuex/matchSession';
import matchHubMethods from '../logic/matchSession/matchHubMethods';
import { PositionUpdate } from '../services/vuezlerApi/models';
import { GETTERS as MatchStateGetters } from '../vuex/matchState';
import { GETTERS as AuthGetters } from '../vuex/auth.module';
import * as NoSleep from 'nosleep.js';
import { getPlayer } from '../services/vuezlerApi/player.service';
import Vue from 'vue';
import { isRunningInCordova, reenableSleep, preventSleep } from '../modules/core/cordova/cordovaApi';
import { getMatchInfo } from '../services/vuezlerApi/match.service';
import QrDialog from '@/components/dialogs/QrDialog';

export default {
  data: function () {
    return {
      noSleep: new NoSleep(),
      playerData: {},
      isMatchFinished: false,
      isQrDialogVisible: false,
      position: undefined,
      isPositionSelectionVisible: false
    };
  },
  computed: {
    pageTitle: function() {
      return this.$t('pageTitles.default');
    },
    side: function() { return getSide(+this.$route.query.pos).side },
    matchId() { return this.$route.params.id },
    activePlayers: function() {
      return this.createActiveUsers(this.players, this.connections, this.playerData);
    },
    ...mapState({
      score: state => state.matchState.goals,
      players: state => state.matchState.players,
      connections: state => state.matchState.connections,
      isConnectedToHub: state => state.matchSession.isHubConnectionEstablished
    }),
    ...mapGetters({
      matchState: MatchSessionGetters.State,
      isPlaying: MatchStateGetters.IsPlaying,
      userId: AuthGetters.USER_ID,
    }),
    isInitializing: function() {
      return this.matchState === MatchState.Uninitialized;
    },
    isMatchEnding: function() {
      return this.matchState === MatchState.Finishing;
    },
    isKickedFromSession() {
       return this.position != null && !this.isPlaying && !this.isInitializing;
    },
  },
  components: {
    PlayerList,
    KickedDialog,
    PositionSelectionDialog,
    ScoreSlider,
    ScoreDisplay,
    SoundBoard,
    LongPressButton,
    MatchFinished,
    ConnectionOverlay,
    LoadingScreen,
    QrDialog,
    MatchToolbar
  },
  watch: {
    '$route.query.pos': {
      immediate: true,
      handler: function(newPosition) {
        this.position = newPosition
        if (newPosition != null) {
          this.requestPositionUpdate(newPosition)
        } else {
          // Spectator
          this.requestPositionUpdate(100)
        }
      },
    },
  },
  methods: {
    ...mapActions({
      invokeSessionAction: MatchSessionActions.InvokeSessionAction,
      initializeSession: MatchSessionActions.InitializeSession,
      updatePlayerPosition: MatchSessionActions.UpdatePlayerPosition,
      endMatch: MatchSessionActions.EndMatch,
      destroySession: MatchSessionActions.DestroySession
    }),
    initializeMatchState() {
      getMatchInfo(this.matchId)
        .then(({ data: matchStateResponse }) => this.isMatchFinished = matchStateResponse.state === 1);
    },
    createActiveUsers: function(players, connections, playerData) {
      return players.map(playerId => {
        if (playerId === undefined || connections[playerId] == null) return undefined;

        const connectionState = connections[playerId].connectionState;
        let profileImage = undefined;
        let elo = undefined;

        const playerInfo = playerData[playerId];
        if (playerInfo === undefined) {
          this.fetchPlayer(playerId);
        } else {
          profileImage = playerInfo && playerInfo.profileImage
          elo = playerInfo && playerInfo.elo
        }

        return {
          id: playerId,
          connectionState,
          profileImage,
          eloRanking: {
            current: elo
          }
        }
      })
    },
    fetchPlayer: function(playerId) {
      // silently set the data to null to detect fetch-in-progress without triggering the watcher.
      this.playerData[playerId] = null;

      getPlayer(playerId).then(({data}) => {
        this.playerData[playerId] = { profileImage: data.profileImage, elo: data.currentElo }
        this.playerData = Object.assign({}, this.playerData);
      }).catch(() => {
        this.playerData[playerId] = undefined;
        Vue.remove(this.playerData, playerId)
      });
    },
    connectToSession: function() {
      this.initializeSession({
        getCurrentPosition: () => this.position,
        matchId: this.matchId,
        token: this.$store.getters.token,
        playSoundCallback: this.playSound
      });
    },
    requestPositionUpdate: function(newPosition) {
      this.updatePlayerPosition(newPosition);
    },
    requestScoreUpdate: function(amount) {
      this.enableInteractiveFeatures();
      if (amount > 0) this.handleSoundDistribution('crowd', true);

      this.invokeSessionAction({
        name: matchHubMethods.COUNT_SCORE, 
        params: [ this.side, amount ]
      })
    },
    leaveMatch: async function() {
      this.$router.replace('/');
    },
    handleEndGame: function() {
      const matchRequest = createMatchRequest(this.matchId, this.players, this.score);
      this.endMatch(matchRequest);
    },
    handleSoundDistribution: function(sound, targetAll = false) {
      this.invokeSessionAction({
        name: matchHubMethods.PLAY_SOUND,
        params: [sound, this.position, targetAll]
      });
    },
    playSound: function(soundRequest) {
      const position = soundRequest.requestingPosition === 0 ? 'home' : 'away';
      if (position !== this.side || soundRequest.targetAll) {
        this.$refs.soundBoard.playCheering();
      }
    },
    enableInteractiveFeatures: function() {
      this.$refs.soundBoard.enableSounds();
    },
    onPositionSelected: function(pos) {
      if (pos == this.position) {
        this.requestPositionUpdate(pos)
      } else if (pos != null && pos < 100) {
        this.$router.replace({ name: 'match', query: { pos } });
      } else {
        this.$router.replace({ name: 'match', query: {} });
      }
    },
    preventSleep() {
      if (isRunningInCordova()) {
        preventSleep();
      } else if (window.$config.env !== 'development') {
        this.noSleep.enable();
      }
    },
    reenableSleep() {
      if (isRunningInCordova()) {
        reenableSleep();
      } else {
        this.noSleep.disable();
      }
    },
    openQrDialog() {
      this.isQrDialogVisible = true;
    }
  },
  mounted () {
    this.preventSleep();
    this.connectToSession();
    if (this.position == null) {
      this.isPositionSelectionVisible = true;
    }
    this.initializeMatchState();
  },
  beforeDestroy: function() {
    this.reenableSleep();
    if (this.matchState != MatchState.Finished) {
      const name = matchHubMethods.POSITION_UPDATE
      const params = [ this.position, PositionUpdate.Leave ]

      this.invokeSessionAction({ name, params })
        .then(this.destroySession)
    } else {
      this.destroySession();
    }
  }
}
</script>

<style scoped>
.blurred {
  filter: blur(5px);
}
</style>