<template>
  <div class="stat-container" :style="containerSize" row>
    <div class="profile-image">
      <avatar :size="avatarHeight" :src="avatar" />
    </div>
    <div class="stats" :style="statsClass">
      <div class="elo">
        <div grow class="value-group black--text">{{ $t('player.rating') }}:
          <bouncing-dots v-if="statistic.elo === undefined"></bouncing-dots>
          <span v-else>{{ statistic.elo }}</span>
        </div>
      </div>
      <div row class="outcomes">
        <div xs4 class="wins value-group">{{ $t('player.winAbbr') }}:
          <bouncing-dots v-if="statistic.wins === undefined"></bouncing-dots>
          <span v-else>{{ statistic.wins }}</span>
        </div>
        <div xs4 class="draws value-group">{{ $t('player.drawAbbr') }}:
          <bouncing-dots v-if="statistic.draws === undefined"></bouncing-dots>
          <template v-else>{{ statistic.draws }}</template>
          </div>
        <div xs4 class="defeats value-group">{{ $t('player.loseAbbr') }}:
          <bouncing-dots v-if="statistic.defeats === undefined"></bouncing-dots>
          <template v-else>{{ statistic.defeats }}</template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '../../modules/core/components/Avatar';

export default {
  data: () => ({
    height: 60,
    borderSize: 1
  }),
  computed: {
    containerSize: function() {
      return {
        'border-radius': `${this.height/2}px`,
        height: `${this.height}px`
      }
    },
    statsClass: function() {
      return {
        'padding-left': `${this.height}px`,

      }
    },
    avatarHeight: function() {
      return this.height-(2*this.borderSize)
    }
  },
  props: {
    statistic: Object,
    avatar: String
  },
  components: {
    Avatar
  }
}
</script>

<style scoped>
.stat-container {
  border: 1px solid lightgray;
  background: linear-gradient(0deg, var(--v-success-base) 50%, white 50%);
  overflow: hidden;
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
  margin: 8px auto 16px auto;
  position: relative;
}

.profile-image {
  position: absolute;
}

.stats {
  height: 100%;

  display: flex;
  flex-direction: column;
}

.stats > * {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  height: 0;
}

.outcomes {
  margin-left: 0px;
  margin-right: 0px;

  display: flex;
  flex-direction: row;
}

.outcomes>* {
  flex-grow: 1;
  width: 0;
}

.outcomes>*>span {
  overflow: hidden;
  text-overflow: ellipsis;
}

.outcomes>*:not(:first-child) {
  border-left: 1px solid lightgray;
}

.defeats {
  background-color: var(--v-error-base)
}

.draws {
  background-color: var(--v-warning-base)
}

.wins {
  background-color: var(--v-success-base)
}

.value-group {
  height: 100%;
  padding-left: 8px;
  display: flex;
  align-items: center;
}

.value-group>* {
  padding-left: 8px;
  font-weight: bold;
}
</style>
