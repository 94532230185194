<template>
  <section v-if='players' class="player-list">
    <section class="side">
      <div class="player"> 
        <player-avatar v-if='players[0]' :player='players[0]' v-bind="inheritedProps" />
      </div>
      <div class="player">
        <player-avatar v-if='players[1]' :player='players[1]' v-bind="inheritedProps" />
      </div>
    </section>
    <section class="side">
      <div class="player">
        <player-avatar v-if='players[2]' :player='players[2]' v-bind="inheritedProps" />
      </div>
      <div class="player">
        <player-avatar v-if='players[3]' :player='players[3]' v-bind="inheritedProps" />
      </div>
    </section>
  </section>
</template>

<script>
import PlayerAvatar from './PlayerAvatar.vue';

export default {
  name: 'playerList',
  components: {
    PlayerAvatar
  },
  props: {
    players: Array,
    showElo: Boolean,
    showEloDelta: Boolean
  },
  computed: {
    inheritedProps: function() {
      return {
        showElo: this.showElo,
        showEloDelta: this.showEloDelta
      }
    }
  }
}
</script>

<style scoped>
.player-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.side {
  display: flex;
  flex-direction: row;
  width: 45%;
  justify-content: space-evenly;
}
</style>