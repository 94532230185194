const matchRegex = /\/match\/((\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1})(?:\?pos=(\d))?/;
const venueRegex = /\/venue\/((\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1})(?:\?pos=(\d))?/;
const tournamentRegex = /\/tournaments\/((\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1})(?:\?join=((\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}))?/;

/**
 * Validates the path of a given match url and returns the route definition for it.
 * If the match url is invalid null will be returned.
 * @param {*} url match url
 */
export function parseMatchUrl(url) {
  const matchMatches = matchRegex.exec(url);
  if (matchMatches) {
    const id = matchMatches[1];
    const routeDefinition = { name: 'match', params: { id } };
    
    const pos = matchMatches[4];
    if (pos) routeDefinition.query = { pos };

    return routeDefinition;
  } else {
    return null;
  }
}

/**
 * Validates the path of a given venue url and returns the route definition for it.
 * If the venue url is invalid null will be returned.
 * @param {*} url venue url
 */
export function parseVenueUrl(url) {
  const venueMatches = venueRegex.exec(url);
  if (venueMatches) {
    const venueId = venueMatches[1];
    const routeDefinition = { name: 'venue', params: { venueId } };
    
    const pos = venueMatches[4];  
    if (pos) routeDefinition.query = { pos }; 

    return routeDefinition;
  } else {
    return null;
  }
}

/**
 * Validates the path of a given tournament url and returns the route definition for it.
 * If the tournament url is invalid null will be returned.
 * @param {*} url tournament url
 */
export function parseTournamentUrl(url) {
  const tournamentMatches = tournamentRegex.exec(url);
  if (tournamentMatches) {
    const id = tournamentMatches[1];
    const routeDefinition = { name: 'tournament', params: { id } };
    
    const join = tournamentMatches[4];
    if (join) routeDefinition.query = { join };

    return routeDefinition;
  } else {
    return null;
  }
}