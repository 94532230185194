import jwt_decode from 'jwt-decode';

export function getUserId(token) {
  return token
    ? jwt_decode(token)["user_id"]
    : undefined;
}

export function getUserMail(token) {
  return token
    ? jwt_decode(token)["email"]
    : undefined;
}
