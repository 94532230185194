import axios from "axios";

export function createMatch(match) {
  return axios
    .post(`${window.$config.vuzzlerApi}/api/match`, match);
}

export function getMatches(start, end) {
  return axios
    .get(`${window.$config.vuzzlerApi}/api/match?start=${start}&end=${end}`);
}

export function getMatchInfo(matchId) {
  return axios
    .get(`${window.$config.vuzzlerApi}/api/match/${matchId}/info`);
}