import Vue from 'vue';
import Vuex from 'vuex';
import group from './group.module';
import auth from './auth.module';
import shell from './shell.module';
import matchSession from './matchSession';
import matchState from './matchState';
import matchSummary from './matchSummary.module';
import tournamentList from '../modules/tournament/states/tournament-list';
import tournament from '../modules/tournament/states/tournament';
import issues from '../modules/tournament/states/issues';
import player from './playerModule';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    auth,
    shell,
    matchSession,
    matchState,
    matchSummary,
    group,
    tournamentList,
    tournament,
    player,
    issues
  }
})