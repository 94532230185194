<template>
  <v-dialog
    :value="value"
    @input="close">
    <v-card v-if="currentGroup != null">
      <v-card-title class="headline" primary-title>{{ $t("group.information")}}</v-card-title>
      <v-card-text>
        <v-text-field readonly :label="$t('group.name')" :value="currentGroup.name"></v-text-field>
        <v-text-field readonly :label="$t('group.description')" :value="currentGroup.description"></v-text-field>
        <p>
          <span>{{ $t('group.nrOfMembers') }}: </span>
          <span>{{ members.length }}</span>
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="close">{{ $t("common.close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'group-information-dialog',
  props: {
    value: Boolean
  },
  computed: {
    ...mapState({
      members: state => state.group.members,
      currentGroup: state => state.group.currentGroup
    })
  },
  methods: {
    close: function() {
      this.$emit('input', false);
    },
  }
}
</script>
