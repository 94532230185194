<template>
  <v-img aspect-ratio="1"
    class="image"
    v-bind="$attrs"
    v-on="$listeners"
  ></v-img>
</template>

<script>
export default {
  name: 'AvatarImage',
}
</script>

<style scoped>
.image {
  width: 100px;
  height: 100px;
  max-width: 100px;
  max-height: 100px;
  margin: 4px;
  border: solid 4px transparent;
  flex: 0 1 auto;
  border-radius: 8px;
}
</style>
